<template>
    <div class="platform" :class="platformClass">
        <div class="platform-text">
            <h3>
                <span>{{ $t(platform.TextKey) }}</span>
                <span>
                    <em v-if="platform.DisplayType == 'ActivTraderPlatform'">{{ $t("lbl_recommended") }}</em>
                </span>
            </h3>

            <p v-if="platform.HintKey" v-html="$t(platform.HintKey)"></p>
        </div>
        <div class="img" :class="platformClass"></div>
    </div>
</template>

<script>
export default {
    name: "UITradingPlatform",
    props: ["platform", "isActive"],
    computed: {
        platformClass() {
            return {
                at: this.platform.DisplayType === "ActivTraderPlatform",
                mt: this.platform.DisplayType === "MetaTrader4Platform" || this.platform.DisplayType === "MetaTrader5Platform",
                baat: this.platform.DisplayType === "BlackArrowActivTrader",
                active: this.isActive,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.platform-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}
.platform {
    display: flex;
    gap: 1.25rem;
    width: var(--form-width);
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--border-secondary);
    border-radius: var(--border-radius-main);
    padding: var(--double-padding);
    text-align: left;
    color: gray;
    cursor: pointer;
}
.rtl .platform {
    text-align: right;
}

.platform.at,
.platform.baat {
    display: grid;
    grid-template-columns: 1fr 8.75rem;
    grid-template-areas:
        "title platform-image"
        "text text";
    column-gap: 1.25rem;
    row-gap: 0.75rem;
}

.platform.at {
    background: none;
    border-color: var(--border-secondary);
}

@media (pointer: fine) {
    .platform:hover {
        background: var(--hover-main);
        border-color: var(--border-main);
    }
    .platform.at:hover {
        background: var(--hover-main);
        border-color: var(--border-main);
    }
    .platform:hover h3 {
        color: var(--font-secondary);
    }
    .platform:hover p,
    .platform.at:hover p {
        color: var(--font-main);
    }
}

.platform:active,
.platform.active,
.platform.at.active,
.platform.at:active {
    border-color: var(--accent);
    background: var(--form-control-focus-bgr);

    h3,
    p {
        color: var(--accent);
    }
}

.at.img,
.baat.img {
    grid-area: platform-image;
    width: auto;
    height: 6.25rem;
    flex-grow: 0;
    flex-shrink: 0;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.at.img {
    background-image: url("@/assets/images/activtrader-platform.webp");
}

.baat.img {
    background-image: url("@/assets/images/nelogica-platform.webp");
}

h3 {
    display: flex;
    gap: 0.75rem;
    font-size: 1rem;
    color: var(--font-main);
    font-weight: 700;
    margin: 0;
}

.platform.at h3 {
    grid-area: title;
    font-size: 1.5rem;
    flex-direction: column;
    gap: 6px;
}

h3 em {
    display: inline-block;
    position: relative;
    top: -0.375rem;
    font-size: 0.75rem;
    font-style: normal;
    color: var(--accent);
    padding: 0.313rem;
    border: 1px solid var(--accent);
    border-radius: 2px;
    background: rgb(var(--accent-rgb) / 5%);
}

p {
    color: var(--font-tertiary);
    margin-top: 1.375rem;
}
.platform p {
    margin: 0.375rem 0 0;
    font-size: 0.875rem;
    color: var(--font-tertiary);
}
.platform.at p {
    grid-area: text;
    color: var(--font-tertiary);
    font-size: 0.875rem;
}

.platform.baat p {
    grid-area: text;
}

.platform.at p :deep(strong) {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 0.625rem;
}

.platform-text {
    flex-grow: 1;
    flex-shrink: 1;
}
.platform.at .platform-text,
.platform.baat .platform-text {
    display: contents;
}
</style>
