<template>
    <AppScrollable :questions="[checkbox]" :errors="props.errors" :buttonKey="declaration.SaveButtonKey" :showButton="true">
        <template #default>
            <Questions :questions="questionsWithoutCheckbox" :errors="props.errors"></Questions>
        </template>
    </AppScrollable>
</template>
<script setup>
import { computed } from "vue";
import Questions from "@/views/onlineapplication/questions/Questions.vue";
import AppScrollable from "@/components/oa/AppScrollable.vue";
import AppCheckbox from "@/components/oa/AppCheckbox.vue";
import UIFlexButton from "@/components/ui/UIFlexButton.vue";

const props = defineProps({
    questions: Array,
    errors: {
        type: Object,
        requred: true,
    },
});

const checkbox = computed(() => props.questions.find((x) => x.FieldType == "Checkbox"));
const questionsWithoutCheckbox = computed(() => props.questions.filter((x) => x.FieldType != "Checkbox"));
const declaration = computed(() => props.questions.find((x) => x.FieldType == "Declaration"));
</script>
