<template>
    <div
        v-for="question in questions"
        v-bind:key="question?.Id"
        class="question"
        v-bind:class="{ devided: question.FieldType == 'Country' && this.allowInlineElements && this.isNotBlackListed, included: question.FieldType == 'TitleRadio' }">
        <AppCountryDropdown
            v-if="question.FieldType == 'Country'"
            v-bind:question="question"
            v-bind:errors="errors"
            v-bind:preSelectedCountryId="this.preSelectedCountryId"
            @on-change-country="handleCountryChange"></AppCountryDropdown>
        <div v-show="isSubmitButtonsEnabled">
            <UIInput
                v-if="question.FieldType == 'General' || question.FieldType == 'Date' || question.FieldType == 'Tin' || question.FieldType == 'Text'"
                v-bind:question="question"
                v-bind:errors="errors"></UIInput>
            <UIEmailInput v-if="question.FieldType == 'Email'" v-bind:question="question" v-bind:errors="errors" :isDemoRegistration="isDemoRegistration"></UIEmailInput>
            <UIPassword v-if="question.FieldType == 'Password'" v-bind:question="question" v-bind:errors="errors"></UIPassword>
            <UISelect v-if="question.FieldType == 'Select'" v-bind:question="question" v-bind:errors="errors"></UISelect>
            <UIDropdown v-if="question.FieldType?.toLowerCase() == 'dropdown'" v-bind:question="question" v-bind:errors="errors" v-bind:useCountryOfResidence="true"></UIDropdown>
            <UIRadio v-if="question.FieldType == 'Radio'" v-bind:question="question" v-bind:errors="errors"></UIRadio>
            <UIText v-if="question.FieldType == 'Declaration'" v-bind:question="question"></UIText>
            <UINote v-if="question.FieldType == 'Note'" v-bind:question="question"></UINote>
            <UIWarning v-if="question.FieldType == 'Warning'" v-bind:question="question"></UIWarning>
            <UITextArea v-if="question.FieldType == 'TextArea'" v-bind:question="question" v-bind:errors="errors"></UITextArea>
            <UISelectableRadio v-if="question.FieldType == 'TitleRadio'" v-bind:question="question" v-bind:errors="errors"></UISelectableRadio>
            <AppBranchSelection v-if="question.FieldType == 'BranchSelection'" v-bind:question="question" v-bind:errors="errors"></AppBranchSelection>
            <AppCheckbox
                v-if="question.FieldType == 'Checkbox' || question.FieldType == 'MultipleCheckbox'"
                v-bind:question="question"
                v-bind:errors="errors"
                v-bind:allowMultipleSelection="question.FieldType == 'MultipleCheckbox'"></AppCheckbox>
            <AppCheckboxOptional v-if="question.FieldType == 'OptionalCheckbox'" v-bind:question="question" v-bind:errors="errors"></AppCheckboxOptional>
            <AppBrProvince v-if="question.FieldType == 'BRProvince'" v-bind:question="question" v-bind:errors="errors"></AppBrProvince>
            <AppTradingPlatform v-if="question.FieldType == 'TradingPlatform'" v-bind:question="question" v-bind:errors="errors"></AppTradingPlatform>
            <AppTradingProfile
                v-if="question.FieldType == 'TradingProfile'"
                v-bind:question="question"
                v-bind:errors="errors"
                class="trading-profile-container"></AppTradingProfile>
            <AppIdVerification v-if="question.FieldType == 'IdVerification'" v-bind:question="question" v-bind:errors="errors"></AppIdVerification>
            <AppOnfidoVerification v-if="question.FieldType == 'OnfidoVerification'" v-bind:question="question" v-bind:errors="errors"></AppOnfidoVerification>
            <AppBrDrivingLicence v-if="question.FieldType == 'BrDrivingLicence'" v-bind:question="question" v-bind:errors="errors"></AppBrDrivingLicence>
            <AppFileUpload v-if="question.FieldType == 'FileUpload'" v-bind:question="question">
                <template v-slot:note>
                    <UINote v-if="questions.find((x) => x.FieldType === 'FileUploadNote')" v-bind:question="questions.find((x) => x.FieldType === 'FileUploadNote')"></UINote>
                </template>
            </AppFileUpload>
            <AppFileUploadCodiceFiscale v-if="question.FieldType == 'FileUploadCodiceFiscale'" v-bind:question="question" v-bind:errors="errors"></AppFileUploadCodiceFiscale>
            <AppCleave v-if="question.FieldType == 'Cleave'" v-bind:question="question" v-bind:errors="errors"></AppCleave>
            <AppNationality v-if="question.FieldType == 'Nationality'" v-bind:question="question" v-bind:errors="errors"></AppNationality>
            <AppCountryTins v-if="question.FieldType == 'CountryTins'" v-bind:question="question" v-bind:errors="errors" v-bind:useCountryOfResidence="true"> </AppCountryTins>
            <AppPhoneSelection v-if="question.FieldType == 'Phone'" v-bind:questions="questions" v-bind:errors="errors"></AppPhoneSelection>
            <AppUserValidation v-if="question.FieldType == 'UserValidation'" v-bind:question="question"></AppUserValidation>
        </div>
        <div v-if="question.FieldType == 'Country' && this.isBlackListed && question.DisplayType == 'CountryOfResidenceDropdown'" class="notification-containter">
            <UIBlackListedCountryNotification />
        </div>
    </div>
</template>

<script>
import eventBus from "@/eventBus";

export default {
    name: "Questions",
    props: ["questions", "errors", "preSelectedCountryId", "allowInlineElements", "isDemoRegistration"],
    data() {
        return {
            countryRiskGroup: "",
            isSubmitButtonsEnabled: true,
        };
    },
    beforeMount() {
        eventBus.$on("change-continue-button-state", (btnState) => (this.isSubmitButtonsEnabled = btnState));
    },
    methods: {
        handleCountryChange(data) {
            if (data) {
                this.countryRiskGroup = data.countryRiskGroup;
            }
        },
    },
    computed: {
        isNotBlackListed() {
            return this.countryRiskGroup?.toLowerCase() !== "blacklisted";
        },
        isBlackListed() {
            return this.countryRiskGroup?.toLowerCase() === "blacklisted";
        },
    },
};
</script>

<style lang="scss" scoped>
.trading-profile-container {
    height: 100%;
}
</style>
