import { defineStore } from "pinia";
import { ref } from "vue";

export const useCommonStore = defineStore("common", () => {
    const platform = ref("AT");
    const companyBranch = ref(null);
    const testingGroup = ref(null);

    return {
        platform,
        companyBranch,
        testingGroup,
    };
});
