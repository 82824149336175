<template>
    <div class="phone-flex">
        <div v-for="question in visibleQuestions" v-bind:key="question.Id">
            <UIPhoneCode v-if="question.FieldType == 'PhoneCode'" v-bind:question="question" v-bind:errors="errors"></UIPhoneCode>
            <UIPhone v-if="question.FieldType == 'Phone'" v-bind:question="question" v-bind:errors="errors"> </UIPhone>
        </div>
    </div>
</template>

<script>
import UIPhone from "@/components/ui/UIPhone.vue";
import UIPhoneCode from "@/components/ui/UIPhoneCode.vue";
export default {
    name: "AppPhoneSelection",
    props: ["questions", "errors", "setFieldError"],
    components: {
        UIPhone,
        UIPhoneCode,
    },
    data() {
        return {
            visibleQuestions: this.questions.filter((x) => x.FieldType === "PhoneCode" || x.FieldType === "Phone"),
        };
    },
};
</script>

<style lang="scss">
.phone-flex {
    display: flex;
    position: relative;
    width: 100%;
}

.phone-flex > div:first-child {
    flex-grow: 0;
    flex-shrink: 0;
    width: 9.063rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.phone-flex > div:nth-child(2) {
    flex-grow: 1;
    flex-shrink: 1;
}

.country-flex > div:last-child .form-field {
    margin: 0;
    width: 100%;
}
</style>
