<template>
    <div class="loader-overlay">
        <div class="loader-position">
            <div class="loader">
                <div class="loader-bgr"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
    delay: {
        type: Number,
        default: 0,
        required: false,
    },
});

const delay = computed(() => `${props.delay}s`);
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_animations.scss";
@import "src/assets/styles/_buttons.scss";

.loader-overlay {
    position: fixed;
    top: 0; //blur animation makes new stacking context
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    opacity: 0;
    background: rgb(var(--bg-secondary-rgb) / 0.5);
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-delay: v-bind(delay);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.loader-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader {
    mask-image: url("/src/assets/images/AT-checkmark.svg");
    mask-repeat: no-repeat;
    -webkit-mask-image: url("/src/assets/images/AT-checkmark.svg");
    -webkit-mask-repeat: no-repeat;
}

.loader-bgr {
    width: 128px;
    height: 124px;
    background-color: var(--loader-bgr);
    background-image: var(--loader-gradient);
    background-repeat: no-repeat;
    background-position: 0 0;
    margin: 0 auto 2.813rem;
    animation: loaderAnimation 2s infinite;
}

@keyframes loaderAnimation {
    0% {
        background-position: 0 -200%;
    }
    100% {
        background-position: 0 300%;
    }
}
</style>
