<template>
    <div>
        <div class="profile-top">
            <div class="profile-flex">
                <img src="@/assets/images/profile/explorer.svg" alt="Explorer profile" class="image-profile" />
                <span class="title">{{ $t("oa_trading_profile_explorer_title") }}</span>
            </div>
            <p>{{ $t("explorer_profile_summary") }}</p>
        </div>
        <div class="submit-button">
            <button id="submit_button" type="button" @click="applicationStore.submit">
                {{ $t("btn_continue") }}
            </button>
        </div>
    </div>
</template>

<script>
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";

export default {
    name: "AggressiveProfile",
    props: ["question"],
    computed: {
        ...mapStores(useApplicationStore),
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_profiles.scss";
</style>
