<template>
    <div class="form-field">
        <Field
            :name="question.FieldName"
            :type="fieldType"
            class="form-control"
            :class="{ 'is-invalid': errors[question.FieldName] }"
            v-model="question.Answers[0].Value"
            v-bind:placeholder="question.TextKey != null ? $t(question.TextKey) : ''"></Field>

        <label class="small-label">{{ question.TextKey != null ? $t(question.TextKey) : "" }}</label>

        <ErrorMessage v-bind:name="question.FieldName" />
    </div>
</template>

<script>
import EmptyAnswerModel from "@/models/EmptyAnswerModel";

export default {
    name: "UIInput",
    props: ["question", "errors"],
    data() {
        return {
            fieldType: "text",
        };
    },
    beforeMount() {
        if (this.question.Answers == null || this.question.Answers.length == 0) {
            this.question.Answers.push(new EmptyAnswerModel());
        }

        if (this.question.DisplayType == "Hidden") {
            this.fieldType = "hidden";
        }
    },
};
</script>
