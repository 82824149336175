export const CorporatePage = {
    Contact: "Contact",
    EventsWebinar: "EventsWebinar",
    ForexMargins: "ForexMargins",
    EducationManuals: "EducationManuals",
    Education: "Education",
    OneToOneTrainings: "OneToOneTrainings",
    CallMe: "CallMe",
    Privacy: "Privacy",
    Legal: "Legal",
    PlatformsSmartForecast: "PlatformsSmartForecast",
    PlatformsSmartPattern: "PlatformsSmartPattern",
    PlatformsPivotPoints: "PlatformsPivotPoints",
    PlatformsSmartTemplate: "PlatformsSmartTemplate",
    PlatformsSmartOrder: "PlatformsSmartOrder",
    PlatformsSmartLines: "PlatformsSmartLines",
    ActivTraderPlatform: "ActivTraderPlatform",
    EducationEvents: "EducationEvents",
    AccountsMT4Overview: "AccountsMT4Overview",
    ActivTradesPrepaidMasterCard: "ActivTradesPrepaidMasterCard",
    AccountsOverview: "AccountsOverview",
    LegalDisclaimer: "LegalDisclaimer",
    MT4WebPlatform: "MT4WebPlatform",
    MT5WebPlatform: "MT5WebPlatform",
    CookiePrivacy: "CookiePrivacy",
    ProfessionalTradingAccounts: "ProfessionalTradingAccounts",
    EducationArchiveSpecial: "EducationArchiveSpecial",
    TaxSubstitute: "TaxSubstitute",
    FAQ: "FAQ",
    ProfessionalAccountApplicationForm: "ProfessionalAccountApplicationForm",
    AccountFunding: "AccountFunding",
    HomePage: "HomePage",
};
