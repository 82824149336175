<template>
    <div class="lang-overlay" v-if="showLanguagesDropdown" @click="toggleDropdown()"></div>
    <div class="lang" v-if="languagesStore.languages">
        <div class="current-lang" @click="toggleDropdown()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-light fa-globe">
                <path
                    d="M256 480c16.7 0 40.4-14.4 61.9-57.3c9.9-19.8 18.2-43.7 24.1-70.7H170c5.9 27 14.2 50.9 24.1 70.7C215.6 465.6 239.3 480 256 480zM164.3 320H347.7c2.8-20.2 4.3-41.7 4.3-64s-1.5-43.8-4.3-64H164.3c-2.8 20.2-4.3 41.7-4.3 64s1.5 43.8 4.3 64zM170 160H342c-5.9-27-14.2-50.9-24.1-70.7C296.4 46.4 272.7 32 256 32s-40.4 14.4-61.9 57.3C184.2 109.1 175.9 133 170 160zm210 32c2.6 20.5 4 41.9 4 64s-1.4 43.5-4 64h90.8c6-20.3 9.3-41.8 9.3-64s-3.2-43.7-9.3-64H380zm78.5-32c-25.9-54.5-73.1-96.9-130.9-116.3c21 28.3 37.6 68.8 47.2 116.3h83.8zm-321.1 0c9.6-47.6 26.2-88 47.2-116.3C126.7 63.1 79.4 105.5 53.6 160h83.7zm-96 32c-6 20.3-9.3 41.8-9.3 64s3.2 43.7 9.3 64H132c-2.6-20.5-4-41.9-4-64s1.4-43.5 4-64H41.3zM327.5 468.3c57.8-19.5 105-61.8 130.9-116.3H374.7c-9.6 47.6-26.2 88-47.2 116.3zm-143 0c-21-28.3-37.5-68.8-47.2-116.3H53.6c25.9 54.5 73.1 96.9 130.9 116.3zM256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512z" />
            </svg>
            {{ languagesStore.languages.find((x) => x.ShortName == languagesStore.preferedLanguageShortName)?.NativeName }}
        </div>
        <div class="languages-dropdown" v-if="showLanguagesDropdown" @click="toggleDropdown()">
            <div v-for="language in languagesStore.languages" :key="language.Id">
                <a href="javascript: void(0)" @click="changeLanguage(language.ShortName)">
                    {{ language.NativeName }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from "@/eventBus";
import { useLanguagesStore } from "@/store/languages.js";
import { mapStores } from "pinia";

export default {
    name: "PickLanguage",
    props: ["position"],
    computed: {
        ...mapStores(useLanguagesStore),
    },
    data: function () {
        return {
            showLanguagesDropdown: false,
        };
    },
    methods: {
        async changeLanguage(language) {
            await this.languagesStore.setLanguage(language);
            eventBus.$emit("changeLanguage");
        },

        toggleDropdown() {
            this.showLanguagesDropdown = !this.showLanguagesDropdown;
        },
    },
};
</script>

<style lang="scss">
.lang {
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    display: flex;
    align-items: center;
    z-index: 10;

    // .rtl & {
    //     right: 0;
    //     left: auto;
    //     text-align: right;
    // }

    @media (max-width: 430px) {
        position: relative;
        margin-top: var(--main-padding);
    }
}

.languages-dropdown {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    min-width: 9rem;
    height: auto;
    max-height: 340px;
    background: var(--bg-main);
    border: 1px solid var(--border-secondary);
    border-top: none;
    border-radius: var(--border-radius-main);
    box-shadow: var(--main-box-shadow);
    text-align: left;
    overflow-y: auto;
    z-index: 20;
}
// .rtl .languages-dropdown {
//     right: 0;
//     left: auto;
//     text-align: right;
// }

.languages-dropdown > div:first-child a {
    border-top: none;
}

.languages-dropdown a {
    display: block;
    color: var(--font-main);
    text-decoration: none;
    font-size: 1rem;
    padding: 0.875rem;
    border-top: 1px solid var(--border-secondary);
}

@media (pointer: fine) {
    .languages-dropdown a:hover {
        background: var(--hover-secondary);
        color: var(--font-main);
        text-decoration: none;
    }
}

.current-lang {
    display: inline-block;
    color: var(--info);
    fill: var(--info);
    font-size: 0.875rem;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
}
// .rtl .current-lang {
//     text-align: right;
// }

.lang-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: transparent;
}
</style>
