<template>
    <Field as="input" class="fieldinput" readonly="readonly" :name="question.FieldName" :class="{ 'is-invalid': errors[question.FieldName] }" v-model="validationModel"> </Field>
    <div v-bind:class="{ group: question.Answers.length > 1 }">
        <UICheckbox
            class="checkbox"
            v-for="answer in question.Answers"
            v-bind:key="answer.Id"
            v-bind:answer="answer"
            v-bind:checked="!!question.SelectedAnswers.find((x) => x == answer.Id)"
            v-bind:invalid="!!errors[question.FieldName]"
            v-on:change="handleSubmittedAnswere(answer)"></UICheckbox>
    </div>
    <ErrorMessage v-bind:name="question.FieldName" />
</template>

<script lang="js">
import eventBus from "@/eventBus";

import UICheckbox from "@/components/ui/UICheckbox.vue";
export default {
    name: "AppCheckbox",
    props: ["question", "errors", "allowMultipleSelection"],
    components: {
        UICheckbox,
    },
    data() {
        return {
            validationModel: null,
        };
    },
    mounted() {
        this.validationModel = !!this.question.SelectedAnswers.find((x) => x > 0) ? true : null;

        var self = this;
        eventBus.$on("changeChebkox", () => {
            self.question.Answers.forEach((checkbox) => {
                self.handleSubmittedAnswere(checkbox);
            });
            eventBus.$off("changeChebkox");
        });
    },
    methods: {
        handleSubmittedAnswere(item) {
            const isIncluded = this.question.SelectedAnswers.find((x) => x == item.Id);
            if (isIncluded) {
                this.removeAnswer(item);
            } else {
                this.addAnswer(item);
            }

            this.validationModel = !!this.question.SelectedAnswers.find((x) => x > 0);
        },
        addAnswer(answer) {
            if (this.allowMultipleSelection && this.question.SelectedAnswers[0] != 0) {
                this.question.SelectedAnswers.push(answer.Id);
                return;
            }

            this.question.SelectedAnswers = [answer.Id];
        },
        removeAnswer(answer) {
            if (this.allowMultipleSelection) {
                const index = this.question.SelectedAnswers.findIndex((a) => a == answer.Id);

                this.question.SelectedAnswers.splice(index, 1);
                return;
            }

            this.question.SelectedAnswers = [];
        },
    },
};
</script>
<style lang="scss" scoped>
.fieldinput {
    display: none;
}
[role="alert"] {
    display: none;
}
.group + [role="alert"] {
    display: block;
    color: var(--notification-error-text);
    background: var(--notification-error-bgr);
    border-radius: var(--border-radius-main);
    text-align: left;
    padding: 0.85rem 0.875rem 0.65rem;
    margin: 0.375rem 0 1.063rem;
}

.rtl .group + [role="alert"] {
    text-align: right;
}
.group + [role="alert"]:before {
    vertical-align: -0.125em;
    display: inline-block;
    width: 1.5rem;
    font-family: "Font Awesome 6 Pro";
    font-size: 1rem;
    text-align: center;
    color: var(--error);
    margin: 0 0.875rem 0 0;
}

.theme-light .group + [role="alert"]:before {
    content: url("@/assets/images/exclamation-error-light-theme.svg");
}

.theme-dark .group + [role="alert"]:before {
    content: url("@/assets/images/exclamation-error-dark-theme.svg");
}

.rtl .group + [role="alert"]:before {
    margin: 0 0 0 0.875rem;
}
</style>
