<template>
    <div v-if="isVisible" v-show="!uniqueEmailRadioButtons" class="radio-group">
        <h1 v-if="question.TextKey">{{ $t(question.TextKey) }}</h1>

        <div v-for="(answer, i) in question.Answers" v-bind:key="answer.Id" class="popover-wrapper">
            <div class="radio-btn" :class="{ active: answer.Id == question.SelectedAnswers[0] }">
                <div v-text="getLetter(i)" class="display-letter"></div>
                <input name="Answer" type="radio" v-bind:id="question.FieldName + answer.Id" v-bind:value="answer.Value" v-model="question.Value" @click="change(answer)" />
                <label v-bind:for="question.FieldName + answer.Id">
                    {{ $t(answer?.TextKey) }}
                </label>
            </div>
            <div v-if="applicationStore.currentStep.DisplayType === 'ItalianTaxes' && answer.DisplayType === 'RegimeAmministrato' && question.SelectedAnswers[0] === answer.Id">
                <div id="it_taxes_disclaimer" class="form-field-wrapper margin-bottom-6">
                    <div class="notification notification-warning">
                        <div class="notification-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-solid fa-triangle-exclamation">
                                <path
                                    d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                            </svg>
                        </div>
                        <div>
                            <span>{{ $t("ls_it_texes_text") }}</span>
                            <ul>
                                <li v-html="$t('ls_it_taxes_list1_escaped')"></li>
                                <li v-html="$t('ls_it_taxes_list2')"></li>
                                <li v-html="$t('ls_it_taxes_list3')"></li>
                                <li v-html="$t('ls_it_taxes_list4')"></li>
                                <li v-html="$t('ls_it_taxes_list5')"></li>
                                <li v-html="$t('ls_it_taxes_list6')"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Field as="hidden" class="form-control" :name="question.FieldName" v-model="selectedAnswer"> </Field>

        <ErrorMessage :name="question.FieldName" />
    </div>
</template>

<script>
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import constants from "@/common/constants";
import eventBus from "@/eventBus";

import BranchSelectPopover from "@/components/ui/popover/BranchSelectPopover.vue";

export default {
    name: "UIRadio",
    props: ["question", "errors"],
    components: {
        BranchSelectPopover,
    },
    data() {
        return {
            submitStepOnClick: true,
            isVisible: true,
            userStatus: "",
            isDemoUserLoggedInPA: false,
            sendChangePasswordEvent: false,
            uniqueEmailRadioButtons: false,
            hoverInProgress: false,
            selectedAnswer: null,
        };
    },
    watch: {
        // this.applicationStore.customerStatus: {
        //     handler(status) {
        //         var data = status;
        //         var self = this;
        //         self.userStatus = data.UserStatus;
        //         self.isDemoUserLoggedInPA = data.IsDemoUserLoggedInPA == "True";
        //         self.isVisible = self.userStatus == "DemoUser" && !self.isDemoUserLoggedInPA;
        //         if (status.startNewIsSelected) {
        //             this.change(this.question.Answers[1]);
        //         } else {
        //             this.change(this.question.Answers[0]);
        //         }
        //         if (!self.isVisible) {
        //             self.question.SelectedAnswers[0] = 0;
        //             eventBus.$emit("ChangePasswordLabelEvent", "default");
        //         }
        //     },
        //     deep: true,
        // },
    },
    mounted() {
        var self = this;
        if (this.question.DisplayType == "UniqueEmailRadioButtons") {
            self.submitStepOnClick = false;
            self.isVisible = false;
            self.sendChangePasswordEvent = true;
            self.uniqueEmailRadioButtons = true;
        }

        if (this.question.SelectedAnswers[0] > 0) {
            var answerId = this.question.SelectedAnswers[0];

            this.question.Answers.forEach((answer) => {
                if (answer.Id == answerId) {
                    this.selectedAnswer = answer.Value;
                    answer.popoverDisplayed = true;
                }
            });
        }

        if (this.applicationStore.currentStep.Questions.length !== 1) {
            self.submitStepOnClick = false;
        }

        eventBus.$on("changeRadio", (answer) => {
            self.change(answer);
            eventBus.$off("changeRadio");
        });
    },
    methods: {
        change(answer) {
            if (
                this.question.SelectedAnswers[0] != 0 &&
                this.question.SelectedAnswers[0] == answer.Id &&
                !this.applicationStore.isLastStep &&
                this.applicationStore.currentStep.Questions.length === 1
            ) {
                this.applicationStore.setNextStepIfNoAnswerIsChanged();
                return false;
            }
            this.question.SelectedAnswers[0] = answer.Id;
            this.selectedAnswer = answer.Value;
            answer.popoverDisplayed = true;

            eventBus.$emit("ChangePasswordLabelEvent", answer.TextKey);

            if (this.submitStepOnClick) {
                this.applicationStore.submit();
            }
        },

        getLetter(i) {
            return constants.RadioButtonLetters[i];
        },

        hoverRadio(answer) {
            this.hoverInProgress = true;
        },

        leaveRadio(answer) {
            this.hoverInProgress = false;
        },
    },
    computed: {
        ...mapStores(useApplicationStore),
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_radio.scss";
</style>
