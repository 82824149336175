<template>
  <div>
    <Questions v-bind:questions="questions" v-bind:errors="errors"></Questions>
  </div>
</template>

<script>
import Questions from "../Questions.vue";

export default {
  name: "Default",
  props: ["questions", "errors"],
  components: {
    Questions,
  }
};
</script>