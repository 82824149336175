<template>
    <div class="platform-container">
        <Field as="hidden" class="fieldinput" readonly="readonly" :name="question.FieldName" :class="{ 'is-invalid': errors[question.FieldName] }" v-model="validationModel">
        </Field>

        <div class="platform-wrapper">
            <UITradingPlatform
                v-for="platform in question.Answers"
                :key="platform.Id"
                :platform="platform"
                :isActive="platform.Id === question.SelectedAnswers[0]"
                v-on:click="handleSubmittedAnswer(platform)"></UITradingPlatform>
        </div>

        <p>{{ $t("change_platform_anytime") }}</p>
    </div>
</template>

<script>
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import UITradingPlatform from "@/components/ui/UITradingPlatform.vue";
import eventBus from "@/eventBus";

export default {
    name: "AppTradingPlatform",
    props: ["question", "errors"],
    components: {
        UITradingPlatform,
    },
    methods: {},
    data() {
        return {
            validationModel: null,
        };
    },
    mounted() {
        var self = this;
        this.validationModel = !!this.question.SelectedAnswers.find((x) => x > 0) ? true : null;

        eventBus.$on("changePlatform", () => {
            self.handleSubmittedAnswer(self.question.Answers[0]);
            eventBus.$off("changePlatform");
        });
    },

    methods: {
        handleSubmittedAnswer(item) {
            this.addAnswer(item);
            this.validationModel = !!this.question.SelectedAnswers.find((x) => x > 0);
        },
        addAnswer(answer) {
            this.question.SelectedAnswers = [answer.Id];
            this.applicationStore.submit();
        },
        removeAnswer(answer) {
            this.question.SelectedAnswers = [];
        },
    },
    computed: {
        ...mapStores(useApplicationStore),
    },
};
</script>

<style lang="scss" scoped>
.platform-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}
p {
    margin-top: 0.875rem;
    font-size: 0.875rem;
}
</style>
