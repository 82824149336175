<script setup>
const props = defineProps({
    toggleDialog: Boolean,
    titleText: String,
    bodyText: String,
    okButtonFunction: Function,
    okButtonText: String,
    cancelButtonFunction: Function,
    cancelButtonText: String,
});
</script>

<template>
    <div class="popup" v-if="props.toggleDialog">
        <div class="container">
            <div class="question-text">
                <h1>{{ props.titleText }}</h1>
                <div class="skip-text">{{ bodyText }}</div>
            </div>
            <div class="popup-buttons-container">
                <button type="button" @click.prevent.once="props.okButtonFunction" class="continue-btn">{{ props.okButtonText }}</button>
                <button type="button" @click.prevent.once="props.cancelButtonFunction" class="secondary-btn">{{ props.cancelButtonText }}</button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "src/assets/styles/_popup.scss";
@import "src/assets/styles/_buttons.scss";

.onfido-verification .popup h1:before {
    content: url("@/assets/images/exclamation-yellow.svg") !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px !important;
}

@media (max-width: 430px) {
    .onfido-verification .popup .container {
        width: 100% !important;
        height: 100% !important;
        transform: none;
        top: 0;
        left: 0;
        max-width: unset;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .onfido-verification .popup .question-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}
</style>
