export default {
    GetDropDownLanguages: "v1/dropdowns/GetDropDownLanguages",
    StartOAUrl: "v1/oa/StartOA",
    SubmitStepUrl: "v1/oa/SubmitStep",
    GetNextStepUrl: "v1/oa/GetNextStep",
    GetPreviousStepUrl: "v1/oa/GetPreviousStep",
    SkipStepUrl: "v1/oa/skipStep",
    CloseOA: "v1/oa/Close",
    ValidateTinUrl: "v1/validation/ValidateTinNumber",
    ValidCpfFormatUrl: "v1/validation/IsValidCpfFormat",
    DropdownUrl: "v1/oa/GetQuestionDropdown",
    GetCustomerStatusUrl: "v1/oa/CheckClientStatus",
    UploadFile: "v1/oa/UploadFile",
    BahamasBranchEndpoint: `${import.meta.env.VITE_PA_URL}OnlineApplication?branch=bh`,
    PersonalAreaHomePageUrl: `${import.meta.env.VITE_PA_URL}Home`,
    PersonalAreaAccountPageUrl: `${import.meta.env.VITE_PA_URL}Account`,
    OpenSecondAccountPageUrl: `${import.meta.env.VITE_PA_URL}?ReturnUrl=%2Fpersonalarea%2FLiveAccounts%2FOpenSecondAccount`,
    SetLanguageUrl: "v1/oa/SetLanguage",
    DemoAccountsPageUrl: `${import.meta.env.VITE_PA_URL}DemoAccounts`,
    AcademyPageUrl: "https://activtrades.academy/",
    BrazilianCorporateSite: "https://activtrades.com.br",
    MarkTranslationForSpecificArea: "v1/translations/MarkTranslationForSpecificArea",
    GetStepsNamesAndIdsUrl: "/v1/oa/getstepsnamesandids/getstepsnamesandids",
    GetStepByIdUrl: "/v1/oa/GetStepById",
    LogUrl: "v1/internal/Log",
    BrContactFooterUrl: "https://www.activtrades.com/pt/dados-de-contato",
    CorporateLinksUrl: `v1/Tools/GetCorporateUrls`,
    GetCountriesUrl: `v1/Tools/GetCountries`,

    OnfidoTestUrl: "v1/onfido/createtestworkflow",
    OnfidoStartUrl: "v1/onfido/OnlineApplication"
};
