<template>
    <div class="tax-residency">
        <div v-for="(question, index) in questions" v-bind:key="question.Id" class="group">
            <div v-if="question.IsVisible" :key="index">
                <div :key="index">
                    <div class="flex-row">
                        <AppCountryDropdown v-bind:question="question" v-bind:errors="errors" v-bind:disableOptions="disabledOptions"></AppCountryDropdown>

                        <div v-if="showRemoveButton(index)" @click="remove(index)" class="remove-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fa-svg fa-solid fa-trash-can">
                                <path
                                    d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="align-start">
            <a href="javascript:void(0)" @click="add" class="add-btn" v-if="addedNationalities < 4">
                <span class="add-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-solid fa-circle-plus">
                        <path
                            d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256 114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                    </svg>
                </span>
                {{ $t("lbl_another_nationality") }}
            </a>
        </div>
    </div>
</template>

<script>
import Questions from "../Questions.vue";
import AppCountryDropdown from "@/components/oa/AppCountryDropdown.vue";
import AppCountryTins from "@/components/oa/AppCountryTins.vue";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";

export default {
    name: "MultipleNationalities",
    components: {
        Questions,
        AppCountryDropdown,
        AppCountryTins,
    },
    props: ["questions", "errors", "setFieldError"],
    data() {
        return {
            options: {},
            cleaveOptions: {},
            tins: [],
        };
    },
    created() {
        this.setDefaultVisability();
    },
    updated() {
        this.setDefaultVisability();
    },
    methods: {
        setDefaultVisability() {
            this.questions.forEach((question, index) => {
                question.IsVisible = question.IsVisible || (question.SelectedAnswers[0] != null && question.SelectedAnswers[0] > 0) || index == 0 ? true : false;
            });

            this.applicationStore.applyValidations();
        },
        add() {
            const firstNotVisibleQuestion = this.questions.find((x) => !x.IsVisible);
            if (firstNotVisibleQuestion != null) {
                firstNotVisibleQuestion.IsVisible = true;
                firstNotVisibleQuestion.SelectedAnswers[0] = 0;
            }

            this.applicationStore.applyValidations();
        },
        remove(index) {
            if (this.questions[index] != null) {
                const itemsLeft = this.questions.filter((x) => x.IsVisible).length - 1 - index;

                if (!itemsLeft) {
                    this.questions[index].IsVisible = false;
                    this.questions[index].SelectedAnswers[0] = 0;
                    return;
                }

                for (let i = index; i < this.questions.length - 1; i++) {
                    const current = this.questions[i];
                    const next = this.questions[i + 1];

                    if (next.SelectedAnswers[0]) {
                        current.IsVisible = true;
                        current.SelectedAnswers[0] = next.SelectedAnswers[0];

                        next.IsVisible = false;
                        next.SelectedAnswers[0] = 0;
                    }
                }
            }

            this.applicationStore.applyValidations();
        },
        showRemoveButton(index) {
            return index != 0;
        },
    },
    computed: {
        ...mapStores(useApplicationStore),
        disabledOptions() {
            var answers = [];
            this.questions.forEach((question) => {
                if (question.SelectedAnswers[0] != null && question.SelectedAnswers[0] > 0) {
                    answers.push(question.SelectedAnswers[0]);
                }
            });
            return answers;
        },
        addedNationalities() {
            return this.questions.filter((x) => x.IsVisible).length - 1;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_buttons.scss";
@import "src/assets/styles/_animations.scss";

.text-residency {
    text-align: left;
}
.rtl .text-residency {
    text-align: right;
}

.flex-row {
    display: flex;
    gap: var(--form-control-padding);
}
.flex-row > div:first-child {
    flex-grow: 1;
}
</style>
