<template>
  <div class="form-field">
    <Field
      as="select"
      :name="question.FieldName"
      :type="FieldType"
      class="form-control"
      :class="{ 'is-invalid': errors[question.FieldName] }"
      v-model="question.Value"
      v-bind:placeholder="$t(question.TextKey)"
    >
      <option value="Mr">Mr</option>
      <option value="Ms">Ms</option>
    </Field>

    <label class="small-label">{{ $t(question.TextKey) }}</label>
    <ErrorMessage v-bind:name="question.FieldName" />
  </div>
</template>

<script>
export default {
  name: "UISelect",
  props: ["question", "errors"],
};
</script>
