<template>
    <div class="form-field">
        <Field as="input" name="searchableAddress" :placeholder="$t('start_typing_and_select_lbl')" @blur="hide" class="form-control" v-model="this.text" type="input"></Field>
        <label class="small-label">{{ $t("oa_lbl_start_typing") }}</label>
        <span role="alert" v-if="uiStore.containsError('searchableAddress')">{{ $t("oa_search_function_error") }}</span>
        <div v-if="this.isVisible" class="dropdown">
            <div v-for="(address, index) in this.addresses" v-bind:key="index" @mousedown.prevent="selectItem(address)">
                {{ address.Text }}
                <span v-if="address.Type !== 'Address'">
                    &rsaquo;
                    <span class="green-link">{{ address.Description }}</span>
                </span>
            </div>
        </div>

        <ErrorMessage name="searchableAddress" />
    </div>
</template>

<script>
import addressService from "@/services/addresses.service";

import { useUiStore } from "@/store/interface.js";
import { useLanguagesStore } from "@/store/languages.js";
import { mapStores } from "pinia";

export default {
    name: "UIAddressSearch",
    props: ["country", "errors"],
    emits: ["on-address-selected"],
    data() {
        return {
            text: null,
            displayDropdown: false,
            addresses: [],
            isSelected: false,
        };
    },
    watch: {
        async text(newText, oldText) {
            const isInvalid = !this.errors["searchableAddress"] && !!this.text && !!this.text.length && !this.isSelected;
            if (isInvalid) {
                this.uiStore.addError("searchableAddress");
            } else {
                this.uiStore.removeError("searchableAddress");
            }

            if (!newText || !newText.length || newText.length < import.meta.env.VITE_GBG_MIN_SYMBOLS || this.isSelected) {
                this.displayDropdown = false;
                this.addresses = [];
                this.isSelected = false;
                return;
            }

            if (newText != oldText && newText.length >= import.meta.env.VITE_GBG_MIN_SYMBOLS) {
                await this.findAddress();
            }
        },
    },
    methods: {
        async findAddress(containter) {
            if (!this.text) {
                return;
            }

            const result = await addressService.findAddress(
                this.text,
                this.country.Iso3Code,
                import.meta.env.VITE_GBG_RESULTS_LIMIT,
                this.languagesStore.preferedLanguageShortName,
                containter
            );
            this.setItems(result);
        },
        setItems(items) {
            this.addresses = items;
            this.displayDropdown = true;
        },
        async selectItem(address) {
            if (address.Type !== "Address") {
                await this.findAddress(address.Id);
                return;
            }

            this.isSelected = true;
            this.text = address.Text;
            this.displayDropdown = false;
            this.uiStore.removeError("searchableAddress");
            await this.retrieveAddress(address.Id);
        },
        async retrieveAddress(id) {
            const result = await addressService.retrieveAddress(id);
            this.$emit("on-address-selected", result);
        },
        hide() {
            this.isSelected = false;
            this.displayDropdown = false;
        },
        show() {
            if (this.addresses.length) {
                this.displayDropdown = true;
            }
        },
    },
    computed: {
        ...mapStores(useUiStore, useLanguagesStore),
        isVisible() {
            return this.displayDropdown && this.addresses != null && this.addresses.length !== 0 && !this.isSelected;
        },
    },
};
</script>
<style lang="scss" scoped>
.form-control {
    cursor: pointer;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

input::-webkit-input-placeholder {
    color: transparent;
}

input:focus::-webkit-input-placeholder {
    color: #888;
}

.dropdown > div {
    padding: 1.125rem 0.875rem 0.875rem;
    border-bottom: 1px solid var(--border-main);
    text-align: left;
    cursor: pointer;
}

@media (pointer: fine) {
    .dropdown > div:hover {
        background: var(--hover-secondary);
    }
}

.green-link {
    color: var(--accent);
    text-decoration: underline;
}
</style>
