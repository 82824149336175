<template>
    <footer>
        <!-- Footer content -->
        <div class="footer-flex">
            <div>
                <div class="help-container">
                    <div class="help-msg">
                        {{ $t("oa_automatic_save_proggress_txt") }}
                    </div>
                    <PickLanguage position="bottom"></PickLanguage>
                </div>
            </div>
            <div class="chat-placeholder"></div>
        </div>
    </footer>
</template>

<script>
import urls from "@/data/corporateUrls";

import { CorporatePage } from "@/models/CorporatePage.js";
import PickLanguage from "./PickLanguage.vue";
import { useLinksStore } from "@/store/links.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";

export default {
    name: "Footer",
    components: {
        PickLanguage,
    },
    computed: {
        ...mapStores(useLinksStore, useApplicationStore),
        contactUsUrl() {
            return this.linksStore.getLink(CorporatePage.Contact);
        },
    },
};
</script>

<style lang="scss">
footer {
    display: flex;
    justify-content: flex-start;
    color: var(--font-main);
    background: var(--bg-secondary);
    font-size: 1rem;
    line-height: 1.2;
    border-top: 1px solid var(--bg-main);
}

.footer-flex {
    direction: ltr;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: var(--double-padding) var(--double-padding) 28px;

    @media (max-width: 430px) {
        padding-bottom: 35px;
    }
}

.footer-flex > div:first-child {
    flex-grow: 1;
}

.chat-placeholder {
    @media (max-width: 768px) {
        width: 100px;
    }

    @media (max-width: 620px) {
        flex-basis: 100px;
        flex-shrink: 0;
    }

    @media (max-width: 430px) {
        flex-basis: 60px;
    }
}

.help-container {
    position: relative;
}

.help-msg {
    position: relative;
    flex-grow: 1;
    color: var(--font-tertiary);
    font-size: 0.875rem;
    text-align: center;

    @media (max-width: 768px) {
        padding-left: 100px;
    }

    @media (max-width: 430px) {
        padding: 0;
        text-align: left;
    }
}

// .rtl .help-msg {
//     @media (max-width: 768px) {
//         padding-right: 100px;
//         padding-left: 0;
//     }

//     @media (max-width: 430px) {
//         padding: 0;
//         text-align: right;
//     }
// }

.help-msg a {
    color: var(--accent);
}
</style>
