<template>
    <img
        :src="srcConverted"
        :style="'width: ' + width + 'rem; height: ' + height + 'rem'"
        class="flag-img"
    />
</template>

<script>
export default {
    name: "UIFlag",
    props: {
        src: String,
        width: String,
        height: String,
        dir: { type: String, default: "4x3" },
    },
    data() {
        return {
            srcConverted: "", // Initialize as empty or a default value
        };
    },
    methods: {
        async fetchFlag() {
            try {
                const modules = import.meta.glob(`/src/assets/images/flags/**`);
                var result = await modules[`/src/assets/images/flags/${this.dir}/${this.src?.toLowerCase()}.svg`]();
                this.srcConverted = result.default;
            } catch (error) {
                this.srcConverted = "";
            }
        },
    },
    watch: {
        src: {
            immediate: true,
            handler() {
                this.fetchFlag();
            },
        },
    },
};
</script>

<style lang="scss"></style>
