<template>
    <!-- <div ref="el" id="btn-flex-small-sc" :class="{ 'small-screen': smallScreen, mobile: uiStore.isMobile }" :style="{ bottom: footerHeight }"> -->
    <div ref="buttonWrapper" id="btn-flex-small-sc" :class="{ 'small-screen': smallScreen, mobile: uiStore.isMobile, 'is-pinned': isPinned }">
        <slot></slot>
        <div v-if="props.buttonKey != null && showButton" class="submit-button">
            <button id="submit_button" type="submit" @click="submit" ref="button">
                {{ $t(props.buttonKey) }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, watch, ref } from "vue";
import { useUiStore } from "@/store/interface.js";
import { useMutationObserver } from "@vueuse/core";

const uiStore = useUiStore();
const props = defineProps({
    buttonKey: {
        type: String,
        requred: true,
    },
    showButton: {
        type: Boolean,
        default: true,
    },
    errors: {
        type: Object,
        requred: true,
    },
});
const emit = defineEmits(["on-padding-bottom-changed"]);
const smallScreen = ref(false);
const footerHeight = ref("");
const isPinned = ref(false);

const buttonWrapper = ref(null);
const button = ref(null);

watch(button, async (newValue) => {
    if (newValue) {
        const el = document.querySelector("#btn-flex-small-sc");
        const observer = new IntersectionObserver(
            ([e]) => {
                isPinned.value = e.intersectionRatio < 1;
            },
            { threshold: [1] }
        );
        observer.observe(el);
    }
});

useMutationObserver(
    buttonWrapper,
    () => {
        recalculate();
    },
    { attributes: true, childList: true, subtree: true }
);

function calculatePaddingBottom() {
    const newPaddingBottom = smallScreen.value ? document.querySelector("#btn-flex-small-sc")?.offsetHeight : 0;
    emit("on-padding-bottom-changed", newPaddingBottom);
}

function getFooterHeight() {
    setTimeout(() => {
        footerHeight.value = document.querySelector("footer").offsetHeight + "px";
    }, 0);
}

function isSmallScreen() {
    let app = document.querySelector(".main-content");
    let screen = +app.clientHeight; //height of an element's content, only visible on the screen
    let content = +app.scrollHeight; //height of an element's content, including content not visible on the screen due to overflow

    if (content <= screen) {
        smallScreen.value = false;
    } else {
        smallScreen.value = true;
    }
}

function recalculate() {
    getFooterHeight();
    isSmallScreen();
    calculatePaddingBottom();
}

onMounted(() => {
    recalculate();
    window.addEventListener("resize", recalculate);
});

onUnmounted(() => {
    window.removeEventListener("resize", recalculate);
});
</script>

<style lang="scss" scoped>
.small-screen {
    position: sticky;
    bottom: -1px;
    left: calc(50% - 6px);
    width: var(--application-width);
    max-width: 100%;
    background: var(--bg-secondary);
    padding: 0 0 15px;
    z-index: 7;
}

.small-screen:after {
    content: "";
    display: block;
    position: absolute;
    top: -14px;
    left: 0;
    right: 0;
    height: 14px;
    background-image: linear-gradient(0deg, var(--bg-secondary) 0%, rgb(var(--bg-secondary-rgb) / 0%) 100%);
}

.small-screen.mobile {
    left: calc(50%);
}

.small-screen:empty {
    display: none;
}

.is-pinned {
    padding: 0 0 35px;
}
</style>
