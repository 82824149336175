import { defineStore } from "pinia";
import { ref, computed } from "vue";
import cookies from "@/common/cookies";
import EnOrigin from "@/enums/EnOrigin";

export const useUiStore = defineStore("ui", () => {
    const isSubmitButtonVisible = ref(true);
    const isSubmitButtonManuallyDisabled = ref(false);
    const isSubmitButtonDisabled = computed(() => customInvalidFields.value.length > 0 || isSubmitButtonManuallyDisabled.value);
    const isMobile = computed(() => {
        const agent = navigator.userAgent || navigator.vendor || window.opera;
        return (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                agent
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                agent.substr(0, 4)
            )
        );
    });

    const isSkipButtonEnabled = ref(true);
    const customInvalidFields = ref([]);

    const appTheme = ref("");

    const onfidoThemes = {
       
        //-------------LIGHT THEME------------------
        "theme-light": {
            fontFamilyTitle: "Lato, Arial, sans-serif",
            colorContentTitle: "#3c4650",
            fontFamilySubtitle: "Lato, Arial, sans-serif",
            fontWeightSubtitle: "400",
            fontFamilyBody: "Lato, Arial, sans-serif",
            fontSizeBody: "1rem",
            fontWeightBody: "400",
            colorContentSubtitle: "#7e8c9a",
            colorContentBody: "#7e8c9a",

            borderWidthSurfaceModal: "0px",
            colorBackgroundSurfaceModal: "#ffffff",
            borderRadiusButton: "4px",

            colorBorderButtonPrimary: "#1480eb",
            colorBackgroundButtonPrimary: "#1480eb",
            colorContentButtonPrimaryText: "#ffffff",
            colorBackgroundButtonPrimaryHover: "#1a7cdc",
            colorBorderButtonPrimaryHover: "#1a7cdc",
            colorBackgroundButtonPrimaryActive: "#1a7cdc",
            colorBorderButtonPrimaryActive: "#1a7cdc",

            colorContentButtonSecondaryText: "#1976d2",
            colorBackgroundButtonSecondary: "#ffffff",
            colorBackgroundButtonSecondaryHover: "#fff",
            colorBackgroundButtonSecondaryActive: "#fff",
            colorBorderButtonSecondary: "#1976d2",
            colorBorderButtonSecondaryHover: "#1a7cdc",
            colorBorderButtonSecondaryActive: "#1a7cdc",

            colorContentButtonTertiaryText: "#1480eb",

            colorBorderLinkUnderline: "transparent",
            colorBackgroundLinkHover: "transparent",
            colorBackgroundLinkActive: "transparent",
            colorContentLinkTextHover: "#1480eb",
            colorBackgroundButtonIconHover: "transparent",

            colorBackgroundDocTypeButton: "#ffffff",
            colorBackgroundDocTypeButtonHover: "rgb(130 143 162 / 10%)",
            colorBackgroundDocTypeButtonActive: "rgb(25 118 210 / 0.25)",
            colorContentDocTypeButton: "#3c4650",
            colorBorderDocTypeButton: "#DBDBF0",
            colorBorderDocTypeButtonHover: "#DBDBF0",
            colorBorderDocTypeButtonActive: "#1480eb",

            colorBackgroundInfoPill: "#3c4650",
            colorContentInfoPill: "#ffffff",
            colorBackgroundIcon: "#D5DAE0",
            colorIcon: "#1480eb",
            colorBackgroundInput: "#ffffff",
            colorContentInput: "#7e8c9a",
            colorInputOutline: "#1976d2",

            colorBackgroundAlertInfo: "#002857",
            colorBackgroundQRCode: "#ffffff",
            buttonGroupStacked: false,
            colorBackgroundSelector: "#f0f0f5",
            colorBackgroundButtonCameraHover: "#1976d2",
            colorBackgroundButtonCameraActive: "#1976d2",

            colorBackgroundLinkFocus: "#1976d2",
            colorContentDisclaimer: "#045FB9",
            colorBackgroundDisclaimer: "rgb(20 128 235 / 15%)",
        },
        ////-------------------DARK THEME-------------------------
        "theme-dark": {
            fontFamilyTitle: "Lato, Arial, sans-serif",
            colorContentTitle: "#d1d4dc",
            fontFamilySubtitle: "Lato, Arial, sans-serif",
            fontWeightSubtitle: "400",
            fontFamilyBody: "Lato, Arial, sans-serif",
            fontSizeBody: "1rem",
            fontWeightBody: "400",
            colorContentSubtitle: "#9ba5af",
            colorContentBody: "#9ba5af",

            borderWidthSurfaceModal: "0px",
            colorBackgroundSurfaceModal: "#131722",
            borderRadiusButton: "4px",

            colorBorderButtonPrimary: "#1480eb",
            colorBackgroundButtonPrimary: "#1480eb",
            colorContentButtonPrimaryText: "#ffffff",
            colorBackgroundButtonPrimaryHover: "#1a7cdc",
            colorBorderButtonPrimaryHover: "#1a7cdc",
            colorBackgroundButtonPrimaryActive: "#1a7cdc",
            colorBorderButtonPrimaryActive: "#1a7cdc",

            colorContentButtonSecondaryText: "#1976d2",
            colorBackgroundButtonSecondary: "#131722",
            colorBackgroundButtonSecondaryHover: "rgb(68 73 82 / 10%)",
            colorBackgroundButtonSecondaryActive: "rgb(68 73 82 / 10%)",
            colorBorderButtonSecondary: "#1976d2",
            colorBorderButtonSecondaryHover: "#1a7cdc",
            colorBorderButtonSecondaryActive: "#1a7cdc",

            colorContentButtonTertiaryText: "#1480eb",

            colorBorderLinkUnderline: "transparent",
            colorBackgroundLinkHover: "transparent",
            colorBackgroundLinkActive: "transparent",
            colorContentLinkTextHover: "#1480eb",
            colorBackgroundButtonIconHover: "transparent",

            colorBackgroundDocTypeButton: "#131722",
            colorBackgroundDocTypeButtonHover: "rgb(68 73 82 / 10%)",
            colorBackgroundDocTypeButtonActive: "rgb(25 118 210 / 0.25)",
            colorContentDocTypeButton: "#d1d4dc",
            colorBorderDocTypeButton: "#2C3247",
            colorBorderDocTypeButtonHover: "#2C3247",
            colorBorderDocTypeButtonActive: "#1480eb",

            colorBackgroundInfoPill: "#d1d4dc",
            colorContentInfoPill: "#131722",
            colorBackgroundIcon: "#D5DAE0",
            colorIcon: "#1480eb",
            colorBackgroundInput: "#131722",
            colorContentInput: "#9ba5af",
            colorInputOutline: "#1976d2",

            colorBackgroundAlertInfo: "#002857",
            colorBackgroundQRCode: "#131722",
            buttonGroupStacked: false,
            colorBackgroundSelector: "#212731",
            colorBackgroundButtonCameraHover: "#1976d2",
            colorBackgroundButtonCameraActive: "#1976d2",

            colorBackgroundLinkFocus: "#1976d2",
            colorContentDisclaimer: "#2E97FF",
            colorBackgroundDisclaimer: "rgb(20 128 235 / 15%)",
        },
    };

    const onfidoUi = computed(() => {
        return onfidoThemes[appTheme.value];
    });

    const containsError = (name) => customInvalidFields.value.find((x) => x === name);

    const isDemo = ref(false);

    const origin = ref(EnOrigin.Unspecified);

    function setOrigin(newOrigin) {
        if (typeof newOrigin === "number" && !isNaN(newOrigin)) {
            origin.value = newOrigin;
            return;
        }

        const normalizedPlatformName = newOrigin.toLowerCase();

        const origins = Object.keys(EnOrigin).find((key) => key.toLowerCase() === normalizedPlatformName);

        const returnOrigin = origins !== undefined ? EnOrigin[origins] : undefined;

        origin.value = returnOrigin;
    }

    function setIsDemo(isDemoClient) {
        isDemo.value = isDemoClient;
    }

    function hideSubmitButton() {
        isSubmitButtonVisible.value = false;
    }

    function showSubmitButton() {
        isSubmitButtonVisible.value = true;
    }

    function addError(errorField) {
        if (customInvalidFields.value.findIndex((x) => x === errorField) === -1) {
            customInvalidFields.value.push(errorField);
        }
    }

    function removeError(errorField) {
        customInvalidFields.value = customInvalidFields.value.filter((x) => x !== errorField);
    }

    function clearAllCustomErrors() {
        customInvalidFields.value = [];
    }

    function enableSkipButton() {
        isSkipButtonEnabled.value = true;
    }

    function disableSkipButton() {
        isSkipButtonEnabled.value = false;
    }

    function disableSubmitButton() {
        isSubmitButtonManuallyDisabled.value = true;
    }

    function enableSubmitButton() {
        isSubmitButtonManuallyDisabled.value = false;
    }

    function applyThemeClassToBody(newTheme) {
        const themeValue = `theme-${newTheme}`;
        const body = document.getElementById("body");
        if (body) {
            if (body.classList.contains(appTheme.value) && appTheme.value !== themeValue) {
                body.classList.replace(appTheme.value, themeValue);
            } else {
                body.classList.add(themeValue);
            }
        }
    }

    function changeAppTheme(newTheme) {
        if (newTheme !== "light" && newTheme !== "dark") {
            return;
        }

        applyThemeClassToBody(newTheme);
        appTheme.value = `theme-${newTheme}`;
    }

    function initializeTheme() {
        if (!appTheme.value) {
            const cookieTheme = cookies.get("theme");
            if (!cookieTheme) {
                changeAppTheme("light");
                return;
            }

            changeAppTheme(cookieTheme);
            return;
        }
    }

    return {
        isSkipButtonEnabled,
        isSubmitButtonDisabled,
        isSubmitButtonVisible,
        customInvalidFields,
        containsError,
        hideSubmitButton,
        showSubmitButton,
        addError,
        removeError,
        clearAllCustomErrors,
        enableSkipButton,
        disableSkipButton,
        enableSubmitButton,
        disableSubmitButton,
        appTheme,
        changeAppTheme,
        onfidoUi,
        initializeTheme,
        isMobile,
        origin,
        setOrigin,
        setIsDemo,
        isDemo,
    };
});
