<template>
    <div class="scrollable">
        <div :class="[paddingBottomApplied ? 'padding-bottom' : '', props.class]">
            <div>
                <slot></slot>
                <UIFlexButton :errors="props.errors" :buttonKey="props.buttonKey" :showButton="props.showButton" @on-padding-bottom-changed="onPaddingBottomChanged">
                    <slot name="button">
                        <div class="hr" v-if="props.questions"></div>
                        <div class="margin-top-double" v-if="props.questions"></div>
                        <Questions :questions="props.questions" :errors="props.errors"></Questions>
                    </slot>
                </UIFlexButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import Questions from "@/views/onlineapplication/questions/Questions.vue";
import UIFlexButton from "@/components/ui/UIFlexButton.vue";

const props = defineProps({
    questions: Array,
    errors: {
        type: Object,
        requred: true,
    },
    class: String,
    buttonKey: {
        type: String,
        requred: true,
    },
    showButton: {
        type: Boolean,
        default: true,
    },
});

const paddingBottomApplied = ref(false);
const paddingBottom = ref("0px");

function onPaddingBottomChanged(padding) {
    paddingBottomApplied.value = !!padding;
    paddingBottom.value = `${padding}px`;
}
</script>

<style scoped>
.hr {
    border-top: 1px solid var(--bg-main);
    margin-bottom: var(--double-padding);
}
.select-branch .margin-top-double,
.select-branch .hr {
    display: none;
}
</style>
