import OAService from "@/services/onlineapplication.service";
import Validator from "@/common/validator";
import endpoints from "../common/endpoints";
import eventBus from "@/eventBus";
import constants from "@/common/constants";
import { useUiStore } from "@/store/interface.js";
import { useLanguagesStore } from "@/store/languages.js";
import { defineStore } from "pinia";
import { ref, reactive, computed } from "vue";
import cookies from "@/common/cookies";
import { broadcastEvent } from "@/common/broadcaster.js";
import EnOrigin from "@/enums/EnOrigin";
import { EnEvent } from "@/enums/EnEvent";
import DemoService from "@/services/demo.service.js";
import AuthService from "@/services/auth.service.js";

export const useApplicationStore = defineStore("application", () => {
    const uiStore = useUiStore();
    const languagesStore = useLanguagesStore();
    const completionPercent = ref(0);
    const currentStep = ref({});
    const serverSideErrors = ref([]);
    const uploadedDocuments = reactive({
        document: {},
        poa: {},
        selfie: {},
    });
    const validationSchema = ref([]);
    const loading = ref(true);
    const continueApplicationLink = ref(undefined);
    const canGoForward = ref(false);
    const customerStatus = ref("");
    const submitStepFailed = ref(false);
    const sessionExpired = ref(false);
    const continuationLinkExpired = ref(false);
    const countryOfResidence = ref({});
    const isFlowUpdated = ref(false);
    const initialLoaded = ref(false);
    const preferedCountryId = ref(0);
    const companyBranch = ref("");
    const oaModelId = ref(-1);
    const memberId = ref(-1);
    const flow = computed(() => currentStep.value.FlowType);
    const translationsParams = computed(() => currentStep.value.TranslationsParameters);
    const isFirstStep = computed(() => currentStep.value.DisplayType === "Start");
    const isLastStep = computed(() => currentStep.value.DisplayType === "Final");
    const skipFileUploadAllowed = computed(() => {
        const flows = currentStep.value?.FlowType?.split(", ");
        return !!!flows?.find((x) => x === "Brazil");
    });

    const oaReference = computed(() => {
        if (!continueApplicationLink.value) {
            return null;
        }
        return continueApplicationLink.value.match(/[0-9a-fA-F]{32}/g)[0];
    });

    async function load() {
        const lang = languagesStore.preferedLanguageShortName;
        const result = await OAService.start(lang);
        await handleStep(result.data);
        await setBranch(result.data.CompanyBranch);
        endLoadingStep();
        return result;
    }

    function startLoadingStep() {
        loading.value = true;
        uiStore.disableSubmitButton();
    }

    function setInitialLoadedState(value) {
        initialLoaded.value = value;
    }

    function endLoadingStep() {
        uiStore.enableSubmitButton();
        loading.value = false;
        applyValidations();
    }

    function applySearchableAddressValidation() {
        validationSchema.value = Validator.AddressSearchSchema;
    }

    function markAsStartNew() {
        const question = currentStep.value.Questions.find((x) => x.UniqueKey === "Q_DemoClientLoginType");
        if (!question) return;

        const startNewAnswer = question.Answers.find((x) => x.UniqueKey === "A_Q_DemoClientLoginType_2");
        if (!startNewAnswer) return;

        question.SelectedAnswers[0] = startNewAnswer.Id;
        customerStatus.value.startNewIsSelected = true;
    }

    async function resetPassword(email) {
        const submitResult = await OAService.resetPassword(email);
        return submitResult.Status == 200;
    }

    async function getLoginKey(memberId) {
        if (uiStore.origin == EnOrigin.WebPlatform || uiStore.origin == EnOrigin.iOS || uiStore.origin == EnOrigin.Android) {
            const result = await AuthService.getLoginKey(memberId);
            return result.data;
        }

        return "";
    }

    async function handleStep(data) {
        if (data.GtmEventData?.event && window.dataLayer) {
            window.dataLayer.push(data.GtmEventData);
        }

        if (data.Status == "AutoLogin") {
            if (uiStore.origin == EnOrigin.Unspecified) {
                let url = endpoints.PersonalAreaAccountPageUrl;

                if (data.AutoLoginKey != "") {
                    url += "?autoLoginKey=" + data.AutoLoginKey + "&lang=" + languagesStore.preferedLanguageShortName;
                }

                window.location = url;
                return;
            }

            if (uiStore.origin == EnOrigin.WebPlatform || uiStore.origin == EnOrigin.iOS || uiStore.origin == EnOrigin.Android) {
                await DemoService.createOrUpdateActivTradesDemo(oaReference.value);
            }

            const loginKey = await getLoginKey(memberId.value);
            const event = {
                type: EnEvent.OaSubmitted,
                memberId: memberId.value,
                oaReference: oaReference.value,
                loginKey: loginKey,
                pendingActions: data.PendingActions,
            };
            broadcastEvent(uiStore.origin, event);
            return;
        }

        if (data.Status == "OaFlowUpdated") {
            isFlowUpdated.value = true;
            return;
        }

        if (data.Status == "SubmitStepFailed") {
            submitStepFailed.value = true;
            return;
        }

        if (data.Status == "SessionExpired") {
            sessionExpired.value = true;
            return;
        }

        if (data.Status == "ContinuationLinkExpired") {
            continuationLinkExpired.value = true;
            return;
        }

        if (data.CompletionPercent) {
            completionPercent.value = data.CompletionPercent;
        }

        if (data.CountryOfResidence) {
            countryOfResidence.value = data.CountryOfResidence;
        }

        if (data.OaModelId) {
            oaModelId.value = data.OaModelId;
        }

        if (data.MemberId) {
            memberId.value = data.MemberId;
        }

        let preferedCountry = data.ClientCountry;
        if (preferedCountry == null || preferedCountry == 0) {
            preferedCountry = {
                Id: constants.BrazilId,
            };
        }

        if (preferedCountry) {
            preferedCountryId.value = preferedCountry.Id;
        }

        // if (data.Language) {
        //     eventBus.$emit("change-language", data.Language);
        // }

        if (data.CurrentStep != null) {
            currentStep.value = data.CurrentStep;
            endLoadingStep();
        }

        if (data.FailedValidations != null && !!Object.keys(data.FailedValidations).length) {
            serverSideErrors.value = data.FailedValidations;
            endLoadingStep();
        }
        if (data.ContinueApplicationLink) {
            continueApplicationLink.value = data.ContinueApplicationLink;
        }
    }

    function applyValidations() {
        const model = currentStep.value;
        if (model != null) {
            Validator.Questions = model.Questions;
        }

        validationSchema.value = Validator.GetSchema;
    }

    async function getNextStep() {
        startLoadingStep();

        const result = await OAService.getNextStepBackend(currentStep.value);
        await handleStep(result.data);

        endLoadingStep();
    }

    async function submit() {
        const data = currentStep.value;
        data.Submitted = true;
        // data.IsSkipEnabled = false;

        startLoadingStep();

        if (currentStep.value.AwaitSubmitResult) {
            const submitResult = await OAService.submit(data);
            await realoadLanguagesIfNeeded(submitResult.data);
            const submitData = submitResult.data;
            submitData.isServerAction = true;

            redirectIfContinueApplicationLink(submitData);

            if (submitData != null && submitData.data != null && submitData.data.CompanyBranch != null) {
                await setBranch(submitData.data.CompanyBranch);
            }

            await handleStep(submitData);
            return;
        }

        const result = await OAService.getNextStepBackend(data);
        await realoadLanguagesIfNeeded(result.data);
        const isServerAction = result.data == null;
        if (isServerAction) {
            return;
        }

        redirectIfContinueApplicationLink(result.data);
        await handleStep(result.data);
        endLoadingStep();

        var submitStep = await OAService.submit(data);
        await realoadLanguagesIfNeeded(submitStep.data);
        await setBranch(submitStep.data.CompanyBranch);

        if (submitStep.data.OaModelId) {
            oaModelId.value = submitStep.data.OaModelId;
        }

        if (submitStep.data.MemberId) {
            memberId.value = submitStep.data.MemberId;
        }

        if (submitStep.data.GtmEventData?.event && window.dataLayer) {
            window.dataLayer.push(submitStep.data.GtmEventData);
        }
    }

    async function setBranch(branch) {
        if (branch != null && companyBranch.value != branch) {
            cookies.set("branch", branch);
            companyBranch.value = branch;
            // await languagesStore.load(companyBranch.value);
        }
    }

    async function realoadLanguagesIfNeeded(data) {
        if (data.CompanyBranch && data.Status === "ReloadLanguages") {
            await languagesStore.load(data.CompanyBranch);
        }
    }

    function getBranch() {
        if (companyBranch.value) {
            return companyBranch.value;
        }

        return cookies.get("branch");
    }

    function redirectIfContinueApplicationLink(data) {
        if (data.ContinueApplicationLink != null && data.Status == "ContinueApplicationRedirect") {
            continueApplicationLink.value = data.ContinueApplicationLink;

            const currentParams = new URLSearchParams(window.location.search);
            const continueUrl = new URL(data.ContinueApplicationLink);
            currentParams.forEach((value, key) => {
                continueUrl.searchParams.append(key, value);
            });
            window.location = continueUrl.toString();
            return;
        }
    }

    function updatePreferedCountry(countryId) {
        preferedCountryId.value = countryId;
    }

    async function setNextStepIfNoAnswerIsChanged() {
        await getNextStep();
    }

    function changeCustomerStatus(status) {
        customerStatus.value = status;
    }

    async function skipStep() {
        loading.value = true;
        const response = await OAService.skipStep(currentStep.value.Id, currentStep.value.SkipToStepId);
        const result = response.data;
        if (result.Succeeded) {
            const nextStep = result.Data;

            currentStep.value.Submitted = true;
            currentStep.value = nextStep;
            loading.value = false;

            eventBus.$emit("CurrentStepChanged", true);
            applyValidations();
        }
    }

    async function getPreviuousStep() {
        startLoadingStep();

        const getPreviousStepBackend = await OAService.getPreviousStepBackend(currentStep.value);
        await handleStep(getPreviousStepBackend.data);
        endLoadingStep();
    }

    async function closeApplication() {
        const { data } = await OAService.close();
        if (data.Data.Status == "SessionExpired") {
            sessionExpired.value = true;
        } else {
            window.location = data.Data.ReturnUrl;
        }
    }

    return {
        completionPercent,
        currentStep,
        serverSideErrors,
        uploadedDocuments,
        validationSchema,
        loading,
        continueApplicationLink,
        canGoForward,
        customerStatus,
        submitStepFailed,
        sessionExpired,
        continuationLinkExpired,
        countryOfResidence,
        isFlowUpdated,
        initialLoaded,
        preferedCountryId,
        flow,
        translationsParams,
        isFirstStep,
        isLastStep,
        skipFileUploadAllowed,
        applySearchableAddressValidation,
        load,
        updatePreferedCountry,
        setNextStepIfNoAnswerIsChanged,
        changeCustomerStatus,
        submit,
        applyValidations,
        skipStep,
        getPreviuousStep,
        setInitialLoadedState,
        startLoadingStep,
        endLoadingStep,
        oaReference,
        getBranch,
        oaModelId,
        memberId,
        markAsStartNew,
        resetPassword,
        closeApplication,
        getLoginKey,
    };
});
