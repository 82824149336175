<template>
    <div class="application failed-oa">
        <div class="image"></div>
        <h1>{{ $t("oa_session_expired_title") }}</h1>
        <p v-html="$t('oa_session_expired_message')"></p>
    </div>
</template>
<script>
export default {
    name: "SessionExpired",
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_failed.scss";
</style>
