import { createRouter, createWebHistory } from "vue-router";
import BrazilOa from "@/views/BrazilOa.vue";
import Demo from "@/views/demo/DemoView.vue";

const routes = [
    {
        path: "/",
        component: BrazilOa,
    },
    {
        path: "/demo",
        component: Demo,
    },
    {
        path: "/personalarea/OA/demo",
        component: Demo,
    },
    {
        path: "/personalarea/OA",
        component: BrazilOa,
    },
    {
        path: "/:catchAll(.*)",
        component: BrazilOa,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    base: import.meta.env.VITE_DEFAULT_PATH,
});

export default router;
