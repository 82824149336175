<template>
    <div class="radio-group">
        <div v-for="(answer, i) in question.Answers" v-bind:key="answer.Id" class="radio-btn" :class="{ active: answer.Id == question.SelectedAnswers[0] }">
            <input
                name="Answer"
                type="radio"
                v-bind:id="question.FieldName + answer.Id"
                v-bind:value="answer.Value"
                v-model="question.Value"
                @click="change(answer)"
                :class="{ selected: this.question.SelectedAnswers[0] == answer.Id }" />
            <label v-bind:for="question.FieldName + answer.Id">{{ $t(answer.TextKey) }}</label>
        </div>
    </div>
</template>

<script>
import constants from "@/common/constants";
import eventBus from "@/eventBus";

export default {
    name: "UISelectableRadio",
    props: ["question", "errors"],
    data() {
        return {};
    },
    methods: {
        change(answer) {
            if (this.question.SelectedAnswers[0] != 0 && this.question.SelectedAnswers[0] == answer.Id) {
                return false;
            }
            this.question.SelectedAnswers[0] = answer.Id;
        },
    },
    created() {
        const firstAnswer = this.question.Answers[0];
        this.question.SelectedAnswers[0] = firstAnswer.Id;
    },
};
</script>

<style lang="scss" scoped>
input[type="radio"] {
    display: block;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.rtl input[type="radio"] {
    right: 0;
    left: auto;
}

.radio-group {
    width: 7rem;
    background: var(--form-control-bgr);
    border: 1px solid var(--border-main);
    border-radius: var(--border-radius-main);
    padding: 0.188rem;
}

label {
    flex-grow: 1;
    text-align: center;
    padding: 0.85rem 0;
    font-size: 1rem;
    line-height: 1;
    color: var(--font-tertiary);
}

.radio-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 50%;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.radio-btn.active label,
.radio-btn:focus label {
    background: var(--font-main);
    color: var(--bg-secondary);
    border-radius: calc(var(--border-radius-main) / 2);
}

.radio-btn.active:first-child label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.radio-btn.active:last-child label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .radio-btn.active:first-child label {
    border-top-right-radius: calc(var(--border-radius-main) / 2);
    border-bottom-right-radius: calc(var(--border-radius-main) / 2);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .radio-btn.active:last-child label {
    border-top-left-radius: calc(var(--border-radius-main) / 2);
    border-bottom-left-radius: calc(var(--border-radius-main) / 2);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
</style>
