<template>
    <div class="branch">
        <div class="heading">
            <i class="flag uk"></i>
            <div>
                <h1>{{ $t("activtrades_plc") }}</h1>
                <h2>{{ $t("activtrades_plc_subtitle") }}</h2>
            </div>
        </div>
        <div class="data">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div class="tick-icon"><i class="checkmark"></i></div>
                            <div class="data-row">
                                <div>{{ $t("ls_leverage_up_to") }}</div>
                                <div class="value">{{ $t("ls_leverage_up_to_value") }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="tick-icon"><i class="checkmark"></i></div>
                            <div class="data-row">
                                <div>{{ $t("ls_segregated_account") }}</div>
                                <div class="value">{{ $t("ls_yes") }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="tick-icon"><i class="checkmark"></i></div>
                            <div class="data-row">
                                <div>{{ $t("ls_balance_protection") }}</div>
                                <div class="value">{{ $t("ls_yes") }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="tick-icon"><i class="checkmark"></i></div>
                            <div class="data-row">
                                <div>{{ $t("ls_funds_insurance") }}</div>
                                <div class="value">{{ $t("ls_insurance_uk") }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="tick-icon"><i class="checkmark"></i></div>
                            <div class="data-row">
                                <div>{{ $t("lbl_base_currency") }}</div>
                                <div class="value">{{ $t("ls_base_currency") }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="tick-icon"><i class="checkmark"></i></div>
                            <div class="data-row">
                                <div>{{ $t("ls_customer_support") }}</div>
                                <div class="value">{{ $t("ls_yes") }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="tick-icon"><i class="checkmark"></i></div>
                            <div class="data-row">
                                <div>{{ $t("ls_professional_account") }}</div>
                                <div class="value">{{ $t("na_abbrev") }}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "UkBranchSelectPopover",
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_branch.scss";
</style>
