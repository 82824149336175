<template>
    <div class="center-container">
        <div>
            <h1>{{ $t("oa_te_failed_title") }}</h1>
        </div>

        <div>
            <p v-html="$t('oa_uklu_te_failed_content', [demoAccountsPageUrl])"></p>
        </div>
    </div>
</template>

<script>
import endpoints from "@/common/endpoints";

export default {
    name: "TradingXpUkLuFailed",
    computed: {
        demoAccountsPageUrl() {
            return endpoints.DemoAccountsPageUrl;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_profiles.scss";

.center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
i {
    font-size: 7rem;
    margin-bottom: 1.625rem;
    color: #d1d1d6;
}
h1 {
    margin-top: 0;
}
</style>
