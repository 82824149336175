import http from "@/backend/vue-axios/axios";

class LogService {
    log(urlConstant, data) {
        try {
            return http.post(urlConstant, data);
        } catch (e) {
            console.error("FAILURE!!");
        }
    }
}

export default new LogService();
