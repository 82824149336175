<template>
    <AppScrollable :questions="props.questions" :errors="props.errors" :buttonKey="checkbox.SaveButtonKey" class="'container fail-trading-experience-bahamas'">
        <div>
            <p v-html="$t('oa_bh_te_failed_content', [demoAccountsPageUrl])"></p>
        </div>
    </AppScrollable>
</template>

<script setup>
import { computed } from "vue";
import endpoints from "@/common/endpoints";
import AppScrollable from "@/components/oa/AppScrollable.vue";

const props = defineProps({
    questions: Array,
    errors: Object,
});

const demoAccountsPageUrl = computed(() => endpoints.DemoAccountsPageUrl);
const checkbox = computed(() => props.questions.find((x) => x.FieldType == "Checkbox"));
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_profiles.scss";

.max-100 {
    height: auto;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin-top: 0;
}

.flex-switch {
    @media (max-width: 430px) {
        height: auto;
    }
}
</style>
