<template>
    <label class="checkbox">
        <div class="card-component" :class="{ 'is-selectable': isSelectAvailable, 'is-selected': isSelected, 'is-readonly': readonly }">
            <i :class="iconClass" class="grid-icon"></i>
            <div class="grid-checkbox">
                <input type="checkbox" v-model="isSelected" :required="required" @change="emitCheck" :disabled="readonly" />
                <div class="fake-checkbox" v-if="isSelectAvailable"></div>
            </div>
            <div class="grid-name">
                {{ $t(translationKey) }}
                <span v-if="readonly">({{ $t("err_required") }})</span>
            </div>
        </div>
    </label>
</template>

<script setup>
import { computed, ref, toRefs, watch } from "vue";

const props = defineProps({
    index: Number,
    readonly: Boolean,
    disabled: Boolean,
    icon: String,
    translationKey: String,
    selected: Boolean,
    isSelectAvailable: Boolean,
});

const { icon, translationKey, required, isSelectAvailable, selected, readonly, disabled } = toRefs(props);

const isSelected = ref(props.selected);

watch(selected, (newValue) => {
    isSelected.value = newValue;
});

const iconClass = computed(() => `icon-${icon.value}`);

const emit = defineEmits(["updateOption"]);

const emitCheck = () => {
    emit("updateOption", { index: props.index, selected: isSelected.value });
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_checkbox.scss";
.card-component {
    display: grid;
    grid-template-columns: max-content minmax(1px, 1fr) max-content;
    grid-template-areas: "icon name checkbox";
    gap: 0.625rem;
    width: 100%;
    height: auto;
    border: 0;
    padding: 0;
    border-radius: var(--border-radius-main);
    text-align: left;
    cursor: default;
}
.rtl .card-component {
    text-align: right;
}
.card-component.is-selectable {
    border: 1px solid var(--border-secondary);
    padding: 0.75rem;
}
.card-component.is-selectable:hover {
    background: var(--hover-main);
    border-color: var(--border-main);
    cursor: pointer;
}
.card-component.is-selectable:hover .fake-checkbox {
    border-color: var(--border-main);
}
.card-component.is-selectable.is-selected:hover .fake-checkbox {
    border-color: var(--accent);
}
.card-component.is-selectable.is-selected.is-readonly:hover .fake-checkbox {
    border-color: transparent;
}
.card-component.is-selectable.is-selected {
    background: var(--accent-selected-background);
    border-color: var(--accent);
}
.card-component.is-selectable.is-selected .grid-name {
    color: var(--accent);
}
.secondary .card-component {
    grid-template-areas: "icon name name";
    gap: 0.625rem;
    width: auto;
    height: auto;
    padding: 0;
    border: 0;
}

.secondary .card-component .grid-checkbox {
    display: none;
}
.secondary .card-component .grid-name {
    align-self: center;
}

.icon-fa-id-card,
.icon-fa-passport,
.icon-fa-driving-license,
.icon-fa-selfie,
.icon-fa-residence-permit,
.icon-fa-proof-address {
    width: 32px;
    height: 28px;
}
.icon-fa-id-card {
    background: url("@/assets/images/verification/id-card-duotone.svg") no-repeat center center;
}
.icon-fa-passport {
    background: url("@/assets/images/verification/passport-duotone.svg") no-repeat center center;
}
.icon-fa-driving-license {
    background: url("@/assets/images/verification/driving-license-duotone.svg") no-repeat center center;
}
.icon-fa-selfie {
    background: url("@/assets/images/verification/selfie-duotone.svg") no-repeat center center;
}
.icon-fa-residence-permit {
    background: url("@/assets/images/verification/residence-permit-duotone.svg") no-repeat center center;
}
.icon-fa-proof-address {
    background: url("@/assets/images/verification/proof-address-duotone.svg") no-repeat center center;
}

.theme-dark,
.theme-light {
    .icon-fa-id-card {
        background: url("@/assets/images/verification/id-card-duotone-blue.svg") no-repeat center center;
    }
    .icon-fa-passport {
        background: url("@/assets/images/verification/passport-duotone-blue.svg") no-repeat center center;
    }
    .icon-fa-driving-license {
        background: url("@/assets/images/verification/driving-license-duotone-blue.svg") no-repeat center center;
    }
    .icon-fa-selfie {
        background: url("@/assets/images/verification/selfie-duotone-blue.svg") no-repeat center center;
    }
    .icon-fa-residence-permit {
        background: url("@/assets/images/verification/residence-permit-duotone-blue.svg") no-repeat center center;
    }
    .icon-fa-proof-address {
        background: url("@/assets/images/verification/proof-address-duotone-blue.svg") no-repeat center center;
    }
}

.grid-icon {
    grid-area: icon;
}
.grid-checkbox {
    grid-area: checkbox;
}
.grid-name {
    position: relative;
    top: 2px;
    grid-area: name;
    align-self: center;
    color: var(--font-main);
    font-size: 1rem;
}
.grid-name span {
    font-size: 0.75rem;
    padding-top: 0.375rem;
}
label.checkbox {
    flex-grow: 0;
    width: auto;
    margin: 0;
    padding: 0;
    font-weight: normal;
}
label.checkbox.error .card-component,
label.checkbox.error .fake-checkbox {
    border-color: var(--error);
}
.fake-checkbox {
    width: 1.375rem;
    height: 1.375rem;
    margin: 0;
}
.fake-checkbox:after {
    top: 0.25rem;
    left: 0.4375rem;
    width: 0.625rem;
    height: 0.3125rem;
    border-color: var(--accent);
}
.rtl .fake-checkbox {
    margin-left: 0;
    margin-right: 0;
}
@media (pointer: fine) {
    .fake-checkbox[data-v-d04eccdd]:hover {
        border: 1px solid var(--border-main);
    }
}

.is-selected .fake-checkbox {
    border-color: var(--accent);
}
.is-readonly .fake-checkbox {
    border: 1px solid transparent;
    background: transparent;
}
</style>
