import { createI18n } from "vue-i18n";
import eventBus from "@/eventBus";

var displayMissingTranslations = false;

export function setupI18n(
  options = {
    locale: "en",
    warnHtmlInMessage: "off",
    silentTranslationWarn: true

    // missing: (locale, key) => {
    //   if (displayMissingTranslations) {
    //     translationService.markMissingTranslation(key);
    //   }
    // },
  }
) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);

  eventBus.$on("default-language-loaded", (event) => {
    displayMissingTranslations = true;
  })

  return i18n;
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector("html").setAttribute("lang", locale);
}

// export async function loadLocaleMessages(i18n, locale) {
//   // load locale messages with dynamic import
//   const messages = await import(
//     /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
//   );

//   // set locale and locale message
//   i18n.global.setLocaleMessage(locale, messages.default);

//   return nextTick();
// }

export default setupI18n();

// import { createI18n } from 'vue-i18n'
// import cookies from '@/common/cookies';
// import translationService from '@/services/translation.service';

// /**
//  * Load locale messages
//  *
//  * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
//  * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
//  */
// function loadLocaleMessages() {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key).default
//     }
//   })
//   return messages
// }

// // export async function loadLocaleMessages(i18n, locale) {

// //   console.log(123)
// //   // load locale messages with dynamic import
// //   const messages = await import(
// //     /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
// //   )

// //   // set locale and locale message
// //   i18n.global.setLocaleMessage(locale, messages.default)

// //   return nextTick()
// // }

// const instance = createI18n({
//   legacy: false,
//   locale: cookies.get("lang") || process.env.VUE_APP_I18N_LOCALE,
//   fallbackLocale: cookies.get("lang") || process.env.VUE_APP_I18N_FALLBACK_LOCALE,
//   messages: loadLocaleMessages(),
//   globalInjection: true,
//   missing: (locale, key) => {
//     console.log(1232);
//     console.log(key);
//     translationService.markMissingTranslation(key);
//   }
// });

// loadDynamic
// console.log(instance)

// export default instance
