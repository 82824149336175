<template>
  <div>
    <Field
      as="hidden"
      :name="question.FieldName"
      :class="{ 'is-invalid': errors[question.FieldName] }"
      v-model="question.DisplayType"
    >
    </Field>

    <component v-bind:is="component" v-bind:question="question"></component>
  </div>
</template>

<script>
import EmptyAnswerModel from "@/models/EmptyAnswerModel";
import StarterProfile from "@/components/oa/trading_profiles/StarterProfile.vue";
import ExplorerProfile from "@/components/oa/trading_profiles/ExplorerProfile.vue";
import ExpertProfile from "@/components/oa/trading_profiles/ExpertProfile.vue";

export default {
  name: "AppTradingProfile",
  props: ["question", "errors"],
  components: {
    StarterProfile,
    ExplorerProfile,
    ExpertProfile,
  },
  methods: {},
  mounted() {
    if (this.question.Answers == null || this.question.Answers.length == 0) {
      const emptyAnswer = new EmptyAnswerModel();
      emptyAnswer.QuestionId = question.Id;
      emptyAnswer.Value = question.DisplayType;
      this.question.Answers.push(emptyAnswer);
    }
  },
  computed: {
    component() {
      switch (this.question.DisplayType) {
        case "Starter":
          return "StarterProfile";
        case "Explorer":
          return "ExplorerProfile";
        case "Expert":
          return "ExpertProfile";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.813rem;
}

img {
  width: 7.5rem;
}

h2 {
  font-weight: 500;
}
</style>