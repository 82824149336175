<template>
    <div v-if="this.applicationStore.getBranch() && this.languagesStore.preferedLanguageShortName">
        <AppChatZenvia v-if="showZenviaChat" />
        <AppChatIntercom v-else />
    </div>
</template>

<script>
import AppChatZenvia from "@/components/oa/AppChatZenvia.vue";
import AppChatIntercom from "@/components/oa/AppChatIntercom.vue";
import { useLanguagesStore } from "@/store/languages.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";

export default {
    name: "AppChat",
    components: {
        AppChatZenvia,
        AppChatIntercom,
    },
    computed: {
        ...mapStores(useLanguagesStore, useApplicationStore),
        showZenviaChat() {
            return (
                this.applicationStore.getBranch().toUpperCase() === "BR" ||
                (this.applicationStore.getBranch().toUpperCase() === "BH" && this.languagesStore.preferedLanguageShortName.toUpperCase() === "PT")
            );
        },
    },
};
</script>
