<template>
    <div class="application failed-oa">
        <div class="wrapper">
            <div class="image"></div>
            <h1>{{ $t("oa_link_expired") }}</h1>
            <p v-html="$t('oa_continuation_link_expired_body', [contactUrl, '', location])"></p>
        </div>
    </div>
</template>

<script>
import { CorporatePage } from "@/models/CorporatePage.js";
import { useLinksStore } from "@/store/links.js";
import { mapStores } from "pinia";

export default {
    name: "ContinuationLinkExpired",
    computed: {
        ...mapStores(useLinksStore),
        location() {
            return document.location.origin + "/personalarea/oa/";
        },
        contactUrl() {
            const corporateWebsiteUrls = this.linksStore.getLink(CorporatePage.Contact);
            return corporateWebsiteUrls;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_failed.scss";

.wrapper {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
</style>
