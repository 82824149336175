export default class EmptyAnswerModel {
  constructor() {
    this.Conditions = null,
    this.DisplayCondition = "None",
    this.HintKey = null,
    this.Id = null,
    this.Order = 0,
    this.QuestionId = 0,
    this.SFValue = null,
    this.TextKey = null,
    this.Value = "";
  }
}
