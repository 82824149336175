<template>
    <div class="container">
        <div>
            <p v-html="$t('oa_te_failed_content', [demoAccountsPageUrl, academyPageUrl])"></p>
        </div>

        <div>
            <p>{{ $t("oa_te_failed_retake") }}</p>

            <a class="btn btn-retake" @click="retake">
                {{ $t("retake_oa_questions") }}
            </a>
        </div>
    </div>
</template>

<script>
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import gsap from "gsap";
import eventBus from "@/eventBus";
import endpoints from "@/common/endpoints";

export default {
    name: "TradingXpFailed",
    data() {
        return {
            instructionsVisible: false,
            activeTab: 1,
        };
    },
    computed: {
        ...mapStores(useApplicationStore),
        demoAccountsPageUrl() {
            return endpoints.DemoAccountsPageUrl;
        },
        academyPageUrl() {
            return endpoints.AcademyPageUrl;
        },
    },
    methods: {
        showInstructions() {
            this.instructionsVisible = true;
        },

        closeInstructions() {
            this.instructionsVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_profiles.scss";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 0;
}
</style>
