<template>
    <div
        class="layout"
        v-if="applicationStore.initialLoaded"
        v-bind:class="[getStepClass(), languagesStore.preferedLanguageShortName == 'ae' ? 'rtl' : '']"
        :dir="languagesStore.preferedLanguageShortName == 'ae' ? 'rtl' : 'ltr'">
        <slot name="header">
            <Header :scrolling="isMainContentScrolled"></Header>
        </slot>
        <div id="main-content" class="main-content" @scroll="handleMainContentScroll">
            <slot></slot>
            <Footer></Footer>
        </div>
        <AppChat
            v-cloak
            v-if="
                applicationStore.currentStep &&
                applicationStore.currentStep.DisplayType &&
                applicationStore.currentStep.DisplayType !== 'Start' &&
                applicationStore.currentStep.DisplayType !== 'SelectBranch'
            " />
    </div>
</template>

<script>
import Header from "@/layout/Header.vue";
import Footer from "@/layout/Footer.vue";
import OnlineApplication from "@/views/onlineapplication/Steps.vue";
import SomethingWentWrongView from "@/views/SomethingWentWrongView.vue";
import SessionExpired from "@/views/onlineapplication/SessionExpired.vue";
import ContinuationLinkExpired from "@/views/onlineapplication/ContinuationLinkExpired.vue";
import OaFlowUpdated from "@/views/onlineapplication/OaFlowUpdated.vue";
import { useCountriesStore } from "@/store/countries.js";
import { useLanguagesStore } from "@/store/languages.js";
import { useLinksStore } from "@/store/links.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import { useUiStore } from "@/store/interface.js";
import EnOrigin from "@/enums/EnOrigin";

export default {
    name: "MainLayout",
    components: {
        Header,
        Footer,
        OnlineApplication,
        SomethingWentWrongView,
        SessionExpired,
        ContinuationLinkExpired,
        OaFlowUpdated,
    },
    data() {
        return {
            isMainContentScrolled: false,
        };
    },
    computed: {
        ...mapStores(useCountriesStore, useLanguagesStore, useLinksStore, useApplicationStore, useUiStore),
    },
    methods: {
        getStepClass() {
            const currentStep = this.applicationStore.currentStep;

            var isMobileUi = this.uiStore.origin === EnOrigin.iOS || this.uiStore.origin === EnOrigin.Android;
            if ((currentStep && currentStep.DisplayType && currentStep.DisplayType !== "Start") || isMobileUi) {
                this.hideRecaptcha();
            }

            switch (currentStep.DisplayType) {
                case "Start":
                    return "homepage";
                case "IdVerification":
                    return "id-verification";
                case "UploadDocuments":
                    return "upload-documents";
                case "PlatformSelection":
                    return "platform-selection";
                case "TraderProfile":
                    return "trader-profile";
                case "SuitabilityFailed":
                    return "suitability-failed";
                case "Final":
                    return "final";
                case "SelectBranch":
                    return "select-branch";
                case "TradingXpUkLuFailed":
                    return "trading-failed";
                case "TradingXpBhGenFailed":
                    return "trading-failed bahamas-branch";
                case "TradingExperienceBegin":
                    return "section-page";
                case "KnowledgeTestBegin":
                    return "section-page";
            }
        },

        hideRecaptcha() {
            let styleEl = document.createElement("style");
            styleEl.id = "hide-recaptcha-style";
            styleEl.innerHTML = ".grecaptcha-badge { display: none !important; }";
            document.head.appendChild(styleEl);
        },

        handleMainContentScroll(e) {
            if (e.target.scrollTop > 14) {
                this.isMainContentScrolled = true;
            } else {
                this.isMainContentScrolled = false;
            }
        },
    },
    async created() {
        await this.countriesStore.load();
        await this.linksStore.load();
    },
};
</script>

<style lang="scss"></style>
