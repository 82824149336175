<template>
    <div v-if="showSearchInput">
        <UIAddressSearch :errors="errors" :country="applicationStore.countryOfResidence" @on-address-selected="onAddressSelected"></UIAddressSearch>
        <p>
            {{ $t("oa_type_or_select") }} <a @click="showAddressFields"> {{ $t("manually_enter_address") }}</a>
        </p>
    </div>
    <div v-else>
        <Questions v-bind:questions="questions" v-bind:errors="errors"></Questions>
        <p v-if="isSearchAvaliable">
            {{ $t("oa_manually_enter_address") }} <a @click="showSearch"> {{ $t("oa_enter_address_from_list") }}</a>
        </p>
    </div>
</template>
<script>
import UIAddressSearch from "@/components/ui/UIAddressSearch.vue";
import Questions from "@/views/onlineapplication/questions/Questions.vue";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { useUiStore } from "@/store/interface.js";
import { mapStores } from "pinia";

export default {
    name: "Address",
    components: {
        UIAddressSearch,
        Questions,
    },
    props: ["questions", "errors", "setFieldValue"],
    data() {
        return {
            showSearchInput: false,
            isSearchAvaliable: true,
        };
    },
    computed: {
        ...mapStores(useApplicationStore, useUiStore),
    },
    methods: {
        onAddressSelected(item) {
            if (!item) {
                return;
            }

            for (let index = 0; index < this.questions.length; index++) {
                const question = this.questions[index];
                if (question.FieldName == "AddressFirstLine") {
                    question.Answers[0].Value = item.Line1;
                    continue;
                }

                if (question.FieldName === "AddressSecondLine") {
                    question.Answers[0].Value = item.Line2;
                    continue;
                }

                if (question.FieldName === "AddressPostcode" || question.FieldName === "AddressPostcodeGB") {
                    question.Answers[0].Value = item.PostalCode;
                    continue;
                }

                if (question.FieldName === "GBGSearchId") {
                    question.Answers[0].Value = item.Id;
                    continue;
                }

                if (question.FieldName === "AddressTown") {
                    this.setDropdownValue(question, item.City);
                    continue;
                }

                if (question.FieldName === "AddressProvince") {
                    this.setDropdownValue(question, item.ProvinceName);
                    continue;
                }

                if (question.FieldName === "AddressCityTextGen") {
                    question.Answers[0].Value = item.City;
                    continue;
                }
            }

            this.showAddressFields();
        },

        setDropdownValue(question, value) {
            const answer = question.Answers.find((x) => x.AdditionalData.TranslatedDropdown == value);
            question.SelectedAnswers[0] = answer && answer.Id ? answer.Id : 0;
        },

        showSearch() {
            this.applicationStore.applySearchableAddressValidation();
            this.showSearchInput = true;
            this.uiStore.removeError("searchableAddress");
        },

        showAddressFields() {
            this.showSearchInput = false;
            this.applicationStore.applyValidations();
        },
    },
    created() {
        if (this.applicationStore.countryOfResidence && this.applicationStore.countryOfResidence.IsAddressSearchSupported && !this.questions[0].Answers[0].Value) {
            this.showSearch();
        } else {
            this.isSearchAvaliable = false;
            this.showAddressFields();
        }
    },
};
</script>

<style>
a {
    cursor: pointer;
}
</style>
