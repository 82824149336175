<template>
    <AppScrollable :errors="props.errors" :buttonKey="props.nextButtonKey" :showButton="showButton">
        <template #default>
            <Questions :questions="props.questions" :errors="props.errors"></Questions>
        </template>
    </AppScrollable>
</template>
<script setup>
import { computed } from "vue";
import Questions from "@/views/onlineapplication/questions/Questions.vue";
import AppScrollable from "@/components/oa/AppScrollable.vue";
import { useUiStore } from "@/store/interface.js";

const props = defineProps({
    questions: Array,
    errors: {
        type: Object,
        requred: true,
    },
    nextButtonKey: {
        type: String,
        requred: true,
    },
});

const uiStore = useUiStore();

const showButton = computed(() => {
    return uiStore.isMobile ? props.questions[0].SelectedAnswers[0] > 0 : true;
});
</script>
