<template>
    <a href="#" @click="toggle" class="close-btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fa-svg fa-thin fa-xmark-large">
            <path
                d="M13.7 34.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L212.7 256 2.3 466.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0L224 267.3 434.3 477.7c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L235.3 256 445.7 45.7c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L224 244.7 13.7 34.3z" />
        </svg>
    </a>
    <div class="popup" v-if="isOppened">
        <div class="container">
            <div class="question-text">
                <h1>{{ $t("lbl_are_you_sure") }}</h1>
                <div class="percent-text">{{ $t("lbl_less_then_five_min") }}</div>
            </div>
            <div class="popup-buttons-container">
                <button @click="toggle" class="continue-btn">{{ $t("btn_continue") }}</button>
                <button @click="exit" class="secondary-btn">{{ $t("btn_exit") }}</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useUiStore } from "@/store/interface.js";
import EnOrigin from "@/enums/EnOrigin";

const emit = defineEmits(["on-exit-clicked"]);
const uiStore = useUiStore();

const isOppened = ref(false);

function toggle() {
    isOppened.value = !isOppened.value;
}

async function exit() {
    const isStartedFromExternalSource = uiStore.origin != EnOrigin.Unspecified;
    emit("on-exit-clicked", isStartedFromExternalSource);
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_popup.scss";

.close-btn,
.close-btn:visited,
.close-btn:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    grid-area: close;
    color: var(--font-main);
    fill: var(--font-main);
    text-decoration: none;
    line-height: 1;
    font-size: 24px;
}

.popup h1 {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 0 10px 0;
}

.popup h1:before {
    content: var(--exit-icon) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px !important;
}

@media (max-width: 430px) {
    .popup .question-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}
</style>
