<template>
    <div>
        <div class="profile-flex">
            <img src="@/assets/images/profile/starter.svg" alt="Starter profile" class="image-profile" />
            <span class="title starter">{{ $t("oa_trading_profile_starter_title") }}</span>
        </div>
        <div class="sorry">
            <div class="sorry-wrapper">
                <p>{{ $t("retake_oa_questions_title") }}</p>

                <a class="btn btn-retake" @click="retakeTest">
                    {{ $t("retake_oa_questions") }}
                </a>

                <a href="javascript:void(0)" @click="showInstructions" class="secondary-btn margin-top-single">
                    {{ $t("understand_more_about_profiles") }}
                </a>
            </div>
        </div>

        <!-- popup for profiles -->
        <div class="popup investor-profiles-popup" v-if="instructionsVisible">
            <div class="container">
                <div class="popup-content">
                    <div>
                        <h1>{{ $t("investor_profiles") }}</h1>
                        <p>{{ $t("investor_profiles_mandatory") }}</p>

                        <div class="profiles-carousel">
                            <div class="flex-tabs">
                                <div class="tab" v-bind:class="{ active: activeTab == 1 }" @click="activeTab = 1">
                                    <img src="@/assets/images/profile/starter.svg" alt="Starter profile" class="carousel-image-profile" />
                                    {{ $t("oa_trading_profile_starter_title") }}
                                </div>
                                <div class="tab" v-bind:class="{ active: activeTab == 2 }" @click="activeTab = 2">
                                    <img src="@/assets/images/profile/explorer.svg" alt="Explorer profile" class="carousel-image-profile" />
                                    {{ $t("oa_trading_profile_explorer_title") }}
                                </div>
                                <div class="tab" v-bind:class="{ active: activeTab == 3 }" @click="activeTab = 3">
                                    <img src="@/assets/images/profile/expert.svg" alt="Expert profile" class="carousel-image-profile" />
                                    {{ $t("oa_trading_profile_expert_title") }}
                                </div>
                            </div>

                            <div class="tab-panel" v-bind:class="{ hidden: activeTab != 1 }">
                                {{ $t("starter_profile_description") }}
                            </div>
                            <div class="tab-panel" v-bind:class="{ hidden: activeTab != 2 }">
                                {{ $t("explorer_profile_description") }}
                            </div>
                            <div class="tab-panel" v-bind:class="{ hidden: activeTab != 3 }">
                                {{ $t("exper_profile_description") }}
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn-blue mobile-btn full-width" @click="closeInstructions()">{{ $t("btn_ok") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { useDebounceFn } from "@vueuse/core";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";

export default {
    name: "StarterProfile",
    data() {
        return {
            instructionsVisible: false,
            activeTab: 1,
        };
    },
    computed: {
        ...mapStores(useApplicationStore),
        retakeTest() {
            return useDebounceFn(this.applicationStore.submit, 600);
        },
    },
    methods: {
        showInstructions() {
            this.instructionsVisible = true;
        },

        closeInstructions() {
            this.instructionsVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_profiles.scss";
@import "src/assets/styles/_popup.scss";
@import "src/assets/styles/_buttons.scss";

.btn-blue {
    margin-top: 2rem;
}

.investor-profiles-popup h1:before {
    content: url("@/assets/images/profile/profile.svg") !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--main-padding) !important;
}

.investor-profiles-popup h1 {
    margin-bottom: 0;
}

.title.starter {
    color: var(--error);
}

.popup .container {
    height: 670px;
    overflow-y: hidden;
    padding: 0;

    @media (max-width: 430px) {
        height: 100%;
    }
}

.popup-content {
    align-items: flex-start;
    overflow-y: auto;
    padding: var(--exit-padding);
}

.popup .container .btn-blue {
    margin: 2rem 0.875rem 35px;
    width: calc(100% - 2 * 0.875rem);
}
</style>
