<template>
    <MainLayout>
        <template #header>
            <Header
                :showProgressbar="applicationStore.currentStep.DisplayType != 'Start'"
                :showCloseButton="!applicationStore.sessionExpired && !applicationStore.continuationLinkExpired && !applicationStore.isFlowUpdated">
                <template #closeBtn>
                    <ExitPopup v-if="applicationStore.isFirstStep" @on-exit-clicked="handleExit"></ExitPopup>
                    <AppClose v-else></AppClose>
                </template>
            </Header>
        </template>
        <SomethingWentWrongView v-if="applicationStore.submitStepFailed"></SomethingWentWrongView>
        <SessionExpired v-else-if="applicationStore.sessionExpired"></SessionExpired>
        <OaFlowUpdated v-else-if="applicationStore.isFlowUpdated"></OaFlowUpdated>
        <ContinuationLinkExpired v-else-if="applicationStore.continuationLinkExpired"></ContinuationLinkExpired>
        <OnlineApplication v-else></OnlineApplication>
    </MainLayout>
</template>

<script setup>
import Header from "@/layout/Header.vue";
import AppClose from "@/layout/AppClose.vue";
import MainLayout from "@/layout/MainLayout.vue";
import OnlineApplication from "@/views/onlineapplication/Steps.vue";
import SomethingWentWrongView from "@/views/SomethingWentWrongView.vue";
import SessionExpired from "@/views/onlineapplication/SessionExpired.vue";
import ContinuationLinkExpired from "@/views/onlineapplication/ContinuationLinkExpired.vue";
import OaFlowUpdated from "@/views/onlineapplication/OaFlowUpdated.vue";
import { useApplicationStore } from "@/store/onlineapplication.js";
import ExitPopup from "@/layout/ExitPopup.vue";
import { onBeforeMount } from "vue";
import i18n from "@/i18n.js";
import { broadcastEvent } from "@/common/broadcaster.js";
import { EnEvent } from "@/enums/EnEvent";
import { useUiStore } from "@/store/interface.js";

const { t } = i18n.global;
const applicationStore = useApplicationStore();
const uiStore = useUiStore();

async function handleExit(isStartedFromExternalSource) {
    if (isStartedFromExternalSource) {
        broadcastEvent(uiStore.origin, { type: EnEvent.OaNotStarted });
        return;
    }

    await applicationStore.closeApplication();
}

onBeforeMount(async () => {
    await applicationStore.load();
});
</script>

<style lang="scss">
@import "src/assets/styles/main.scss";
</style>
