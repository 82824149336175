<template>
    <Suspense>
        <template #default>
            <div id="router-view" :class="uiStore.appTheme">
                <router-view />
            </div>
        </template>
        <template #fallback> <UILoader :delay="0"></UILoader> </template>
    </Suspense>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { useUiStore } from "@/store/interface.js";
import { useCountriesStore } from "@/store/countries.js";
import { useLanguagesStore } from "@/store/languages.js";
import { useLinksStore } from "@/store/links.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { useCommonStore } from "@/store/common.js";
import { injectGtm } from "@/common/injectGtm.js";
import constants from "@/common/constants";
import cookies from "@/common/cookies";
import { useGtm } from "@gtm-support/vue-gtm";
import { onBeforeMount } from "vue";
import translationService from "@/services/translation.service";
import eventBus from "@/eventBus";
import i18n from "@/i18n.js";
import UILoader from "@/components/ui/UILoader.vue";
const { t, setLocaleMessage } = i18n.global;

const uiStore = useUiStore();
uiStore.initializeTheme();

const languagesStore = useLanguagesStore();
const applicationStore = useApplicationStore();
const countriesStore = useCountriesStore();
const commonStore = useCommonStore();
const linksStore = useLinksStore();

const router = useRouter();
const route = useRoute();
const gtm = useGtm();

function setCookiesFromQuery() {
    const keys = Object.keys(route.query);

    keys.forEach((key) => {
        if (key == constants.OaReferenceQueryParam || key == constants.KillCacheQueryParam) {
            return;
        }

        if (key === constants.CookiesOptionsQueryParam) {
            const value = route.query[key];
            if (value.toLowerCase() !== constants.CookiesOptionsAll && value.toLowerCase() !== constants.CookiesOptionsEsential) {
                return;
            }
        }

        if (key == constants.MemberIdQueryParam) {
            cookies.set(constants.UserIdCookieKey, route.query[constants.MemberIdQueryParam]);
        }

        if (key === constants.ThemeQueryParam) {
            uiStore.changeAppTheme(route.query[key]);
        }

        if (key === constants.PlatformParam) {
            commonStore.platform = route.query[key];
        }

        if (key === constants.Branch) {
            commonStore.companyBranch = route.query[key];
        }

        if (key === constants.TestingGroup) {
            commonStore.testingGroup = route.query[key];
        }

        cookies.set(key, route.query[key]);
    });

    if (keys.find((x) => x == constants.KillCacheQueryParam)) {
        let query = Object.assign({}, route.query);
        delete query.killCache;
        router.replace({ query });
    }
}

function setDefaultQueryParams() {
    uiStore.setOrigin(route.query["origin"] || "Unspecified");
    uiStore.setIsDemo(route.query["isdemo"] === "true");
}

function handleGoogleTagManager() {
    const cookie = cookies.get("cookies_option");
    const shouldBeEnabled = !cookie || cookie?.toLowerCase() != constants.CookiesOptionsEsential;
    if (shouldBeEnabled) {
        injectGtm(import.meta.env.VITE_GTM_ID);
        gtm.enable();
    }
}

async function loadTranslations(lang) {
    if (lang == null) {
        return;
    }

    translationService.getAllTranslations(lang).then((result) => {
        eventBus.$emit("default-language-loaded", true);
        setLocaleMessage(lang, result.data);
        i18n.global.locale = lang;
        console.log(route.path);
        document.title = getPageName(route.path);
    });
    await applicationStore.setInitialLoadedState(true);
}

function getPageName(route) {
    const routeName = route.toLowerCase();

    if (routeName && routeName.includes("demo")) {
        return "Activtrades - Try a free demo account";
    }

    return t("oa_ttl");
}

onBeforeMount(async () => {
    await router.isReady();
    setCookiesFromQuery();
    setDefaultQueryParams();

    await languagesStore.load(commonStore.companyBranch);
    await countriesStore.load();
    await linksStore.load();

    const preferedLanguage = await languagesStore.getPreferedLanguage(route.query["lang"]);
    await languagesStore.setLanguage(preferedLanguage);

    i18n.global.locale = preferedLanguage || "en";
    await loadTranslations(preferedLanguage);

    handleGoogleTagManager();

    eventBus.$on("change-language", (lang) => {
        if (lang == null) {
            lang = "en";
        }
        if (i18n.global.locale !== lang) {
            loadTranslations(lang);
        }
    });
});
</script>
<style lang="scss">
html {
    overflow: hidden;
    overscroll-behavior: none;
}
html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--lato-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

:focus {
    outline: none;
}

#router-view {
    height: 100%;
}
</style>
