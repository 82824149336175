<template>
    <a href="#" @click="togglePopup" class="close-btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fa-svg fa-thin fa-xmark-large">
            <path
                d="M13.7 34.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L212.7 256 2.3 466.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0L224 267.3 434.3 477.7c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L235.3 256 445.7 45.7c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L224 244.7 13.7 34.3z" />
        </svg>
    </a>
    <!-- close() -->

    <div class="popup" v-if="isOppened">
        <div class="container">
            <div class="question-text">
                <h1>{{ $t("lbl_almost_there") }}</h1>
                <div v-html="$t('completed_percent', [Math.round(applicationStore.completionPercent)])" class="percent-text"></div>
            </div>
            <div class="popup-buttons-container">
                <button @click="togglePopup" class="continue-btn">{{ $t("btn_continue") }}</button>
                <button @click="closeApplication" class="secondary-btn">{{ $t("save_exit") }}</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useApplicationStore } from "@/store/onlineapplication.js";
import { useUiStore } from "@/store/interface.js";
import { ref } from "vue";
import { broadcastEvent } from "@/common/broadcaster.js";
import EnOrigin from "@/enums/EnOrigin";
import { EnEvent } from "@/enums/EnEvent";
import DemoService from "@/services/demo.service.js";

const isOppened = ref(false);
const applicationStore = useApplicationStore();
const uiStore = useUiStore();

function togglePopup() {
    isOppened.value = !isOppened.value;
}

async function closeApplication() {
    if (uiStore.origin == EnOrigin.Unspecified) {
        await applicationStore.closeApplication();
        return;
    }

    if (uiStore.origin == EnOrigin.WebPlatform || uiStore.origin == EnOrigin.iOS || uiStore.origin == EnOrigin.Android) {
        await DemoService.createOrUpdateActivTradesDemo(applicationStore.oaReference);
    }

    const eventType = applicationStore.currentStep.UniqueKey == "ST_TradingXpUkLuFailed" ? EnEvent.OaRejected : EnEvent.OaInProgress;
    const event = {
        type: eventType,
        memberId: applicationStore.memberId,
        loginKey: await applicationStore.getLoginKey(applicationStore.memberId),
        oaReference: applicationStore.oaReference,
    };

    broadcastEvent(uiStore.origin, event);
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_popup.scss";

.close-btn,
.close-btn:visited,
.close-btn:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    grid-area: close;
    color: var(--font-main);
    fill: var(--font-main);
    text-decoration: none;
    line-height: 1;
    font-size: 24px;
}

.popup h1 {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 0 10px 0;
}

.popup h1:before {
    content: var(--exit-icon) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px !important;
}

@media (max-width: 430px) {
    .popup .question-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}
</style>
