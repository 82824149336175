import http from "@/backend/vue-axios/axios";
import endpoints from "../common/endpoints";

class TranslationService {
    // markMissingTranslation(key) {
    //     if (key.trim() != "") {
    //         try {
    //             var url = endpoints.MarkTranslationForSpecificArea;
    //             return http.post(url, { key: key, subArea: 1 });
    //         } catch (e) {
    //             console.log("MarkMissingTranslation FAILURE!!");
    //         }
    //     }
    // }

    async getAllTranslations(lang) {
        try {
            const url = `v1/translations/GetBySubArea?subArea=1&langShortName=${lang}`;
            return http.get(url);
        } catch (e) {
            console.log("getAllTranslations FAILURE!!");
        }
    }
}

export default new TranslationService();
