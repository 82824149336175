<template>
    <div v-if="resetLinkSent" class="note success">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-normal fa-circle-check">
            <path
                d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
        </svg>
        <span>{{ $t("oa_reset_link_sent") }}</span>
    </div>
    <div v-else class="note" :class="{ loading: isLoading }">
        <span class="text"
            >{{ $t("link_cannot_access") }} <a @click.once="requestNewPassword">{{ $t("oa_request_reset_link") }}</a></span
        >
        <span class="loader">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-solid fa-loader">
                <path
                    d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V96c0 17.7 14.3 32 32 32s32-14.3 32-32V32zm0 384c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7 14.3 32 32 32s32-14.3 32-32V416zM0 256c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zm416-32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H416zM75 75c-12.5 12.5-12.5 32.8 0 45.3l45.3 45.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L120.2 75C107.7 62.5 87.5 62.5 75 75zM391.8 346.5c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L391.8 437c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-45.3-45.3zM75 437c12.5 12.5 32.8 12.5 45.3 0l45.3-45.3c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L75 391.8c-12.5 12.5-12.5 32.8 0 45.3zM346.5 120.2c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L437 120.2c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-45.3 45.3z" />
            </svg>
        </span>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useApplicationStore } from "@/store/onlineapplication.js";
import eventBus from "@/eventBus";
import { broadcastEvent } from "@/common/broadcaster.js";
import EnOrigin from "@/enums/EnOrigin";
import { EnEvent } from "@/enums/EnEvent";
import { useUiStore } from "@/store/interface.js";

const appStore = useApplicationStore();
const uiStore = useUiStore();
const email = computed(() => appStore.currentStep?.Questions.find((x) => x.UniqueKey === "Q_Email")?.Answers[0].Value);
const isLoading = ref(false);
const resetLinkSent = ref(false);

async function requestNewPassword() {
    isLoading.value = true;
    if (uiStore.origin == EnOrigin.Unspecified) {
        await appStore.resetPassword(email.value);
    } else {
        broadcastEvent(uiStore.origin, { type: EnEvent.ResetPassword, email: email.value });
    }

    setTimeout(() => {
        isLoading.value = false;
        resetLinkSent.value = true;
    }, 2000);
}

onMounted(() => {
    eventBus.$on("email-changed", () => {
        appStore.serverSideErrors = [];
        resetLinkSent.value = false;
        isLoading.value = false;
    });
});
</script>

<style lang="scss" scoped>
.loading .text {
    opacity: 0.5;
}
.loader {
    display: none;
}
.loading .loader {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -4px;
    text-align: center;
    font-size: 24px;
    line-height: 0.8;
}
.note {
    position: relative;
}
.note.success {
    color: var(--note-success);
}
.note.success svg {
    fill: var(--note-success);
}

.note .fa-loader {
    animation: rotateSpinner 1.5s linear infinite;
}

@keyframes rotateSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
