import eventBus from "@/eventBus";
import { useApplicationStore } from "@/store/onlineapplication.js";

function updateTraslation(el, binding) {
    var store = useApplicationStore();

    var translationKey = binding.value.key != null ? binding.value.key : binding.value;
    var params = binding.value.params != null ? binding.value.params : null;

    var branch = store.getBranch()?.toLowerCase();

    const getConvertedTranslationKey = (translationKey) => {
        if (translationKey.startsWith("^")) {
            translationKey = translationKey.substring(1);
            switch (branch) {
                case "br":
                    return translationKey + "_br";
                case "bh":
                    return translationKey + "_bah";
                case "lu":
                    return translationKey + "_lu";
                case "pt":
                    return translationKey + "_pt";
            }
        }
        return translationKey;
    };

    translationKey = getConvertedTranslationKey(translationKey);

    if (params != null) {
        params = getConvertedTranslationKey(params);
    }

    if (params != null) {
        el.innerHTML = binding.instance.$t(translationKey, [binding.instance.$t(params)]);
    } else {
        el.innerHTML = binding.instance.$t(translationKey);

        if (el.innerHTML == translationKey) {
            setTimeout(() => updateTraslation(el, binding), 1000);
        }
    }
}

export default {
    mounted(el, binding) {
        updateTraslation(el, binding);
        eventBus.$on("changeLanguage", () => {
            setTimeout(() => updateTraslation(el, binding), 1000);
        });
    },
};
