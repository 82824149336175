import LanguagesService from "@/services/languages.service";
import eventBus from "@/eventBus";
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useApplicationStore } from "./onlineapplication";
import cookies from "@/common/cookies";

export const useLanguagesStore = defineStore("languages", () => {
    const applicationStore = useApplicationStore();
    const languages = ref([]);
    const preferedLanguageShortName = computed(() => preferedLanguage.value?.ShortName ?? "en");
    const preferedLanguage = ref(null);

    const onfidoLanguage = computed(() => {
        if (preferedLanguage.value?.ShortName === "en") return "en_GB";

        if (preferedLanguage.value?.ShortName === "ae") return "ar";

        if (preferedLanguage.value?.ShortName === "cn") return "zh_CN";

        if (preferedLanguage.value?.ShortName === "hk") return "zh_TW";

        return preferedLanguage.value?.ShortName;
    });

    async function load(branch) {
        const result = await LanguagesService.getAll(branch);
        languages.value = result.data;

        await setLanguage(cookies.get("lang"));
    }

    async function setLanguage(language) {
        if (!languages.value.length) {
            await load(applicationStore.getBranch());
        }

        if (language) {
            const lang = languages.value.find((x) => x.ShortName == language) ?? languages.value.find((x) => x.IsDefault);
            await LanguagesService.setLanguage(lang.ShortName);

            preferedLanguage.value = lang;
            cookies.set("lang", lang.ShortName);
            eventBus.$emit("change-language", lang.ShortName);
        }
    }

    async function getPreferedLanguage(langFromQueryParam) {
        if (!languages.value.length) {
            await load(applicationStore.getBranch());
        }

        if (langFromQueryParam && languages.value.find((x) => x.ShortName === langFromQueryParam)) {
            return langFromQueryParam;
        }

        const langCookie = cookies.get("lang");
        if (langCookie && languages.value.find((x) => x.ShortName === langCookie)) {
            return langCookie;
        }

        cookies.set("lang", preferedLanguageShortName.value);
        return preferedLanguageShortName.value;
    }

    return {
        languages,
        preferedLanguage,
        preferedLanguageShortName,
        load,
        setLanguage,
        getPreferedLanguage,
        onfidoLanguage,
    };
});
