<template>
    <div class="form-field">
        <Field :name="question.FieldName" type="hidden" class="form-control" v-model="question.Answers[0].Value"></Field>

        <p v-if="question.TextKey" class="lead">{{ $t(question.TextKey) }}</p>
        <textarea v-model="question.Answers[0].Value" class="form-control" :class="{ 'is-invalid': errors[question.FieldName] }"></textarea>
        <ErrorMessage v-bind:name="question.FieldName" />
    </div>
</template>

<script>
import EmptyAnswerModel from "@/models/EmptyAnswerModel";
export default {
    name: "UITextArea",
    props: ["question", "errors"],
    beforeMount() {
        if (this.question.Answers == null || this.question.Answers.length == 0) {
            this.question.Answers.push(new EmptyAnswerModel());
        }
    },
};
</script>

<style lang="scss" scoped>
textarea.form-control {
    width: 100%;
    min-height: 200px;
    padding: 0.5rem 0.75rem;
    resize: vertical;
}
.lead {
    margin-top: -1.25rem;
}
</style>
