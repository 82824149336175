<template>
    <header :class="{ scrolling: scrolling }">
        <div v-if="props.showArrows" class="arrows">
            <a href="javascript:void(0);" @click="showPreviousStep" class="back-arrow-btn" v-bind:class="{ visible: applicationStore.currentStep.CanGoBack }">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fa-svg fa-thin fa-chevron-left">
                    <path
                        d="M18.3 250.3c-3.1 3.1-3.1 8.2 0 11.3l216 216c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L35.3 256 245.7 45.7c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0l-216 216z" />
                </svg>
            </a>
            <a
                href="javascript:void(0);"
                @click="applicationStore.getNextStep"
                class="next-arrow-btn"
                v-bind:class="{ visible: applicationStore.canGoForward && applicationStore.currentStep.Submitted }"
                v-if="applicationStore.canGoForward && applicationStore.currentStep.Submitted">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fa-svg fa-thin fa-chevron-right">
                    <path
                        d="M301.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-216 216c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L284.7 256 74.3 45.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l216 216z" />
                </svg>
            </a>
        </div>
        <div class="close-oa" v-if="props.showCloseButton">
            <slot name="closeBtn"> </slot>
        </div>
        <div class="activtrades-logo">
            <div class="logo-img">
                <img src="/src/assets/images/activtrades-logo-blue.svg" class="theme-corporate-logo theme-light-logo" />
                <img src="/src/assets/images/activtrades-logo.svg" class="theme-dark-logo" />
            </div>
        </div>
        <div v-if="props.showProgressbar" class="progressbar-container">
            <div class="progressbar">
                <div class="progress" v-bind:style="{ width: applicationStore.completionPercent + '%' }"></div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { useUiStore } from "@/store/interface.js";
import { useApplicationStore } from "@/store/onlineapplication.js";

const props = defineProps({
    scrolling: {
        type: Boolean,
        default: false,
    },
    showArrows: {
        type: Boolean,
        default: true,
    },
    showProgressbar: {
        type: Boolean,
        default: true,
    },
    isFirstStep: {
        type: Boolean,
        default: false,
    },
    showCloseButton: {
        type: Boolean,
        default: false,
    },
});

const uiStore = useUiStore();
const applicationStore = useApplicationStore();

function showPreviousStep() {
    if (applicationStore.currentStep.CanGoBack) {
        uiStore.clearAllCustomErrors();
        applicationStore.getPreviuousStep();
    }
}
</script>

<style lang="scss" scoped>
header {
    grid-area: oa-header;
    position: relative;
    background: var(--bg-secondary);
    width: 100%;
    margin: 0 auto;
    padding: var(--main-padding) var(--double-padding) 0;
}

header:after {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    right: 0;
    height: 14px;
    background-image: linear-gradient(180deg, var(--bg-secondary) 0%, rgb(var(--bg-secondary-rgb) / 0%) 100%);
    z-index: 5;
    opacity: 0;
    transition: opacity 0.2s linear;
}

header.scrolling:after {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    right: 0;
    height: 14px;
    background-image: linear-gradient(180deg, var(--bg-secondary) 0%, rgb(var(--bg-secondary-rgb) / 0%) 100%);
    z-index: 5;
    opacity: 1;
}

.activtrades-logo {
    grid-area: logo;
    position: relative;
    width: 130px;
    margin: 0 auto;
    padding: 14px 0 16px;
}

.logo-img {
    position: relative;
    display: block;
    width: 100%;
}

.logo-img img {
    display: none;
}

.theme-corporate .theme-corporate-logo,
.theme-light .theme-light-logo,
.theme-dark .theme-dark-logo {
    display: block;
    width: 100%;
}

.close-oa {
    position: absolute;
    top: var(--main-padding);
    right: var(--double-padding);
    display: flex;
    justify-content: center;
    align-items: center;
}
.rtl .close-oa {
    left: var(--double-padding);
    right: auto;
}

.progressbar-container {
    grid-area: progressbar;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
}

.progressbar {
    background-color: var(--bg-main);
    width: 100%;
    border-radius: 10px;
    transition: background-color 0.2s linear;
}

.scrolling .progressbar {
    background-color: var(--border-secondary);
}

.progress {
    background-color: var(--accent);
    height: 4px;
    border-radius: 10px;
}

.arrows {
    position: absolute;
    top: var(--main-padding);
    left: 0.75rem;
    display: flex;
    gap: 0.375rem;
}

.rtl .arrows {
    right: 0.75rem;
    left: auto;
}

.back-arrow-btn,
.next-arrow-btn {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 24px;
    opacity: 0.4;
    cursor: default;

    .rtl & {
        transform: rotate(180deg);
    }
}

.next-arrow-btn {
    opacity: 0;
}

.back-arrow-btn .fa-svg,
.next-arrow-btn .fa-svg {
    fill: var(--font-main);
}

.back-arrow-btn.visible,
.next-arrow-btn.visible {
    opacity: 1;
    cursor: pointer;
}

/* for debug purposes only  */

.debugContainer {
    background-color: black;
    position: fixed;
    left: -270px;
    top: 92px;
    z-index: 100;
    max-height: 300px;
    overflow: auto;
    width: 300px;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.debugContainer:hover {
    left: 0;
}

.debugContainer:hover .white-href {
    border-bottom: 1px solid #4d91df;
    text-decoration: none;
}

.white-href {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 18.75rem;
    padding: 0.625rem;
    color: #fff;
    font-weight: bold;
    background: black;
    text-decoration: none;
    border-bottom-right-radius: 16px;
}

@media (pointer: fine) {
    .white-href:hover {
        text-decoration: none;
    }
}

.debug-list {
    padding: 2.5rem 0.625rem 0.625rem;
}

.debug-list a {
    color: #efefef;
    text-decoration: none;
    line-height: 1.4;
}

.debug-list a:hover {
    text-decoration: underline;
}

.debug-arrow {
    display: inline-block;
    border-top: none;
    border-left: none;
    border-bottom: 2px solid #4d91df;
    border-right: 2px solid #4d91df;
    height: 0.625rem;
    width: 0.625rem;
    transform: rotate(-52deg) skewX(-16deg);
}

/* scrollbars */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #383838;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #383838;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #656363;
}

/* end debug */
</style>
