import axios from "axios";

class AddressesService {
    async findAddress(text, countries, limit, language, containter) {
        const container = containter || "";
        const key = import.meta.env.VITE_GBG_KEY;
        const isMiddleware = false;
        const origin = "";
        const url = "https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws";
        const params = `
        &Key=${encodeURIComponent(key)}
        &Text=${encodeURIComponent(text)}
        &IsMiddleware=${encodeURIComponent(isMiddleware)}
        &Container=${encodeURIComponent(container)}
        &Origin=${encodeURIComponent(origin)}
        &Countries=${encodeURIComponent(countries)}
        &Limit=${encodeURIComponent(limit)}
        &Language=${encodeURIComponent(language)}`;

        const result = await axios.post(url, params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });

        return result.data.Items;
    }

    async retrieveAddress(id) {
        const key = import.meta.env.VITE_GBG_KEY;
        const field1Format = "";
        const url = "https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws";
        const params = `
        &Key=${encodeURIComponent(key)}
        &Id=${encodeURIComponent(id)}
        &Field1Format=${encodeURIComponent(field1Format)}`;

        const result = await axios.post(url, params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });

        return result.data.Items[0];
    }
}

export default new AddressesService();
