<template>
    <div class="section-content">
        <div class="center-container">
            <div class="theme-corporate-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 585 512" class="fa-svg fa-thin fa-chart-mixed">
                    <path
                        d="M582.5 5.7c2.3 4.5 2.3 10.2-1.1 13.6L372.9 199.4c-3.4 3.4-9.1 3.4-12.5 0L221.1 60 18.4 218.6c-3.4 2.3-9.1 2.3-12.5-2.3c-3.4-3.4-2.3-9.1 2.3-12.5L216.6 40.8c3.4-2.3 7.9-2.3 11.3 1.1L367.3 181.2 568.9 4.5c4.5-2.3 10.2-2.3 13.6 1.1zM112.4 364.7V473.5c0 20.4-15.9 36.2-36.2 36.2c-19.3 0-36.2-15.9-36.2-36.2V364.7c0-19.3 17-36.2 36.2-36.2c20.4 0 36.2 17 36.2 36.2zM94.3 473.5V364.7c0-9.1-7.9-18.1-18.1-18.1c-9.1 0-18.1 9.1-18.1 18.1V473.5c0 10.2 9.1 18.1 18.1 18.1c10.2 0 18.1-7.9 18.1-18.1zM184.9 256c0-19.3 17-36.2 36.2-36.2c20.4 0 36.2 17 36.2 36.2V473.5c0 20.4-15.9 36.2-36.2 36.2c-19.3 0-36.2-15.9-36.2-36.2V256zm18.1 0V473.5c0 10.2 9.1 18.1 18.1 18.1c10.2 0 18.1-7.9 18.1-18.1V256c0-9.1-7.9-18.1-18.1-18.1c-9.1 0-18.1 9.1-18.1 18.1zm200.5 72.5v145c0 20.4-17 36.2-36.2 36.2c-20.4 0-36.2-15.9-36.2-36.2v-145c0-19.3 15.9-36.2 36.2-36.2c19.3 0 36.2 17 36.2 36.2zm-18.1 145v-145c0-9.1-9.1-18.1-18.1-18.1c-10.2 0-18.1 9.1-18.1 18.1v145c0 10.2 7.9 18.1 18.1 18.1c9.1 0 18.1-7.9 18.1-18.1zM476 256c0-19.3 15.9-36.2 36.2-36.2c19.3 0 36.2 17 36.2 36.2V473.5c0 20.4-17 36.2-36.2 36.2c-20.4 0-36.2-15.9-36.2-36.2V256zm18.1 0V473.5c0 10.2 7.9 18.1 18.1 18.1c9.1 0 18.1-7.9 18.1-18.1V256c0-9.1-9.1-18.1-18.1-18.1c-10.2 0-18.1 9.1-18.1 18.1z" />
                </svg>
            </div>
            <div class="theme-dark-icon">
                <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M52.0156 18.5508L38.8906 29.0508C37.9062 29.8711 36.5938 29.8711 35.6094 29.1328L26.75 22.4883L15.2656 31.6758C14.1172 32.5781 12.4766 32.4141 11.5742 31.2656C10.6719 30.1992 10.8359 28.4766 11.9844 27.5742L25.1094 17.0742C26.0117 16.3359 27.3242 16.3359 28.3086 17.0742L37.168 23.7188L48.7344 14.4492C49.8008 13.5469 51.5234 13.793 52.4258 14.8594C53.3281 16.0078 53.082 17.6484 52.0156 18.5508ZM24.125 32.25C24.125 30.8555 25.2734 29.625 26.75 29.625C28.1445 29.625 29.375 30.8555 29.375 32.25V48C29.375 49.4766 28.1445 50.625 26.75 50.625C25.2734 50.625 24.125 49.4766 24.125 48V32.25ZM13.625 40.125C13.625 38.7305 14.7734 37.5 16.25 37.5C17.6445 37.5 18.875 38.7305 18.875 40.125V48C18.875 49.4766 17.6445 50.625 16.25 50.625C14.7734 50.625 13.625 49.4766 13.625 48V40.125ZM37.25 34.875C38.6445 34.875 39.875 36.1055 39.875 37.5V48C39.875 49.4766 38.6445 50.625 37.25 50.625C35.7734 50.625 34.625 49.4766 34.625 48V37.5C34.625 36.1055 35.7734 34.875 37.25 34.875ZM45.125 32.25C45.125 30.8555 46.2734 29.625 47.75 29.625C49.1445 29.625 50.375 30.8555 50.375 32.25V48C50.375 49.4766 49.1445 50.625 47.75 50.625C46.2734 50.625 45.125 49.4766 45.125 48V32.25Z" />
                </svg>
            </div>
            <h1>{{ $t("oa_trading_experience") }}</h1>
            <p>{{ $t("trading_experience_begin_subtitle") }}</p>
        </div>
        <div>
            <Questions v-bind:questions="questions" v-bind:errors="errors"></Questions>
        </div>
    </div>
</template>

<script>
import Questions from "@/views/onlineapplication/questions/Questions.vue";

export default {
    name: "TradingExperienceBegin",
    props: ["questions", "errors"],
    components: {
        Questions,
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_profiles.scss";

.section-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.section-content > div {
    width: 100%;
}

.section-content :deep(.notification) {
    margin: 0;
}

.center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

h1 {
    margin: 0;
}

p {
    margin: 10px 0 28px;
}

.fa-svg {
    font-size: 7rem;
    margin-bottom: 1.625rem;
    fill: var(--font-tertiary);
}

.theme-dark .theme-corporate-icon,
.theme-light .theme-corporate-icon {
    display: none;
}
.theme-corporate .theme-dark-icon {
    display: none;
}
.theme-dark-icon {
    fill: var(--accent);
    width: 64px;
    height: 64px;
    margin: 0 auto 10px;
}
</style>
