<template>
    <div class="tax-residency">
        <div v-for="(question, index) in questions" v-bind:key="question.Id" class="group">
            <!-- <transition-group appear name="add" > -->
            <div v-if="question.IsVisible">
                <hr />
                <div class="flex-row">
                    <AppCountryDropdown
                        v-if="question.FieldType == 'Country'"
                        v-bind:question="question"
                        v-bind:errors="errors"
                        v-bind:disableOptions="disabledOptions"
                        v-bind:groupIndex="questions.filter((x) => x.IsVisible && x.FieldType === 'Country').findIndex((x) => x.Id === question.Id) + 1">
                    </AppCountryDropdown>

                    <AppCountryTins
                        v-if="question.FieldType == 'CountryTins'"
                        v-bind:question="question"
                        v-bind:errors="errors"
                        v-bind:groupIndex="questions.filter((x) => x.IsVisible && x.FieldType === 'CountryTins').findIndex((x) => x.Id === question.Id) + 1">
                    </AppCountryTins>

                    <AppUserValidation
                        v-if="question.FieldType == 'UserValidation' && question.IsVisible"
                        v-bind:question="question"
                        v-bind:groupIndex="questions.filter((x) => x.FieldType === 'UserValidation').findIndex((x) => x.Id === question.Id) + 1">
                        >
                    </AppUserValidation>

                    <div v-if="showRemoveButton(index)" @click="remove(index)" class="remove-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fa-svg fa-solid fa-trash-can">
                            <path
                                d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                        </svg>
                    </div>
                    <div v-else class="fake-column"></div>
                </div>
            </div>
            <!-- </transition-group> -->
        </div>

        <div class="align-start">
            <a href="javascript:void(0)" @click="add" v-if="showAddButton()" class="add-btn">
                <span class="add-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-solid fa-circle-plus">
                        <path
                            d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256 114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                    </svg>
                </span>
                {{ $t("lbl_another_tax_residency") }}
            </a>
        </div>
    </div>
</template>

<script>
import i18n from "@/i18n.js";

import Questions from "../Questions.vue";
import AppCountryDropdown from "@/components/oa/AppCountryDropdown.vue";
import AppCountryTins from "@/components/oa/AppCountryTins.vue";
import AppUserValidation from "@/components/oa/AppUserValidation.vue";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { useUiStore } from "@/store/interface.js";
import { mapStores } from "pinia";

export default {
    name: "CountryTins",
    components: {
        Questions,
        AppCountryDropdown,
        AppCountryTins,
        AppUserValidation,
    },
    props: ["questions", "errors", "setFieldError", "setFieldValue"],
    created() {
        this.setDefaultVisability();
    },
    updated() {
        this.setDefaultVisability();
    },
    methods: {
        setDefaultVisability() {
            this.questions.forEach((question, index) => {
                question.IsVisible =
                    question.IsVisible ||
                    (question.FieldType == "Country" && question.SelectedAnswers[0] != null && question.SelectedAnswers[0] > 0) ||
                    (question.FieldType == "CountryTins" && question.Answers[0] != null && question.Answers[0].Value) ||
                    (question.FieldType == "UserValidation" && question.SelectedAnswers[0] != null && question.SelectedAnswers[0]) ||
                    index == 0 ||
                    index == 1 ||
                    index == 2
                        ? true
                        : false;
            });

            this.applicationStore.applyValidations();
        },
        add() {
            const firstNotVisibleTin = this.questions.find((x) => !x.IsVisible && x.FieldType === "CountryTins");
            firstNotVisibleTin.IsVisible = true;
            firstNotVisibleTin.Answers[0].Value = "";

            this.setFieldValue(firstNotVisibleTin.FieldName, "");

            this.enableQuestionByFieldName("Country");
            this.enableQuestionByFieldName("UserValidation");

            this.applicationStore.applyValidations();
        },
        enableQuestionByFieldName(fieldName) {
            const firstNotVisibleQuestion = this.questions.find((x) => !x.IsVisible && x.FieldType === fieldName);
            firstNotVisibleQuestion.IsVisible = true;
            firstNotVisibleQuestion.SelectedAnswers[0] = 0;

            this.setFieldValue(firstNotVisibleQuestion.FieldName, "");
        },
        remove(index) {
            if (this.questions[index] != null) {
                const itemsLeft = this.questions.filter((x) => x.IsVisible && x.FieldType === "CountryTins").length - 1 - index / 3;
                if (!itemsLeft) {
                    this.questions[index].IsVisible = false;
                    this.questions[index].SelectedAnswers[0] = 0;

                    if (this.questions[index + 1] != null) {
                        this.questions[index + 1].IsVisible = false;
                        this.questions[index + 1].Answers[0].Value = "";
                    }

                    if (this.questions[index + 2] != null) {
                        this.questions[index + 2].IsVisible = false;
                        this.questions[index + 2].SelectedAnswers[0] = 0;
                        this.uiStore.removeError(this.questions[index + 2].FieldName);
                    }

                    this.applicationStore.applyValidations();
                    return;
                }

                for (let i = index; i < this.questions.length - 2; i += 3) {
                    const currentCountry = this.questions[i];
                    const currentTin = this.questions[i + 1];
                    const currentValidation = this.questions[i + 2];

                    const nextCountry = this.questions[i + 3];
                    const nextTin = this.questions[i + 4];
                    const nextValidation = this.questions[i + 5];

                    if (!nextCountry) {
                        currentCountry.SelectedAnswers[0] = 0;
                        currentCountry.IsVisible = false;

                        currentTin.Answers[0].Value = "";
                        currentTin.IsVisible = false;
                        continue;
                    }

                    if (!nextCountry.IsVisible && !currentCountry.SelectedAnswers[0]) {
                        currentCountry.IsVisible = false;
                        continue;
                    }

                    currentCountry.SelectedAnswers[0] = nextCountry.SelectedAnswers[0];
                    currentCountry.IsVisible = true;

                    nextCountry.SelectedAnswers[0] = 0;
                    nextCountry.IsVisible = false;

                    currentTin.Answers[0].Value = nextTin.Answers[0].Value;
                    currentTin.IsVisible = true;

                    nextTin.Answers[0].Value = "";
                    nextTin.IsVisible = false;

                    const answerIndex = nextValidation.Answers.findIndex((x) => x.Id === nextValidation.SelectedAnswers[0]);
                    if (answerIndex !== -1) {
                        currentValidation.SelectedAnswers[0] = currentValidation.Answers[answerIndex].Id;
                    }
                    currentValidation.IsVisible = true;

                    nextValidation.SelectedAnswers[0] = 0;
                    nextValidation.IsVisible = false;
                    this.uiStore.removeError(nextValidation.FieldName);
                }
            }

            this.applicationStore.applyValidations();
        },
        showRemoveButton(index) {
            return index > 2 && index % 3 === 0;
        },
        showAddButton() {
            return this.questions.find((x) => !x.IsVisible && x.FieldType === "CountryTins") != null;
        },
    },
    computed: {
        ...mapStores(useUiStore, useApplicationStore),
        disabledOptions() {
            var answers = [];
            this.questions.forEach((question) => {
                if (question.FieldType === "Country" && question.SelectedAnswers[0] != null && question.SelectedAnswers[0] > 0) {
                    answers.push(question.SelectedAnswers[0]);
                }
            });
            return answers;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_buttons.scss";
@import "src/assets/styles/_animations.scss";

.text-residency {
    text-align: left;
}
.rtl .text-residency {
    text-align: right;
}

hr {
    display: none;
    margin: 1.125rem 0;
    border: none;
    border-top: 1px solid var(--border-main);
}

.group:nth-child(3n + 1) hr {
    display: block;
}

.group:nth-child(1) hr {
    display: none;
}

.flex-row {
    display: flex;
    gap: var(--form-control-padding);
}
.flex-row > div:first-child {
    flex-grow: 1;
}

.fake-column {
    width: 60px;
    min-width: 60px;
}
.group:nth-child(1) .fake-column,
.group:nth-child(2) .fake-column,
.group:nth-child(3) .fake-column {
    display: none;
}

.flex-row .tin-notification {
    margin: 0;
}
</style>
