<template>
    <div class="form-field" :class="{ active: isSelected }">
        <label :for="`answer-${question.Id}`" class="checkbox-label">
            <input :id="`answer-${question.Id}`" :name="`answer-${question.Id}`" v-model="isSelected" type="checkbox" />
            <div class="fake-checkbox"></div>
            <span>{{ $t(question.TextKey) }}</span>
        </label>
    </div>
</template>

<script>
import eventBus from "@/eventBus";
export default {
    name: "AppCheckboxOptional",
    props: ["question", "receiverIndex"],
    emits: ["on-checkbox-selected"],
    data() {
        return {
            isSelected: false,
        };
    },
    methods: {
        setAnswer(value) {
            this.isSelected = !!value;
            const index = this.isSelected ? 1 : 0;
            const answer = this.question.Answers[index];
            this.question.SelectedAnswers = [answer.Id];
        },
        updateSelection() {
            if (this.question.SelectedAnswers[0] !== 0) {
                const index = this.question.Answers.findIndex((x) => x.Id === this.question.SelectedAnswers[0]);
                const answer = this.question.Answers[index];
                this.isSelected = answer.TextKey.toLowerCase() !== "ls_no" ? true : false;
                return;
            }

            this.setAnswer(false);
        },
    },
    watch: {
        isSelected(newValue) {
            this.setAnswer(newValue);
            this.$emit("on-checkbox-selected", newValue);
        },
    },
    mounted() {
        this.updateSelection();
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_checkbox.scss";
</style>
