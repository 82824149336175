<template>
    <!-- <Flicking :options="{ horizontal: true, align: 'prev' }"> -->
    <div :key="index" v-for="(answer, index) of question.Answers">
        <a href="javascript:void(0);" @click="uploadDocumentType(answer.Value)" @dragstart.prevent>
            <div class="verification-bg">
                <span v-if="answer.DisplayType === 'CNH'">
                    <span class="document-img driving-license"></span>
                </span>
                <span v-if="answer.DisplayType === 'RG' || answer.DisplayType === 'RGNEW'">
                    <span class="document-img id-card"></span>
                </span>
                <span v-if="answer.DisplayType === 'Passport'">
                    <span class="document-img passport"></span>
                </span>
            </div>
            <div class="name">{{ $t(answer.TextKey) }}</div>
            <div v-if="answer.HintKey" class="recommended">{{ $t("lbl_recommended") }}</div>
        </a>
    </div>
    <!-- </Flicking> -->
</template>
<script>
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import eventBus from "@/eventBus";

export default {
    name: "IdVerificationModule",
    props: ["question", "errors"],
    data() {
        return {
            file: "",
            fileUploaded: false,
            instructionsVisible: false,
            typeAnswers: [],
        };
    },
    mounted() {
        var self = this;
        if (this.typeAnswers.length < this.question.Answers.length) {
            this.typeAnswers = this.question.Answers;
        }

        eventBus.$on("changeIdVerification", (answer) => {
            self.uploadDocumentType(self.question.Answers[0].Value);
            eventBus.$off("changeIdVerification");
        });
    },
    methods: {
        uploadDocumentType(type) {
            this.documentType = type;
            const answer = this.typeAnswers.find((x) => x.Value.toLowerCase() == type.toLowerCase());
            this.question.SelectedAnswers[0] = answer.Id;

            this.applicationStore.submit();
        },
    },
    computed: {
        ...mapStores(useApplicationStore),
        imageUrl() {
            return URL.createObjectURL(this.file[0]);
        },
    },
};
</script>

<style lang="scss" scoped>
a {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.875rem;
    max-width: 100%;
    border: 1px solid var(--border-secondary);
    border-radius: var(--border-radius-main);
    padding: 0.875rem;
    margin-bottom: 0.875rem;
    text-decoration: none;
}

@media (pointer: fine) {
    a:hover {
        text-decoration: none;
        background: var(--hover-main);
        border-color: var(--border-main);
    }
    a:hover .name {
        color: var(--font-secondary);
    }
}

a:active {
    text-decoration: none;
    border-color: var(--accent);
    background-color: rgb(var(--accent-rgb), 5%);
}

a:active .name {
    color: var(--accent);
}

a:active .verification-bg .fa-svg {
    fill: var(--accent);
}

@media (hover: none), (hover: on-demand) {
    a:hover {
        /* suppress hover effect on devices that don't support hover fully */
        border-color: var(--border-main);
    }
}

.verification-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 28px;
    flex-grow: 0;
}

.verification-bg .fa-svg {
    font-size: 1.375rem;
    fill: var(--accent);
}

.document-img {
    display: inline-block;
    width: 32px;
    height: 28px;
    background-size: contain;
}

.id-card {
    background-image: url("@/assets/images/verification/id-card-duotone.svg");
}
.passport {
    background-image: url("@/assets/images/verification/passport-duotone.svg");
}
.driving-license {
    background-image: url("@/assets/images/verification/driving-license-duotone.svg");
}

.theme-dark,
.theme-light {
    .id-card {
        background-image: url("@/assets/images/verification/id-card-duotone-blue.svg");
    }
    .passport {
        background-image: url("@/assets/images/verification/passport-duotone-blue.svg");
    }
    .driving-license {
        background-image: url("@/assets/images/verification/driving-license-duotone-blue.svg");
    }
}

.recommended {
    display: inline-block;
    font-size: 0.75rem;
    font-style: normal;
    color: var(--accent);
    padding: 0.25rem 0.313rem;
    border: 1px solid var(--accent);
    border-radius: 2px;
    background: var(--accent-selected-background);
}

.name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--font-main);
    font-size: var(--font-desktop);
    letter-spacing: -1px;
}
</style>
