export default class PostStepModel {
    constructor(data) {
        this.data = data;
        this.model = {
            StepId: String,
            Questions: Array,
        };
    }

    createFromStep() {
        const submittedQuestions = JSON.parse(JSON.stringify(this.data.Questions));
        var submitModelQuestions = submittedQuestions.map((x) => {
            if (x.Answers.length > 1) {
                x.Answers = x.Answers.filter((y) => {
                    return x.SelectedAnswers.find((id) => id == y.Id);
                });
            }

            return {
                Id: x.Id,
                Answers: x.Answers.map((a) => {
                    return {Id: a.Id, QuestionId: a.QuestionId, Value: a.Value, IsExternalAnswer: a.IsExternalAnswer}
                }),
                SelectedAnswers: x.SelectedAnswers,
                IsVisible: x.IsVisible,
                FieldType: x.FieldType,
                DisplayType: x.DisplayType
            }
        });

        this.model.StepId = this.data.Id;
        this.model.Questions = submitModelQuestions;
        this.model.RecaptchaToken = this.data.RecaptchaToken;
        return this.model;
    }
}
