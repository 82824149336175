<template>
    <div class="country-code input form-field" v-click-outside="onClickOutside" :class="{ focus: dropdownOpened }">
        <Field as="hidden" :name="question.FieldName" class="form-control" :class="{ 'is-invalid': errors[question.FieldName] }" v-model="question.Answers[0].Value"></Field>

        <div @click.stop="toggle()">
            <div class="country-flag">
                <UIFlag v-bind:src="selectedCountry.Code" width="auto" height="1"></UIFlag>
            </div>
            <span class="fake-input">{{ selectedCountry.PhoneCode }}</span>
            <label class="fake-small-label">{{ $t("lbl_country_code") }}</label>

            <span class="dropdown-arrow" @click.stop="toggle()">
                <i v-if="dropdownArrow == 'down'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-thin fa-chevron-down">
                        <path
                            d="M250.3 397.7c3.1 3.1 8.2 3.1 11.3 0l216-216c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L256 380.7 45.7 170.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l216 216z" />
                    </svg>
                </i>
                <i v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-thin fa-chevron-up">
                        <path
                            d="M250.3 114.3c3.1-3.1 8.2-3.1 11.3 0l216 216c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L256 131.3 45.7 341.7c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3l216-216z" />
                    </svg>
                </i>
            </span>
        </div>
        <div v-if="dropdownOpened" class="country-code-dropdown">
            <ul>
                <li>
                    <input placeholder="Search" v-model="filter" @keypress="onlyNumbers" width="0.938" height="0.938" />
                </li>
                <li v-for="country in displayedCountries" v-bind:key="country.Id">
                    <a href="javascript:void(0)" @click="onCountryChanged(country)">
                        <span class="country-flag">
                            <UIFlag v-bind:src="country.Code.toLowerCase()" width="auto" height="0.938"></UIFlag>
                        </span>
                        <span>{{ country.PhoneCode }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import UIFlag from "./UIFlag.vue";
import vClickOutside from "click-outside-vue3";
import { useCountriesStore } from "@/store/countries.js";
import { useLanguagesStore } from "@/store/languages.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import eventBus from "@/eventBus";

export default {
    name: "UIPhoneCode",
    props: ["question", "errors"],
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        UIFlag,
    },
    data() {
        return {
            filter: "",
            selectedCountry: {},
            dropdownOpened: false,
            dropdownArrow: "down",
        };
    },
    beforeMount() {
        if (this.question.Answers == null || this.question.Answers.length == 0) {
            this.question.Answers.push(new EmptyAnswerModel());
        }

        this.applicationStore.applyValidations();

        const preSelectCountry = this.question.SelectedAnswers[0] ? this.countriesStore.countries.find((x) => x.Id == this.question.SelectedAnswers[0]) : null;
        if (preSelectCountry) {
            this.changeSelectedCountry(preSelectCountry);
        }

        this.countriesStore.sortByName(this.languagesStore.preferedLanguage);

        eventBus.$on("on-change-country", (id) => {
            const country = this.countriesStore.countries.find((x) => x.Id == id);
            if (country) {
                this.changeSelectedCountry(country);
            }
        });
    },
    methods: {
        toggle() {
            this.dropdownOpened = !this.dropdownOpened;
            this.dropdownArrow = this.dropdownOpened ? "up" : "down";
        },
        close() {
            this.dropdownOpened = false;
            this.dropdownArrow = "down";
        },
        onClickOutside() {
            this.dropdownOpened = false;
            this.dropdownArrow = "down";
        },
        onPhoneNumberChanged() {
            this.question.Answers[0].Value = this.phoneNumber;
        },
        onCountryChanged(country) {
            this.changeSelectedCountry(country);
            this.filter = "";
            this.dropdownOpened = false;
            this.dropdownArrow = "down";
        },
        changeSelectedCountry(country) {
            this.selectedCountry.PhoneCode = country.PhoneCode;
            this.selectedCountry.Code = country.Code.toLowerCase();
            this.question.Answers[0].Value = country.PhoneCode;
        },
    },
    computed: {
        ...mapStores(useCountriesStore, useLanguagesStore, useApplicationStore),
        displayedCountries() {
            if (!this.filter) {
                return this.countriesStore.countries;
            }

            return this.countriesStore.countries.filter(
                (x) =>
                    x.Name.toLowerCase().includes(this.filter.toLowerCase()) ||
                    x.Code.toLowerCase().includes(this.filter.toLowerCase()) ||
                    x.Iso3Code.toLowerCase().includes(this.filter.toLowerCase()) ||
                    x.PhoneCode.toLowerCase().includes(this.filter.toLowerCase())
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

hidden {
    display: none;
}

.country-code {
    flex-grow: 0;
    flex-shrink: 0;
    white-space: nowrap;
    width: 9.063rem;
    font-size: var(--form-control-font-size);
    line-height: 1.4;
    padding: 17px var(--form-control-padding) 3px;
    margin-bottom: 0.75rem;
    background: var(--form-control-bgr);
    border: 1px solid var(--border-main);
    border-right: none;
    border-top-left-radius: var(--border-radius-main);
    border-bottom-left-radius: var(--border-radius-main);
    text-align: left;
    cursor: pointer;
}

.rtl .country-code {
    border-right: 1px solid var(--border-main);
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--border-radius-main);
    border-bottom-right-radius: var(--border-radius-main);
    text-align: right;
}

.country-code.focus {
    @include focus-mixin;
}

.country-flag {
    position: relative;
    top: 2px;
    width: 1rem;
    height: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #f2f2f2;
    border: 1px solid var(--border-main);
    margin-right: 0.375rem;
    overflow: hidden;
}

.rtl .country-flag {
    margin-left: 0.375rem;
    margin-right: 0;
}

.country-code-dropdown {
    position: absolute;
    top: 3.188rem;
    left: 0;
    width: 9.063rem;
    border: 1px solid var(--border-main);
    background: var(--bg-main);
    box-shadow: var(--main-box-shadow);
    height: 12.5rem;
    overflow: auto;
    z-index: 20;
}

.rtl .country-code-dropdown {
    right: 0;
    left: auto;
}

.country-code .dropdown-arrow {
    background-color: transparent;
    box-shadow: none;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

ul a {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    text-decoration: none;
    color: var(--font-main);
    text-align: left;
    padding: 0.313rem var(--form-control-padding);
}

.rtl ul a {
    text-align: right;
}

@media (pointer: fine) {
    ul a:hover {
        background: var(--hover-secondary);
    }
}

.fake-small-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 7px var(--form-control-padding) 0;
    font-size: 0.75rem;
    line-height: 1.2;
    color: var(--font-tertiary);
    transition: top 0.05s ease-in;
    pointer-events: none;
}

.rtl .fake-small-label {
    right: 0.125rem;
    left: auto;
}

.fake-input {
    color: var(--font-main);
    line-height: 1.8;
}

.country-code-dropdown input {
    height: auto;
    padding: 0.438rem 0.313rem;
    margin-bottom: 0.625rem;
    width: 90%;
    margin: 0.313rem;
    border-radius: 4px;
    background: var(--bg-main);
    color: var(--font-main);
    border: 1px solid var(--border-main);
    font-size: 0.813rem;
}

.country-code-dropdown input[type="text"] {
    height: 1.875rem;
}

ul li .country-flag img {
    position: relative;
    left: 1px;
}
</style>
