export function injectGtm(id) {
    const script = document.createElement("script");
    const dataLayerName = "dataLayer";

    if (!window[dataLayerName]) {
        window[dataLayerName] = [];
    }

    dataLayer = window[dataLayerName];

    dataLayer.push({
        event: "gtm.js",
        "gtm.start": new Date().getTime(),
    });

    if (!id) {
        return script;
    }

    script.async = true;
    script.defer = true;

    const source = "https://www.googletagmanager.com/gtm.js";

    script.src = `${source}?id=${id}`;

    document.body.appendChild(script);
    return script;
}
