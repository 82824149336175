<template>
  <div>
    {{ $t(question.TextKey) }}

    <input type="hidden" v-model="question.Value" />

    <div style="border: 1px solid blue" @click="DisplayProvinces">
      <UIFlag
        v-bind:src="question.ValueDescription"
        width="0.938"
        height="0.938"
        dir="brazil-states"
      >
      </UIFlag>
      {{ question.ValueDescription }}
    </div>
    <div v-if="ProvinceDisplayed">
      <li v-for="province in provinces" v-bind:key="province.Id">
        <UIFlag
          v-bind:src="province.Name"
          width="0.938"
          height="0.938"
          dir="brazil-states"
        >
        </UIFlag>
        <a href="javascript:void(0)" @click="SelectProvince(province)">
          {{ province.Name }}
        </a>
      </li>
    </div>

    <br />
    <ErrorMessage v-bind:name="question.FieldName" />
  </div>
</template>

<script>
import UIFlag from "@/components/ui/UIFlag.vue";

export default {
  name: "AppBrProvince",
  props: ["question", "errors"],
  components: {
    UIFlag,
  },
  data() {
    return {
      provinces: [
        {
          Id: 1,
          Name: "Acre",
        },
        {
          Id: 2,
          Name: "Alagoas",
        },
      ],
      provinceDisplayed: false,
    };
  },
  beforeCreate() {
    this.question.ValueDescription = "Acre";
    this.question.Value = 1;
  },
  methods: {
    SelectProvince(province) {
      this.question.ValueDescription = province.Name;
      this.question.Value = province.Id;
    },
    DisplayProvinces() {
      this.provinceDisplayed = !this.provinceDisplayed;
    },
  },
};
</script>
