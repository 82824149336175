<template>
    <form class="section-content">
        <div class="flex-switch">
            <div class="check-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-solid fa-circle-check">
                    <path
                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>
            </div>
            <h1>{{ t("oa_demo_success") }}</h1>
        </div>
        <div>
            <button type="button" @click="emit('on-exit-clicked')">
                {{ t("btn_continue") }}
            </button>
        </div>
    </form>
</template>
<script setup>
import i18n from "@/i18n.js";

const emit = defineEmits(["on-exit-clicked"]);
const { t } = i18n.global;
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_profiles.scss";

.section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.section-content > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.section-content :deep(.notification) {
    margin: 0;
}

h1 {
    margin: 0 0 calc(var(--main-padding) * 4);
}

p {
    margin: 10px 0 28px;
}

.theme-dark .theme-corporate-icon,
.theme-light .theme-corporate-icon {
    display: none;
}
.theme-corporate .theme-dark-icon {
    display: none;
}
.check-icon {
    fill: var(--accent);
    width: 42px;
    height: 42px;
    margin: 0 auto 25px;
}
</style>
