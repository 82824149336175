import http from "@/backend/vue-axios/axios";

class OnfidoService {
    startWorkflow(data) {
        try {
            return http.post("v1/onfido/onlineApplication", data);
        } catch {
            console.error("FAILURE!!");
        }
    }

    completeVerification(workflowRunId) {
        try {
            return http.post("v1/onfido/completeverification", { workflowRunId: workflowRunId });
        } catch (er) {
            console.error(er);
        }
    }

    async hasCompletedVerification(reference, companyBranch, verificationType) {
        try {
            const result = await http.get(`v1/onfido/iscompletedbyuser?OaReference=${reference}&CompanyBranch=${companyBranch}&Type=${verificationType}`);
            return result.data.Data;
        } catch (er) {
            console.error(er);
        }
    }

    loadIdentificationOptions(type, companyBranch, memberId) {
        try {
            return http.get(`v1/onfido/loadIdentificationOptions?Type=${type}&CompanyBranch=${companyBranch}&MemberId=${memberId}`);
        } catch {
            console.error("FAILURE!!");
        }
    }
}

export default new OnfidoService();
