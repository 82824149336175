<template>
    <div class="form-field">
        <Field
            type="text"
            class="form-control"
            :name="question.FieldName"
            :class="{
                'is-invalid': errors[question.FieldName],
            }"
            v-bind:placeholder="$t(tinTranslationKey)"
            :key="question.FieldName"
            v-model="tinValue"
            v-on:focus="this.showExample = true"
            v-on:blur="this.showExample = false"></Field>

        <label class="small-label">{{ $t(tinTranslationKey) }}</label>

        <label class="example" v-if="showTinExample">{{ tinValidations?.TinExample }}</label>

        <ErrorMessage v-bind:name="question.FieldName" />
    </div>
</template>

<script lang="js">
import AppCountryDropdown from "@/components/oa/AppCountryDropdown.vue";
import eventBus from "@/eventBus";
import i18n from "@/i18n.js";
import { mapStores } from 'pinia'
import {useCountriesStore} from '@/store/countries.js'
import { useApplicationStore } from "@/store/onlineapplication.js";

export default {
    name: "AppCountryTins",
    props: ["question", "errors", "groupIndex", "useCountryOfResidence"],
    components: {
        AppCountryDropdown,
    },
    data() {
        return {
            tinValidations: null,
            showExample: false,
            tinValue: null,
        };
    },
    computed: {
        ...mapStores(useCountriesStore,useApplicationStore),
        tinTranslationKey() {
            if (this.tinValidations) {
                return this.tinValidations?.TinTranslationKey ?? "tin_number";
            }

            return "tin_number";
        },
        showTinExample() {
            return this.question.Answers[0] && !this.question.Answers[0].Value && this.tinValidations?.TinExample && this.showExample;
        },
        receiverIndex() {
            return this.groupIndex || 1;
        },
    },
    methods: {
        addCountryBasedValidation(country) {
            if (!country) {
                this.question.Validations = [
                    {
                        Id: 0,
                        QuestionId: this.question.Id,
                        ValidationType: "Required",
                        ErrorMessageKey: i18n.global.t("err_required"),
                        Enabled: true,
                    },
                ];
                this.applicationStore.applyValidations();
                return;
            }

            const tinValidations = this.countriesStore.countries.find((x) => x.Id === country.Id)?.CountryTinValidationRules[0];
            this.tinValidations = tinValidations;
            this.validateTin(this.tinValue);
        },
        validateTin(newValue){
            if (!!(this.tinValidations && newValue)) {
                const regex = new RegExp(this.tinValidations.ValidationRegex);
                const isValid = regex.test(newValue);
                eventBus.$emit(`on-tin-validation-state-changed-${this.receiverIndex}`, isValid);
                return;
            }

            eventBus.$emit(`on-tin-validation-state-changed-${this.receiverIndex}`, true);
        },
    },
    async created() {
        if (this.groupIndex) {
            eventBus.$on(`on-country-tim-change-${this.groupIndex}`, (country) => {
                this.addCountryBasedValidation(country);
            });
        }
    },
    beforeMount() {
        if (this.useCountryOfResidence) {
            this.addCountryBasedValidation(this.applicationStore.countryOfResidence);
        }
    },
    mounted() {
        //This assignment should be here because of the user validation component
        if (this.question.Answers[0].Value) {
            this.tinValue = this.question.Answers[0].Value;
        }

        this.applicationStore.applyValidations();
    },
    updated(){
        if (this.question.Answers[0].Value) {
            this.tinValue = this.question.Answers[0].Value;
        }
    },
    watch: {
        tinValue(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            this.validateTin(newValue);
            this.question.Answers[0].Value = newValue;
        },
    },
};
</script>
<style lang="scss" scoped>
.example {
    position: absolute;
    top: 1px;
    left: 1px;
    line-height: 1.2;
    padding: 1.5rem var(--form-control-padding) 0.625rem;
    font-size: var(--form-control-font-size);
    letter-spacing: -0.4px;
    color: var(--font-tertiary);
    pointer-events: none;
}

.rtl .example {
    left: auto;
    right: 0;
}
</style>
