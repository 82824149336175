<template>
    <div class="declarations-wrapper">
        <div v-html="$t(question.TextKey)"></div>
    </div>
</template>

<script>
export default {
    name: "UIText",
    props: ["question"],
};
</script>

<style lang="scss">
.declarations-wrapper {
    max-width: 100%;
    margin: 0 auto 1.125rem;
    text-align: left;
    color: var(--font-tertiary);
    padding: 0 0.75rem;
}
.rtl .declarations-wrapper {
    text-align: right;
}

.declarations-wrapper a {
    color: var(--accent);
}
.declarations-wrapper ul,
.declarations-wrapper ol {
    list-style-position: inside;
    padding: 0;
}
.declarations-wrapper li {
    margin-bottom: 0.5rem;
}
.declarations-wrapper p {
    margin-top: 0;
}
</style>
