<template>
  <div>
    <StarterProfile></StarterProfile>
  </div>
</template>

<script>

import StarterProfile from "@/components/oa/trading_profiles/StarterProfile.vue"

export default {
  name: "AppFailedSuitability",
  components:{
      StarterProfile,
  }
};
</script>