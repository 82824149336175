import http from "@/backend/vue-axios/axios";
import endpoints from "../common/endpoints";

class LanguagesService {
    async getAll(branch) {

        if (branch) {
            return http.get(`${endpoints.GetDropDownLanguages}?branch=${branch}`);
        }

        return http.get(endpoints.GetDropDownLanguages);
    }

    async setLanguage(language) {
        return http.post(endpoints.SetLanguageUrl + "?lang=" + language);
    }
}

export default new LanguagesService();
