<template>
    <div>
        <div class="identification-container">
            <p
                class="prepared"
                v-html="pickOneOrMore ? $t('oa_onfido_prepare_two_photos') : $t('oa_onfido_prepare_following_photos')"
            ></p>
            <div
                v-if="identificationOptions != null && identificationOptions.length > 0"
                class="identification-wrapper"
                :class="{ 'full-width': pickOneOrMore }"
            >
                <div
                    v-if="identificationOptions.some((x) => x.type == 'main')"
                    class="identification-cards-container"
                >
                    <IdentificationOptions
                        v-for="(identificationOption, index) in identificationOptions.filter((x) => x.type == 'main')"
                        :key="index"
                        :icon="identificationOption.icon"
                        :translationKey="identificationOption.translationKey"
                        :required="identificationOption.required"
                        :selected="identificationOption.selected"
                        :readonly="identificationOption.readonly"
                        :disabled="identificationOption.disabled"
                        :isSelectAvailable="identificationOption.isSelectAvailable"
                        :type="identificationOption.type"
                        :index="index"
                        :class="{ error: showRequiredError && !identificationOption.selected }"
                        @updateOption="updateOption"
                    >{{ workflow.IdentificationOptions }}</IdentificationOptions>
                </div>

                <div v-if="identificationOptions.some((x) => x.type == 'secondary')">
                    <div class="and">{{ $t("and_txt") }}</div>
                    <div class="identification-cards-container secondary">
                        <IdentificationOptions
                            v-for="(identificationOption, index) in identificationOptions.filter((x) => x.type == 'secondary')"
                            :key="index"
                            :icon="identificationOption.icon"
                            :translationKey="identificationOption.translationKey"
                            :required="identificationOption.required"
                            :selected="identificationOption.selected"
                            :readonly="identificationOption.readonly"
                            :disabled="identificationOption.disabled"
                            :type="identificationOption.type"
                            :index="index"
                            @updateOption="updateOption"
                        >{{ workflow.IdentificationOptions }}</IdentificationOptions>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="pick-option"
            :class="{ error: showRequiredError }"
            v-if="pickOneOrMore"
        >{{ $t("oa_onfido_pick_option") }}</div>
        <div v-if="showServerError" role="alert">{{ $t("err_verification") }}</div>
        <button
            class="full-width"
            @click.prevent="debouncedRequest"
            :disabled="isStartBtnClicked"
        >{{ $t("im_ready") }}</button>
        <button
            v-if="isSkippable"
            type="button"
            @click.prevent="confirmAndSkip"
            class="secondary-btn margin-top-single"
        >{{ $t("btn_skip_step") }}</button>

        <div :class="[{ 'onfido-overlay': showCloseBtn && !showServerError }]"></div>
        <div :class="[{ 'onfido-popup': showCloseBtn && !showServerError }]">
            <div id="onfido-mount-point" v-show="showPopup"></div>
            <div class="close-onfido-popup" v-if="showCloseBtn && showPopup" @click="closePopup">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    class="fa-svg fa-solid fa-xmark-large"
                >
                    <path
                        d="M393.4 41.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3L269.3 256 438.6 425.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 301.3 54.6 470.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 9.4 86.6C-3.1 74.1-3.1 53.9 9.4 41.4s32.8-12.5 45.3 0L224 210.7 393.4 41.4z"
                    />
                </svg>
            </div>
        </div>

        <UIDialog
            :toggleDialog="toggleSkipDialog"
            :titleText="$t('oa_lbl_skip_step')"
            :bodyText="$t('oa_lbl_financial_regulations')"
            :okButtonText="$t('btn_continue')"
            :okButtonFunction="closeConfirmationDialogAndContunue"
            :cancelButtonFunction="applicationStore.skipStep"
            :cancelButtonText="$t('btn_skip_step')"
        ></UIDialog>

        <UIDialog
            :toggleDialog="toggleAlreadyCompletedDialog"
            :titleText="$t('oa_documents_uploaded')"
            :bodyText="$t('oa_onfido_has_completed_verification')"
            :okButtonText="$t('Continue Registration')"
            :okButtonFunction="this.applicationStore.submit"
            :cancelButtonFunction="this.updateDocuments"
            :cancelButtonText="$t('Update documents')"
        ></UIDialog>
    </div>
</template>

<script>
import injectOnfidoScripts from "@/common/injectOnfidoScripts.js";
import onfidoService from "@/services/onfido.service.js";
import { useLanguagesStore } from "@/store/languages.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import { useDebounceFn } from "@vueuse/core";
import { useUiStore } from "@/store/interface.js";

const additionalIdentificationOptions = "PersonalId, SecondaryId, POA, Selfie, AdditionalDocument";

export default {
    name: "OnfidoVerification",
    props: ["questions", "errors"],
    emits: ["updateOption"],
    data() {
        return {
            isStartBtnClicked: false,
            onfido: null,
            onfidoWorkflowType: 0,
            workflow: null,
            showRequiredError: false,
            showServerError: false,
            toggleSkipDialog: false,
            identificationOptions: [],
            pickOneOrMore: false,
            toggleAlreadyCompletedDialog: false,
            showPopup: false,
        };
    },
    watch: {
        onfidoWorkflowType() {
            this.workflow = null;
        },
    },
    async mounted() {
        injectOnfidoScripts();

        if (this.highRiskOptions != null && this.highRiskOptions.length > 0) {
            this.loadIdentificationOptions(additionalIdentificationOptions);
            return;
        }
        const result = await onfidoService.loadIdentificationOptions(this.onfidoWorkflowType, this.applicationStore.getBranch(), this.applicationStore.memberId);
        if (result && result.status < 300 && result.data) {
            this.showServerError = false;
            this.loadIdentificationOptions(result.data.Data);
        } else {
            this.showServerError = true;
            this.isStartBtnClicked = false;
            this.applicationStore.endLoadingStep();
        }
    },
    computed: {
        ...mapStores(useLanguagesStore, useApplicationStore, useUiStore),
        highRiskOptions() {
            return this.questions?.[0]?.Answers;
        },

        provideDocumentsKey() {
            var options = this.identificationOptions;

            // if any of type
            if (options != null && options.length > 0 && options.some((x) => x.type == "AdditionalDocument")) {
                return "prepare_two_of_the_following_documents";
            }

            return "prepare_one_of_the_following_documents";
        },

        debouncedRequest() {
            return useDebounceFn(this.startVerification, 1000);
        },

        showCloseBtn() {
            return this.showPopup && this.workflow && this.onfido;
        },
        isSkippable() {
            return this.applicationStore.currentStep.IsSkippable || this.showServerError;
        },
    },
    methods: {
        async closePopup() {
            await this.onfido.tearDown();
            this.isStartBtnClicked = false;
            this.showPopup = false;

            this.toggleSkipDialog = this.pickOneOrMore ? false : this.isSkippable;
        },
        async startVerification() {
            this.applicationStore.startLoadingStep();
            const hasCompletedVerification = await onfidoService.hasCompletedVerification(
                this.applicationStore.oaReference,
                this.applicationStore.getBranch(),
                this.onfidoWorkflowType
            );
            if (hasCompletedVerification) {
                this.toggleAlreadyCompletedDialog = true;
                this.applicationStore.endLoadingStep();
                return;
            }

            await this.initializeOnfido();
        },
        async updateDocuments() {
            this.toggleAlreadyCompletedDialog = false;
            await this.initializeOnfido();
        },
        async initializeOnfido() {
            this.applicationStore.startLoadingStep();
            this.isStartBtnClicked = true;
            if (this.questions.length && !this.onfidoWorkflowType) {
                this.showRequiredError = true;
                this.isStartBtnClicked = false;
                this.applicationStore.endLoadingStep();
                return;
            }

            if (this.workflow != null && this.workflow.WorkflowRunId != null) {
                this.startOnfidoWorkflow(this.workflow);
                return;
            }

            const result = await onfidoService.startWorkflow({
                Type: this.onfidoWorkflowType,
                OaMemberId: this.applicationStore.oaModelId,
                Branch: this.applicationStore.getBranch(),
            });
            if (result && result.status < 300 && result.data) {
                this.showServerError = false;
                this.workflow = result.data.Data;

                this.startOnfidoWorkflow(result.data.Data);
            } else {
                this.showServerError = true;
                this.isStartBtnClicked = false;
                this.applicationStore.endLoadingStep();
            }
        },

        loadIdentificationOptions(identityOptions) {
            if (identityOptions == null || identityOptions == "") return;
            // split and trim
            this.identificationOptions = [];
            var options = identityOptions.split(",").map((x) => x.trim());
            var pickOneOrMore = options.some((x) => x == "AdditionalDocument");
            this.pickOneOrMore = pickOneOrMore;

            options.forEach((option) => {
                var optionData = {};
                switch (option) {
                    case "PersonalId":
                        optionData = {
                            type: "PersonalId",
                            icon: "fa-id-card",
                            translationKey: pickOneOrMore ? "oa_identity_document" : "oa_onfido_personal_id",
                            selected: pickOneOrMore,
                            readonly: pickOneOrMore,
                            disabled: pickOneOrMore,
                            isSelectAvailable: pickOneOrMore,
                            type: "main",
                        };
                        break;
                    case "SecondaryId":
                        optionData = {
                            type: "SecondaryId",
                            icon: "fa-id-card",
                            translationKey: "oa_onfido_additional_personal_id",
                            isSelectAvailable: pickOneOrMore,
                            type: "main",
                            flowType: pickOneOrMore ? 2 : 0,
                        };
                        break;
                    case "Passport":
                        optionData = {
                            type: "Passport",
                            icon: "fa-passport",
                            translationKey: "identity_doc_passport",
                            isSelectAvailable: pickOneOrMore,
                            type: "main",
                        };
                        break;
                    case "DrivingLicense":
                        optionData = {
                            type: "DrivingLicense",
                            icon: "fa-driving-license",
                            translationKey: "oa_onfido_driving_license",
                            isSelectAvailable: pickOneOrMore,
                            type: "main",
                        };
                        break;

                    case "ResidentPermit":
                        optionData = {
                            type: "ResidentPermit",
                            icon: "fa-residence-permit",
                            translationKey: "oa_onfido_residence_permit",
                            isSelectAvailable: pickOneOrMore,
                            type: "main",
                        };
                        break;
                    case "Selfie":
                        optionData = {
                            type: "Selfie",
                            icon: "fa-selfie",
                            translationKey: "oa_onfido_selfie",
                            isSelectAvailable: pickOneOrMore,
                            type: "secondary",
                        };
                        break;
                    case "POA":
                        optionData = {
                            type: "POA",
                            icon: "fa-proof-address",
                            translationKey: pickOneOrMore ? "oa_onfido_proof_of_address" : "oa_onfido_provide_proof_of_address",
                            isSelectAvailable: pickOneOrMore,
                            type: pickOneOrMore ? "main" : "secondary",
                            // For flow type
                            flowType: pickOneOrMore ? 1 : 0,
                        };
                        break;
                    // case "AdditionalDocument":
                    //     optionData = { type: "AdditionalDocument", icon: "fa-file-invoice", translationKey: "oa_onfido_additional_document" };
                    //     break;
                }

                if (optionData.type != null) {
                    this.identificationOptions.push(optionData);
                }
            });
        },

        updateOption(payload) {
            this.showRequiredError = false;
            this.identificationOptions[payload.index].selected = payload.selected;
            this.identificationOptions.forEach((x) => {
                if (!x.readonly && x != this.identificationOptions[payload.index]) {
                    x.selected = false;
                }
            });

            var flowType = this.identificationOptions.filter((x) => x.selected && x.flowType);
            if (flowType.length > 0) {
                this.onfidoWorkflowType = Math.max.apply(
                    Math,
                    flowType.map((x) => x.flowType)
                );
            } else {
                this.onfidoWorkflowType = 0;
            }
        },

        startOnfidoWorkflow(onfidoWorkflow) {
            this.onfido = window.Onfido.init({
                token: onfidoWorkflow.Token,
                workflowRunId: onfidoWorkflow.WorkflowRunId,
                containerId: "onfido-mount-point",
                language: this.languagesStore.onfidoLanguage,
                crossDeviceClientIntroProductLogoSrc: "https://cdn.activtrades.com/PA/onfido/onfido-connected-with-phone.svg",
                customUI: this.uiStore.onfidoUi,
                onComplete: async (data) => {
                    setTimeout(async () => {
                        await this.onfido.tearDown();
                        await onfidoService.completeVerification(this.workflow.WorkflowRunId);
                        this.applicationStore.submit();
                    }, 4000);
                },
                onError: async (error) => {
                    console.error(error);
                    this.showServerError = true;
                    await this.onfido.tearDown();
                },
            });

            setTimeout(() => {
                this.showPopup = true;
                this.applicationStore.endLoadingStep();
            }, 2000);
        },
        async closeConfirmationDialogAndContunue() {
            await this.initializeOnfido();
            this.toggleSkipDialog = false;
        },
        async confirmAndSkip() {
            if (this.showServerError) {
                await this.applicationStore.skipStep();
                return;
            }
            this.toggleSkipDialog = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_radio.scss";
@import "src/assets/styles/_buttons.scss";

.onfido-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 100%;
    max-width: var(--application-width);
    max-height: 100svh;
    margin: 0 auto;
    background-color: var(--bg-secondary);
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    overflow-y: auto;

    @media (max-width: 430px) {
        top: 0;
        left: 0;
        transform: none;
        height: 100%;
        border-radius: 0;
    }
}

.close-onfido-popup {
    position: absolute;
    right: 16px;
    top: 14px;
    font-size: 1rem;
    fill: var(--info);
    cursor: pointer;
}

.onfido-overlay {
    top: 0px;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--overlay);
    backdrop-filter: blur(5px);
}

.display-letter {
    align-items: center;
    gap: 7px;
    width: auto;
    color: var(--font-tertiary);
    font-size: 1.22rem;
}
.display-letter .fa-svg {
    font-size: 1.25rem;
    line-height: 0;
    fill: var(--font-tertiary);
}
.active .display-letter .fa-svg {
    fill: var(--accent);
}
.HighRiskOneIdTwoPoA .fa-passport {
    display: none;
}

.HighRiskTwoIds .fa-file-invoice {
    display: none;
}

.pick-option {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--font-tertiary);
    margin: 26px 0;
    letter-spacing: normal;
}
.identification-container {
    margin: 0 0 1.75rem;
}
.identification-wrapper {
    display: inline-flex;
    flex-direction: column;
}
.identification-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.875rem;
    margin: 0;
}
.full-width.identification-wrapper {
    width: 100%;
}
.full-width.identification-wrapper .identification-cards-container.secondary {
    display: inline-flex;
}
.prepared {
    color: var(--font-main);
    margin: 0 0 var(--triple-padding);
}
.prepared :deep(span) {
    text-decoration: underline;
    font-weight: bold;
}
.and {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1.25rem 0;
}
.containers-wrapper {
    @media (max-width: 768px) {
        max-width: 165px;
        margin: 0 auto;
    }
}
.error {
    color: var(--error);
}
</style>

<style lang="scss">
#onfido-mount-point {
    border-radius: 0.5rem;
    height: 100%;

    @media (max-width: 430px) {
        border-radius: 0;
    }
}
#onfido-mount-point iframe {
    top: 0;
    left: 0;
    border-radius: 0.5rem;
    padding: 0;
    background: var(--bg-secondary);

    @media (max-width: 430px) {
        border-radius: 0;
    }
}
#onfido-sdk.loading::after {
    top: 0;
    left: 0;
    background-color: rgb(var(--bg-secondary-rgb) / 0.5) !important;
    border-radius: 0.5rem;

    @media (max-width: 430px) {
        border-radius: 0;
    }
}
</style>
