function injectOnfidoScripts(callback) {
    const scriptElement = document.createElement("script");
    scriptElement.src = `${import.meta.env.VITE_DEFAULT_PATH}onfido/onfido.min.js`;
    scriptElement.defer = true;
    document.head.appendChild(scriptElement);

    const linkElement = document.createElement("link");
    linkElement.id = "onfidoStyles";
    linkElement.rel = "stylesheet";
    linkElement.type = "text/css";
    linkElement.href = `${import.meta.env.VITE_DEFAULT_PATH}assets/onfido.css`;
    linkElement.media = "all";
    document.head.appendChild(linkElement);

    if (callback) scriptElement.onload = callback;
}

export default injectOnfidoScripts;
