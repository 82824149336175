<template>
    <div class="notification notification-error">
        <div>{{ $t("ls_not_allowed") }}</div>
    </div>
</template>
<script>
export default {
    name: "UIBlackListedCountryNotification",
    data() {
        return {};
    },
    mounted() {},
};
</script>
<style lang="scss" scoped></style>
