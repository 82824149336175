import { defineStore } from "pinia";
import { ref } from "vue";
import CountriesService from "@/services/countries.service";

export const useCountriesStore = defineStore("countries", () => {
    const countries = ref([]);

    function sortByName(lang) {
        return countries.value.sort((a, b) => {
            if (!lang) {
                console.error("Invalid language!");
                return 0;
            }
            try {
                const first = a.NameTranslations[lang.Id].toUpperCase();
                const second = b.NameTranslations[lang.Id].toUpperCase();
                return first < second ? -1 : first > second ? 1 : 0;
            } catch {
                console.error("Invalid language! " + lang);
                return 0;
            }
        });
    }

    async function load() {
        const result = await CountriesService.getAll();
        countries.value = result.data;
    }

    return {
        countries,
        sortByName,
        load,
    };
});
