<template>
    <div class="application flex-center" v-bind:class="getStepClass()">
        <!-- start loader -->
        <UILoader v-if="applicationStore.loading" :delay="3"></UILoader>
        <!-- end loader -->

        <div class="flex-center">
            <div v-if="applicationStore.currentStep != null" class="flex-center" :class="{ loading: applicationStore.loading }">
                <div class="flex-center">
                    <!-- <transition appear name="fade" @before-enter="beforeEnter" @after-enter="afterEnter" v-if="stepQuestions != null && stepQuestions.Id == step"> -->
                    <div class="animation">
                        <div class="full-width">
                            <Form
                                @submit="submitForm"
                                :validation-schema="applicationStore.validationSchema"
                                v-slot="{ setFieldError, errors, setFieldValue }"
                                ref="form"
                                autocomplete="off">
                                <div class="flex-switch">
                                    <div
                                        v-if="applicationStore.currentStep.Questions != null && applicationStore.currentStep.Questions.length > 0"
                                        v-bind:class="{
                                            'id-card-front': applicationStore.currentStep.Questions.find((question) => question.DisplayType == 'DocumentUploadRGFront'),
                                            'id-card-back': applicationStore.currentStep.Questions.find((question) => question.DisplayType == 'DocumentUploadRGBack'),
                                            selfie: applicationStore.currentStep.Questions.find((question) => question.DisplayType == 'Selfie'),
                                            'driving-license': applicationStore.currentStep.Questions.find((question) => question.DisplayType == 'DocumentUploadCNH'),
                                            passport: applicationStore.currentStep.Questions.find((question) => question.DisplayType == 'DocumentUploadOther'),
                                            'proof-address': applicationStore.currentStep.Questions.find((question) => question.DisplayType == 'PoA'),
                                        }"></div>
                                    <div>
                                        <h1
                                            v-if="applicationStore.currentStep.TitleKey"
                                            v-bind:class="{
                                                'has-subtitle': applicationStore.currentStep.SubtitleKey,
                                            }">
                                            {{ $t(applicationStore.currentStep.TitleKey, applicationStore.translationsParams[applicationStore.currentStep.TitleKey]) }}
                                        </h1>

                                        <AppStepSubtitleVideo :step="applicationStore.currentStep"></AppStepSubtitleVideo>
                                    </div>

                                    <div v-if="dynamicComponent(applicationStore.currentStep)" class="max-100">
                                        <component
                                            v-bind:is="applicationStore.currentStep.DisplayType"
                                            v-bind:questions="applicationStore.currentStep.Questions"
                                            v-bind:errors="errors"
                                            v-bind:nextButtonKey="applicationStore.currentStep.NextButtonKey"
                                            v-bind:setFieldError="setFieldError"
                                            v-bind:setFieldValue="setFieldValue"></component>
                                    </div>
                                    <div v-else class="max-100">
                                        <Default v-bind:questions="applicationStore.currentStep.Questions" v-bind:errors="errors"></Default>
                                    </div>
                                </div>

                                <button
                                    id="submit_button"
                                    type="submit"
                                    v-if="
                                        applicationStore.currentStep.NextButtonKey != null &&
                                        applicationStore.currentStep.NextButtonKey != '' &&
                                        uiStore.isSubmitButtonVisible &&
                                        applicationStore.currentStep.DisplayType != 'Questionnaire'
                                    "
                                    :disabled="!!uiStore.isSubmitButtonDisabled">
                                    {{ $t(applicationStore.currentStep.NextButtonKey, applicationStore.translationsParams[applicationStore.currentStep.NextButtonKey]) }}
                                </button>

                                <button
                                    type="button"
                                    v-if="
                                        applicationStore.currentStep.IsSkippable &&
                                        uiStore.isSkipButtonEnabled &&
                                        (applicationStore.currentStep.SkipButtonStyle === 'Bottom' || applicationStore.currentStep.SkipButtonStyle === 'TopAndBottom')
                                    "
                                    @click="applicationStore.skipStep"
                                    class="secondary-btn margin-top-single">
                                    {{ $t(applicationStore.currentStep.SkipButtonKey, applicationStore.translationsParams[applicationStore.currentStep.SkipButtonKey]) }}
                                </button>
                            </Form>
                        </div>
                    </div>
                    <!-- </transition> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
import { Form } from "vee-validate";

import Start from "./questions/style/Start.vue";
import Default from "./questions/style/Default.vue";
import CountryTins from "./questions/style/CountryTins.vue";
import MultipleNationalities from "./questions/style/MultipleNationalities.vue";
import Address from "./questions/style/Address.vue";
import SuitabilityFailed from "@/components/oa/AppFailedSuitability.vue";
import TradingXpFailed from "@/components/oa/AppFailedTradingExperience.vue";
import AppStepSubtitleVideo from "@/components/oa/AppStepSubtitleVideo.vue";
import TradingExperienceBegin from "@/components/oa/AppTradingExperienceBegin.vue";
import KnowledgeTestBegin from "@/components/oa/AppKnowledgeTestBegin.vue";
import TradingXpBhGenFailed from "@/components/oa/AppFailedTradingExperienceBahamas.vue";
import TradingXpUkLuFailed from "@/components/oa/AppFailedTradingExperienceUKLuxemburg.vue";
import OnfidoVerification from "@/components/oa/AppOnfidoVerification.vue";
import Final from "./questions/style/Final.vue";
import UILoader from "@/components/ui/UILoader.vue";
import Questionnaire from "./questions/style/Questionnaire.vue";

import constants from "@/common/constants";

import eventBus from "@/eventBus";
import { useDebounceFn } from "@vueuse/core";

import { useUiStore } from "@/store/interface.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";

export default {
    name: "Steps",
    components: {
        Form,
        Default,
        Start,
        CountryTins,
        MultipleNationalities,
        TradingXpFailed,
        SuitabilityFailed,
        AppStepSubtitleVideo,
        Address,
        TradingExperienceBegin,
        KnowledgeTestBegin,
        TradingXpUkLuFailed,
        TradingXpBhGenFailed,
        OnfidoVerification,
        Final,
        UILoader,
        Questionnaire,
    },
    data: function () {
        return {
            dynamicComponents: [
                "Start",
                "CountryTins",
                "MultipleNationalities",
                "TradingXpFailed",
                "SuitabilityFailed",
                "Address",
                "TradingExperienceBegin",
                "KnowledgeTestBegin",
                "TradingXpUkLuFailed",
                "TradingXpBhGenFailed",
                "OnfidoVerification",
                "Final",
                "Questionnaire",
            ],
            box: null,
            pageTransition: null,
            changeFieldTimeout: 0,
        };
    },
    mounted() {
        var self = this;

        eventBus.$off("TerminateOa");
        eventBus.$on("TerminateOa", () => {
            var form = self.$refs["form"];
            var submitButton = form.$el.getElementsByTagName("button")[0];

            Promise.resolve(form.validate()).then(function (value) {
                if (value.valid) {
                    submitButton.click();
                }
            });
        });
    },
    watch: {
        step: function (newVal, oldVal) {
            if (newVal >= oldVal) {
                this.playForwardAnimation();
            } else {
                this.playBackAnimation();
                this.applicationStore.currentStep.Submitted = true;
            }
        },
        currentStep: {
            handler: function (old, newValue) {
                if (this.changeFieldTimeout < Date.now()) {
                    if (Object.keys(newValue).length > 0 && old.Id == newValue.Id) {
                        this.applicationStore.currentStep.Submitted = false;
                    }
                }
                this.changeFieldTimeout = Date.now() + 500;
            },
            deep: true,
        },
    },
    computed: {
        ...mapStores(useUiStore, useApplicationStore),
        debouncedRequest() {
            return useDebounceFn(this.submit, 200);
        },
    },
    methods: {
        async submitForm() {
            await this.debouncedRequest();
        },
        dynamicComponent() {
            return this.dynamicComponents.includes(this.applicationStore.currentStep.DisplayType);
        },

        async submit() {
            var self = this;

            // if (this.applicationStore.currentStep.DisplayType == "Start") {
            //     await this.$recaptchaLoaded();

            //     const token = await this.$recaptcha("login");
            //     this.applicationStore.currentStep.RecaptchaToken = token;
            // }

            await this.applicationStore.submit().then(() => {
                self.validateSideErrors();
            });

            this.clearValuesAfterSubmit();

            return false;
        },

        clearValuesAfterSubmit() {
            var terminateOaField = this.applicationStore.currentStep.Questions.filter((x) => x.FieldName == constants.TerminateOaFieldName);

            if (terminateOaField != null && terminateOaField.length > 0) {
                terminateOaField[0].Answers[0].Value = "";
            }
        },

        validateSideErrors() {
            var self = this;
            for (var key in this.applicationStore.serverSideErrors) {
                if (!this.applicationStore.serverSideErrors.hasOwnProperty(key)) continue;

                var obj = this.applicationStore.serverSideErrors[key];
                for (var prop in obj) {
                    if (!obj.hasOwnProperty(prop)) continue;

                    setTimeout(() => {
                        self.$refs.form.setFieldError(key, obj[prop], false);
                    }, 10);
                    break;
                }
            }
        },

        getStepClass() {
            switch (this.applicationStore.currentStep.DisplayType) {
                case "Start":
                    return "homepage";
                case "IdVerification":
                    return "id-verification";
                case "UploadDocuments":
                    return "upload-documents";
                case "PlatformSelection":
                    return "platform-selection";
                case "TraderProfile":
                    return "trader-profile";
                case "SuitabilityFailed":
                    return "suitability-failed";
                case "Final":
                    return "final";
                case "SelectBranch":
                    return "select-branch";
                case "OnfidoVerification":
                    return "onfido-verification";
            }
        },

        beforeEnter() {
            document.querySelector("#app").style.overflow = "hidden";
        },

        afterEnter() {
            document.querySelector("#app").style.overflow = "auto";
            eventBus.$emit("endPageAnimation");
        },

        playForwardAnimation() {
            if (document.querySelector(".animation") !== null) {
                gsap.fromTo(
                    ".animation",
                    { opacity: 0, transform: "translateX(100vw)" },
                    {
                        opacity: 1,
                        transform: "translateX(0)",
                        duration: 0.7,
                        ease: "power3.out",
                    }
                );
            }
        },

        playBackAnimation() {
            if (document.querySelector(".animation") !== null) {
                gsap.fromTo(
                    ".animation",
                    { opacity: 0, transform: "translateX(-100vw)" },
                    {
                        opacity: 1,
                        transform: "translateX(0)",
                        duration: 0.7,
                        ease: "power3.out",
                    }
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_animations.scss";
@import "src/assets/styles/_buttons.scss";

.loading {
    opacity: 1;
    animation-name: fadeOut, blurAnimation;
    animation-duration: 0.4s, 0.4s;
    animation-fill-mode: forwards, forwards;
    animation-iteration-count: 1, 1;
    animation-delay: 0s, 2s;
}

@keyframes blurAnimation {
    0% {
        filter: blur(0px);
    }
    100% {
        filter: blur(1px);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.4;
    }
}

.full-width {
    max-width: 100%;
    display: flex;
    flex-grow: 1;
}

.animation {
    min-width: 0; /* this allows the flexbox children to shrink and respect the padding */
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.flex-switch {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: auto;
}

.max-100 {
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
}

.max-100 > div {
    width: 100%;
}

.trader-profile .max-100 > div,
.trader-profile .question > div {
    height: 100%;
}
</style>
