<template>
    <div>
        <div class="form-field country">
            <div class="relative-pos">
                <div v-if="question != null" class="country-flag">
                    <div v-if="currentSelectedCountry != null && currentSelectedCountry.AdditionalData.Code != null && currentSelectedCountry.AdditionalData.Code != ''">
                        <UIFlag v-bind:src="currentSelectedCountry != null ? currentSelectedCountry.AdditionalData.Code : ''" width="auto" height="2.25"></UIFlag>
                    </div>
                </div>
                <Field :name="fieldName" type="hidden" v-model="question.SelectedAnswers[0]"> </Field>

                <input
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors[fieldName],
                        'has-flag': question.Code != '',
                    }"
                    :placeholder="placeHolder"
                    @focus="defaultFocus"
                    @click="toggle"
                    @blur="blur"
                    :value="searchText"
                    @input="(e) => (searchText = e.target.value)"
                    ref="dropdownInput"
                    autocomplete="new-password"
                    :disabled="isDisabled()" />

                <label class="small-label">{{ $t(question.TextKey) }}</label>
                <span class="dropdown-arrow" @click="toggle">
                    <i v-if="dropdownArrow == 'down'">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-thin fa-chevron-down">
                            <path
                                d="M250.3 397.7c3.1 3.1 8.2 3.1 11.3 0l216-216c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L256 380.7 45.7 170.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l216 216z" />
                        </svg>
                    </i>
                    <i v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-thin fa-chevron-up">
                            <path
                                d="M250.3 114.3c3.1-3.1 8.2-3.1 11.3 0l216 216c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L256 131.3 45.7 341.7c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3l216-216z" />
                        </svg>
                    </i>
                </span>
            </div>
            <div v-if="displayDropdown" class="dropdown">
                <ul>
                    <li v-for="country in filteredDropdownCountries" v-bind:key="country.Id">
                        <a href="javascript:void(0)" v-on:mousedown.prevent="select(country)" v-bind:class="{ disabled: selectDisabled(country.Id) }">
                            <div class="flag-container">
                                <div class="country-flag small">
                                    <UIFlag v-bind:src="country.AdditionalData.Code" height="1.5"></UIFlag>
                                </div>
                            </div>
                            <span>{{ country.AdditionalData.TranslatedDropdown }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <ErrorMessage v-bind:name="question.FieldName" />
        </div>
    </div>
</template>

<script>
import UIFlag from "@/components/ui/UIFlag.vue";
import eventBus from "@/eventBus";

export default {
    name: "AppCountryDropdown",
    props: ["question", "errors", "answerIndex", "multilineValidation", "disableOptions", "preSelectedCountryId", "groupIndex"],
    emits: ["on-change-country", "change-continue-button-state"],
    components: {
        UIFlag,
    },
    data: function () {
        return {
            placeHolder: this.$t(this.question.TextKey),
            displayDropdown: false,
            currentAnswerIndex: 0,
            currentCountry: null,
            searchText: "",
            dropdownArrow: "down",
            index: this.groupIndex,
            isDirty: false,
        };
    },
    created() {
        if (this.question.SelectedAnswers[0] == 0) {
            this.question.SelectedAnswers[0] = null;
            return;
        }

        const country = this.question.Answers.find((country) => country.Id == this.question.SelectedAnswers[0]);
        if (country) {
            this.select(country);
        }
    },
    beforeMount() {
        if (this.answerIndex != null) {
            this.currentAnswerIndex = this.answerIndex;
        }

        if (this.currentAnswerIndex && this.question.Answers[this.currentAnswerIndex] == null) {
            this.question.Answers.push({ Answer: "", Code: "" });
        }

        if (this.multilineValidation != null) {
            if (this.question.MultiLineValidation == null) {
                this.question.MultiLineValidation = [];
            }

            this.question.MultiLineValidation.push(this.fieldName);
        }
    },
    mounted() {
        var self = this;

        if (self.question.SelectedAnswers[0] != 0) {
            self.question.DefaultValue = self.question.SelectedAnswers[0];
        }

        this.selectDefaultCountry();

        eventBus.$on("changeCountryById", (id) => {
            self.question.DefaultValue = id;
            self.selectDefaultCountry();
        });
    },
    computed: {
        avaliableCountries() {
            if (this.disableOptions && this.disableOptions.length) {
                return this.question.Answers.filter((answer) => !this.disableOptions.includes(answer.Id));
            }

            return this.question.Answers;
        },
        currentSelectedCountry() {
            if (this.question.SelectedAnswers[0] != null && this.question.SelectedAnswers[0] != 0) {
                var answer = this.question.Answers.filter((answer) => answer.Id == this.question.SelectedAnswers[0]);
                if (answer != null) {
                    return answer[0];
                }
            }

            return null;
        },

        currentSelectedCountryName() {
            if (this.currentSelectedCountry != null) {
                return this.currentSelectedCountry.AdditionalData.TranslatedDropdown;
            }
            return null;
        },

        fieldName() {
            return this.multilineValidation != null ? this.question.FieldName + "_" + this.currentAnswerIndex : this.question.FieldName;
        },

        filteredDropdownCountries() {
            var self = this;
            var filtered = this.avaliableCountries
                .filter((country) => country.AdditionalData.TranslatedDropdown.trim().toLowerCase().includes(self.searchText.toLowerCase()))
                .sort((a, b) => {
                    const first = a.AdditionalData.TranslatedDropdown.toUpperCase();
                    const second = b.AdditionalData.TranslatedDropdown.toUpperCase();
                    return first < second ? -1 : first > second ? 1 : 0;
                });

            return filtered;
        },
    },
    methods: {
        selectDefaultCountry() {
            var self = this;

            if (self.question.DefaultValue != null && self.questionDefaultValue != 0) {
                const countryId = this.preSelectedCountryId || self.question.DefaultValue;
                var country = self.question.Answers.find((country) => country.Id == countryId);
                if (country) {
                    self.select(country);
                }
            }
        },

        defaultFocus() {
            this.placeHolder = "";
            this.isDirty = true;
        },

        toggle() {
            if (this.displayDropdown) {
                this.$refs.dropdownInput.blur();
            } else {
                this.$refs.dropdownInput.focus();
                this.focus();
            }
        },

        focus() {
            this.searchText = "";

            this.question.PreviousCountry = this.currentCountry;

            this.placeHolder = this.currentSelectedCountryName != null ? this.currentSelectedCountryName : "";
            this.displayDropdown = true;
            this.dropdownArrow = "up";
        },
        blur() {
            this.dropdownArrow = "down";
            this.hideDropdown();
            if (this.searchText == null || this.searchText == "") {
                this.select(this.question.PreviousCountry, true);
                return;
            }

            const typedCountry = this.avaliableCountries.find((country) => country.AdditionalData.TranslatedDropdown.toLowerCase() == this.searchText.toLowerCase());
            if (typedCountry) {
                this.select(typedCountry);
                return;
            }

            this.select(this.question.PreviousCountry);
            this.placeHolder = this.$t(this.question.TextKey);
        },
        select(country, isPrevious) {
            if (country != null) {
                this.selectCountry(country, isPrevious);

                this.$emit("on-change-country", {
                    countryRiskGroup: country.AdditionalData.RiskGroup,
                });

                eventBus.$emit("on-change-country", country.Id);
            }
        },
        selectCountry(country, isPrevious) {
            this.searchText = country.AdditionalData?.TranslatedDropdown;
            this.question.SelectedAnswers[0] = country.Id;
            country.IsExternalAnswer = true;

            var answer = this.question.Answers.filter((answer) => answer.Id == this.question.SelectedAnswers[0]);
            if (answer != null) {
                this.currentCountry = answer[0];
            }

            if (this.question.DisplayType == "CountryOfResidenceDropdown") {
                this.handleCountryRiskStatus();
            }
            this.hideDropdown();
            this.isDirty = false;

            if (this.index && !isPrevious) {
                eventBus.$emit(`on-country-tim-change-${this.index}`, country);
            }
        },

        handleCountryRiskStatus() {
            if (this.currentCountry.AdditionalData.RiskGroup == "BlackListed") {
                eventBus.$emit("change-continue-button-state", false);
            } else {
                eventBus.$emit("change-continue-button-state", true);
            }
        },

        hideDropdown() {
            this.displayDropdown = false;
            this.dropdownArrow = "down";
        },
        selectDisabled(countryId) {
            var answers = this.question.Answers.some((x) => x.Value == countryId) && this.question.Answers[this.currentAnswerIndex].Value != countryId;

            return answers;
        },
        isDisabled() {
            return this.question.DisplayType == "Readonly" ? "disabled" : false;
        },
    },
    watch: {
        searchText(newValue, oldValue) {
            if (newValue?.toLowerCase() !== oldValue?.toLowerCase()) {
                this.question.PreviousCountry = this.currentCountry;

                this.placeHolder = this.currentSelectedCountryName != null ? this.currentSelectedCountryName : "";
                if (this.isDirty) {
                    this.displayDropdown = true;
                    this.dropdownArrow = "up";
                }
            }
        },

        currentSelectedCountryName(newValue, oldValue) {
            if (newValue?.toLowerCase() !== oldValue?.toLowerCase()) {
                this.searchText = newValue;
                this.hideDropdown();

                if (this.index) {
                    eventBus.$emit(`on-country-tim-change-${this.index}`, this.currentSelectedCountry);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.country .form-control {
    cursor: pointer;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.flag-container {
    width: 2.25rem;
    display: flex;
    justify-content: center;
}
.country-flag {
    position: absolute;
    top: 50%;
    left: var(--form-control-padding);
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    background: var(--bg-main);
    border: 1px solid var(--border-main);
    border-radius: 50%;
    overflow: hidden;
}
.rtl .country-flag {
    right: var(--form-control-padding);
    left: auto;
}
.country-flag > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.country-flag.small {
    position: static;
    width: 1.5rem;
    height: 1.5rem;
    transform: initial;
}

.form-field.country .small-label,
.form-field.country input.has-flag {
    padding-left: 3.75rem;
}
.rtl .form-field.country .small-label,
.rtl .form-field.country input.has-flag {
    padding-right: 3.75rem;
    padding-left: 0;
}

.form-field.country .form-control:not(.is-invalid):focus + .small-label,
.form-field.country .form-control:not(.is-invalid):not(:placeholder-shown) + .small-label,
.form-field.country .form-control:-webkit-autofill:not(.is-invalid) + .small-label {
    padding-left: 3.75rem;
}

.rtl .form-field.country .form-control:not(.is-invalid):focus + .small-label,
.rtl .form-field.country .form-control:not(.is-invalid):not(:placeholder-shown) + .small-label,
.rtl .form-field.country .form-control:-webkit-autofill:not(.is-invalid) + .small-label {
    padding-right: 3.75rem;
    padding-left: 0;
}

input::-webkit-input-placeholder {
    color: transparent;
}

input:focus::-webkit-input-placeholder {
    color: #888;
}
</style>
