import axios from "axios";
import { useApplicationStore } from "@/store/onlineapplication.js";

const API_URL = import.meta.env.VITE_API_URL;

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
    validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
    },
});

instance.interceptors.request.use(function (config) {
    const applicationStore = useApplicationStore();
    config.headers["X-Reference"] = applicationStore.oaReference;
    return config;
});

export default instance;
