import http from "@/backend/vue-axios/axios";
import endpoints from "../common/endpoints";

class CorporateLinksService {
    async getAll() {
        var corporateLinks = http.get(`${endpoints.CorporateLinksUrl}`);
        return corporateLinks;
    }
}

export default new CorporateLinksService();
