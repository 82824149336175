<template>
    <div class="application">
        <div class="wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="fa-svg fa-thin fa-triangle-exclamation">
                <path
                    d="M20.7 412.3c-3.1 5.3-4.7 11.2-4.7 17.3c0 19 15.4 34.4 34.4 34.4H461.6c19 0 34.4-15.4 34.4-34.4c0-6.1-1.6-12.1-4.7-17.3L290.3 67.7C283.2 55.5 270.1 48 256 48s-27.2 7.5-34.3 19.7L20.7 412.3zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4c0 27.8-22.6 50.4-50.4 50.4H50.4C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c4.4 0 8 3.6 8 8V328c0 4.4-3.6 8-8 8s-8-3.6-8-8V168c0-4.4 3.6-8 8-8zM240 384a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z" />
            </svg>
            <h1>{{ $t("oa_flow_updated") }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "OaFlowUpdated",
};
</script>

<style lang="scss" scoped>
.wrapper {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.wrapper .fa-svg {
    display: block;
    font-size: 12.125rem;
    margin-bottom: 2.188rem;
    fill: var(--font-main);
}
.wrapper a {
    color: inherit;
}
h1 {
    margin-left: 0;
    margin-right: 0;
}

@media (max-width: 580px) {
    .wrapper .image {
        width: 6.25rem;
    }
}
</style>
