<template>
  <div>
    <select :name="question.Code" v-model="question.Code">
      <option
        v-for="licence in DrivingLicences"
        v-bind:key="licence.Id"
        v-bind:value="licence.Code"
      >
        {{ licence.Code }}
      </option>
    </select>

    <Field
      :name="question.FieldName"
      type="text"
      class="form-control"
      :class="{ 'is-invalid': errors[question.FieldName] }"
      v-model="question.Value"
    ></Field>
    <br />
    <ErrorMessage v-bind:name="question.FieldName" />
  </div>
</template>

<script>

export default {
  name: "AppBrDrivingLicence",
  props: ["question", "errors"],
  data() {
    return {
      DrivingLicences: [],
    };
  },
  mounted() {
    this.DrivingLicences = [
      {
        Code: "AC",
      },
      {
        Code: "AL",
      },
      {
        Code: "AP",
      },
      {
        Code: "AM",
      },
      {
        Code: "BA",
      },
      {
        Code: "CE",
      },
    ];
  },
};
</script>
