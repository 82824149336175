<template>
    <AppScrollable :buttonKey="question.SaveButtonKey" :showButton="submitAllowed">
        <h1 v-if="question.TextKey">{{ $t(question.TextKey) }}</h1>

        <div v-for="(answer, i) in question.Answers" v-bind:key="answer.Id" class="popover-wrapper">
            <div class="radio-btn" :class="{ active: answer.Id == question.SelectedAnswers[0], 'select-branch': applicationStore.currentStep.DisplayType == 'SelectBranch' }">
                <input name="Answer" type="radio" v-bind:id="question.FieldName + answer.Id" v-bind:value="answer.Value" v-model="question.Value" @click="change(answer)" />
                <label v-bind:for="question.FieldName + answer.Id">
                    <BranchSelectPopover :answer="answer"></BranchSelectPopover>
                </label>
            </div>
        </div>

        <Field as="hidden" class="form-control" :name="question.FieldName" v-model="selectedAnswer"> </Field>

        <ErrorMessage :name="question.FieldName" />
    </AppScrollable>
</template>

<script>
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import eventBus from "@/eventBus";

import BranchSelectPopover from "@/components/ui/popover/BranchSelectPopover.vue";
import AppScrollable from "@/components/oa/AppScrollable.vue";

export default {
    name: "AppBranchSelection",
    props: ["question", "errors"],
    components: {
        BranchSelectPopover,
        AppScrollable,
    },
    data() {
        return {
            submitStepOnClick: true,
            isVisible: true,
            userStatus: "",
            isDemoUserLoggedInPA: false,
            sendChangePasswordEvent: false,
            uniqueEmailRadioButtons: false,
            hoverInProgress: false,
            selectedAnswer: null,
        };
    },
    mounted() {
        var self = this;
        if (this.question.DisplayType == "UniqueEmailRadioButtons") {
            self.submitStepOnClick = false;
            self.isVisible = false;
            self.sendChangePasswordEvent = true;
            self.uniqueEmailRadioButtons = true;
        }

        if (this.applicationStore.currentStep.NextButtonKey != null) {
            self.submitStepOnClick = false;
        }

        if (this.question.SelectedAnswers[0] > 0) {
            var answerId = this.question.SelectedAnswers[0];

            this.question.Answers.forEach((answer) => {
                if (answer.Id == answerId) {
                    this.selectedAnswer = answer.Value;
                    answer.popoverDisplayed = true;
                }
            });
        }

        eventBus.$on("changeRadio", (answer) => {
            self.change(answer);
            eventBus.$off("changeRadio");
        });
    },
    unmounted() {},
    created() {},
    methods: {
        change(answer) {
            if (this.question.SelectedAnswers[0] == answer.Id) {
                this.question.SelectedAnswers[0] = 0;
                return;
            }

            if (this.question.SelectedAnswers[0] != 0 && this.question.SelectedAnswers[0] == answer.Id) {
                this.applicationStore.setNextStepIfNoAnswerIsChanged();
                return false;
            }
            this.question.SelectedAnswers[0] = answer.Id;
            this.selectedAnswer = answer.Value;
            answer.popoverDisplayed = true;

            eventBus.$emit("ChangePasswordLabelEvent", answer.TextKey);

            if (this.submitStepOnClick) {
                this.applicationStore.submit();
            }
        },

        hoverRadio(answer) {
            this.hoverInProgress = true;
            answer.displayPopover = true;
        },

        leaveRadio(answer) {
            this.hoverInProgress = false;
            answer.displayPopover = false;
        },
    },
    computed: {
        ...mapStores(useApplicationStore),
        submitAllowed() {
            return this.question.SelectedAnswers[0] > 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_animations.scss";
@import "src/assets/styles/_radio.scss";
</style>
