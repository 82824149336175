import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueAxios from "vue-axios";
import axios from "./backend/vue-axios/axios";
import i18n from "./i18n";
import dynamicTranslation from "./directives/dynamic-translation";
import Cleave from "vue-cleave-component";
import { Field, ErrorMessage } from "vee-validate";
import { createGtm } from "@gtm-support/vue-gtm";
import "@egjs/vue3-flicking/dist/flicking.css";
import { createPinia } from "pinia";
import { VueReCaptcha } from "vue-recaptcha-v3";

createApp(App)
    .use(i18n)
    .use(router)
    .use(createPinia())
    .use(VueAxios, axios)
    .use(Cleave)
    .use(
        createGtm({
            id: import.meta.env.VITE_GTM_ID,
            defer: true,
            compatibility: true,
            enabled: false,
            debug: import.meta.env.VITE_GTM_DEBUG === "true",
            vueRouter: router,
            trackOnNextTick: false,
            loadScript: false,
        })
    )
    .use(VueReCaptcha, { siteKey: import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY })
    .component("Field", Field)
    .component("ErrorMessage", ErrorMessage)
    .directive("dynamic-translation", dynamicTranslation)
    .mount("#app");
