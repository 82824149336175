import * as yup from "yup";
import moment from "moment";
import constants from "@/common/constants";
import endpoints from "@/common/endpoints";
import http from "@/backend/vue-axios/axios";

function minLength(length, msg) {
    return this.test({
        name: "minLength",
        message: msg,
        test: (value) => value && value.toString().length >= length,
    });
}

function maxLength(length, msg) {
    return this.test({
        name: "maxLength",
        message: msg,
        test: (value) => value && value.toString().length <= length,
    });
}

function isAccepted(requiredValue, msg) {
    return this.test({
        name: "isAccepted",
        message: msg,
        test: (value) => value === requiredValue,
    });
}

function greaterThanEighteen(msg) {
    return this.test({
        name: "greaterThanEighteen",
        message: msg,
        test: (value) => {
            const date = moment(value, constants.BrazilianDateFormat, true);
            if (!date.isValid()) return false;

            const years = moment().diff(date, "years", true);
            return years >= 18;
        },
    });
}

function afterDate(minDateString, msg) {
    return this.test({
        name: "afterDate",
        message: msg,
        test: (value) => {
            const date = moment(value, constants.BrazilianDateFormat, true).valueOf();
            const minDate = moment(minDateString, constants.BrazilianDateFormat, true).valueOf();
            const maxFutureDate = moment.utc().add(20, "y");
            return date >= minDate && date <= maxFutureDate;
        },
    });
}

function isValidCpfFormat(msg) {
    return this.test({
        name: "isValidCpfFormat",
        message: msg,
        test: async (value) => {
            return new Promise((resolve) => {
                http.get(endpoints.ValidCpfFormatUrl + "?cpf=" + value)
                    .then((result) => {
                        resolve(result.data);
                    })
                    .catch((result) => {
                        console.error(result);
                        resolve(false);
                    });
            });
        },
    });
}

function isDateNotTooOld(msg) {
    return this.test({
        name: "isValidDate",
        message: msg,
        test: (value) => {
            const inputDate = moment(value, constants.BrazilianDateFormat, true).valueOf();
            const maxDateInThePast = moment.utc().subtract(110, "y");
            return inputDate >= maxDateInThePast;
        },
    });
}

yup.addMethod(yup.number, "minLength", minLength);
yup.addMethod(yup.number, "maxLength", maxLength);
yup.addMethod(yup.boolean, "isAccepted", isAccepted);
yup.addMethod(yup.string, "greaterThanEighteen", greaterThanEighteen);
yup.addMethod(yup.string, "afterDate", afterDate);
yup.addMethod(yup.string, "isValidCpfFormat", isValidCpfFormat);
yup.addMethod(yup.string, "isDateNotTooOld", isDateNotTooOld);

export default yup;
