import EnOrigin from "@/enums/EnOrigin";
import { EnEvent } from "@/enums/EnEvent";

export function broadcastEvent(origin, event) {
    if (!origin || origin === EnOrigin.Unspecified) return;

    if (origin === EnOrigin.iOS && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.oaMessageHandler) {
        return window.webkit.messageHandlers.oaMessageHandler.postMessage(event);
    }

    if (origin === EnOrigin.Android) {
        return OaApplication.handleOaEvent(JSON.stringify(event));
    }

    if (origin === EnOrigin.CorporateWebsite || origin === EnOrigin.WebPlatform || origin === EnOrigin.PA) {
        return window.parent.postMessage(event, "*");
    }
}
