<template>
    <div class="application failed-oa">
        <div class="image"></div>
        <h1>{{ $t("lbl_something_went_wrong") }}</h1>
        <p v-html="$t('lbl_oa_failed_submit_step', [contactUrl])"></p>
    </div>
</template>
<script setup>
import { computed } from "vue";
import urls from "@/data/corporateUrls";
import i18n from "@/i18n.js";

const { locale } = i18n.global;
const corporateWebsiteUrls = urls[import.meta.env.VITE_CORPORATE_URLS_ENV];
const contactUrl = computed(() => {
    const sites = corporateWebsiteUrls.find((x) => x.Language == locale);
    if (sites != null) {
        return sites["Link"]["Contact"];
    }
    return "";
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_failed.scss";
@import "src/assets/styles/main.scss";

.failed-oa h1:before {
    content: "";
}
</style>
