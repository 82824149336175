<template>
    <div class="form-field phone-number">
        <Field
            :name="question.FieldName"
            type="tel"
            @keypress="onlyNumbers"
            class="form-control"
            :class="{ 'is-invalid': errors[question.FieldName] }"
            v-model="question.Answers[0].Value"
            placeholder="Phone number"></Field>
        <label class="small-label">{{ $t("phone") }}</label>
        <ErrorMessage v-bind:name="question.FieldName" />
    </div>
</template>

<script>
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";

export default {
    name: "UIPhone",
    props: ["question", "errors"],
    beforeMount() {
        this.applicationStore.applyValidations();
    },
    methods: {
        onlyNumbers($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 190) {
                $event.preventDefault();
            }
        },
    },

    computed: {
        ...mapStores(useApplicationStore),
    },
};
</script>

<style lang="scss" scoped>
.phone-number .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.rtl .phone-number .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: var(--border-radius-main);
    border-bottom-left-radius: var(--border-radius-main);
}
</style>
