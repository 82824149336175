<template>
    <div class="form-field" :class="{ active: checked, error: invalid }">
        <label :for="answer?.Id" class="checkbox-label">
            <input :id="answer?.Id" :name="answer?.Id" v-model="isChecked" type="checkbox" />
            <div class="fake-checkbox"></div>
            <span v-dynamic-translation="{ key: answer?.TextKey, params: answer?.TextKeyParams }"></span>
        </label>
    </div>
</template>

<script>
export default {
    name: "UICheckbox",
    props: ["answer", "checked", "invalid"],
    data() {
        return {
            isActive: false,
            isChecked: this.checked,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_checkbox.scss";
</style>
