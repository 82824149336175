<template>
    <div v-if="showRadioButtons">
        <div class="note" v-if="props.isDemoRegistration">
            <span @click.prevent="emit('on-login-clicked')" v-html="t('demo_oa_in_progress')"> </span>
        </div>
        <div class="note" v-else-if="uiStore.origin !== EnOrigin.Unspecified">
            <span v-if="applicationStore.customerStatus.UserStatus == 'DemoUserWithOa'">
                {{ t("oa_oa_in_progress") }}
            </span>

            <span v-if="applicationStore.customerStatus.UserStatus == 'DemoUser'">
                {{ t("already_existing_demo") }}
            </span>
            <span v-if="applicationStore.customerStatus.UserStatus == 'LiveUser'" @click.prevent="emit('on-login-clicked')" v-html="t('demo_oa_in_progress')"> </span>
            <span
                @click.prevent="emit('on-login-clicked')"
                v-if="applicationStore.customerStatus.UserStatus == 'ApplicationAlreadySubmitted'"
                v-html="t('application_already_submited')"></span>
        </div>
        <div class="note" v-else>
            <span v-if="applicationStore.customerStatus.UserStatus == 'DemoUserWithOa'">
                {{ t("oa_oa_in_progress", [personalAreaHomePageUrl]) }}
            </span>

            <span v-if="applicationStore.customerStatus.UserStatus == 'DemoUser'">
                {{ t("already_existing_demo") }}
            </span>
            <span
                v-if="applicationStore.customerStatus.UserStatus == 'LiveUser' && applicationStore.customerStatus.BranchMatches"
                v-html="t('live_user_login_to_request_second_account', [endpoints.OpenSecondAccountPageUrl])">
            </span>
            <span v-if="applicationStore.customerStatus.UserStatus == 'LiveUser' && !applicationStore.customerStatus.BranchMatches">
                {{ t("live_user_use_different_email_to_continue") }}
            </span>
            <div
                v-if="applicationStore.customerStatus.UserStatus == 'ApplicationAlreadySubmitted'"
                v-html="t('application_already_submited', [endpoints.PersonalAreaHomePageUrl])"></div>
        </div>
    </div>
</template>

<script setup>
import endpoints from "@/common/endpoints";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { useUiStore } from "@/store/interface.js";
import { computed, watch } from "vue";
import i18n from "@/i18n.js";
import EnOrigin from "@/enums/EnOrigin";

const { t } = i18n.global;
const props = defineProps({
    isDemoRegistration: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["on-login-clicked"]);
const applicationStore = useApplicationStore();
const uiStore = useUiStore();

const showRadioButtons = computed(() => {
    if (applicationStore.customerStatus == null || applicationStore.customerStatus == "") {
        return false;
    }
    let allowedStatuses = ["DemoUserWithOa", "DemoUser", "LiveUser", "ApplicationAlreadySubmitted"];
    return allowedStatuses.indexOf(applicationStore.customerStatus.UserStatus) != -1;
});

watch(showRadioButtons, (newValue, oldValue) => {
    if (newValue && props.isDemoRegistration) {
        uiStore.disableSubmitButton();
    } else {
        uiStore.enableSubmitButton();
    }
});
</script>
