import Cookies from 'js-cookie'

function setCookie(key,value) {
    var topLevelDomain = getDomain();
    Cookies.set(key, value, { path: '/', domain: topLevelDomain  });
}

function getCookie(key) {
    return Cookies.get(key);
}

const cookies =  {
    set: setCookie,
    get: getCookie
}

function getDomain() {
    const hostname = window.location.hostname;

    if (hostname === "localhost" || hostname === "127.0.0.1") {
        return "localhost";
    }

    const parts = hostname.split('.').reverse();

    if (parts.length >= 2) {
        return parts[1] + '.' + parts[0];
    }

    return hostname;
}

export default cookies;
