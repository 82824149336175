<template>
    <div class="notification tin-notification" v-if="showDisableValidation" :class="[isWarning ? 'notification-error' : 'notification-info']">
        <div class="warning-wrapper">
            <div class="notification-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-solid fa-triangle-exclamation">
                    <path
                        d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                </svg>
            </div>
            <div v-html="$t('tin_incorrect_lbl')"></div>
        </div>
        <hr />
        <AppCheckboxOptional @on-checkbox-selected="toggleValidation" :question="question" v-if="showDisableValidation" :receiverIndex="receiverIndex"></AppCheckboxOptional>
    </div>
</template>
<script>
import AppCheckboxOptional from "@/components/oa/AppCheckboxOptional.vue";
import eventBus from "@/eventBus";

import { useUiStore } from "@/store/interface.js";
import { mapStores } from "pinia";

export default {
    name: "AppUserValidation",
    props: ["question", "groupIndex"],
    components: {
        AppCheckboxOptional,
    },
    created() {
        eventBus.$on(`on-tin-validation-state-changed-${this.receiverIndex}`, (state) => {
            this.showDisableValidation = !state;
            if (state && this.question.SelectedAnswers[0] === 0) {
                this.question.SelectedAnswers[0] = this.question.Answers[0].Id;
            }
        });
    },
    data() {
        return {
            showDisableValidation: false,
            isWarning: true,
        };
    },
    watch: {
        showDisableValidation(newValue) {
            if (!newValue) {
                this.uiStore.removeError(this.question.FieldName);
                return;
            }

            this.uiStore.addError(this.question.FieldName);
        },
    },
    methods: {
        toggleValidation(newValue) {
            if (newValue) {
                this.uiStore.removeError(this.question.FieldName);
                this.isWarning = false;
                return;
            }

            this.uiStore.addError(this.question.FieldName);
            this.isWarning = true;
        },
    },
    computed: {
        ...mapStores(useUiStore),
        receiverIndex() {
            return this.groupIndex || 1;
        },
    },
};
</script>
<style lang="scss" scoped>
.notification {
    display: block;
}
.warning-wrapper {
    display: flex;
}
hr {
    height: 0;
    border: none;
    margin: 0.75rem 0 0.75rem calc(1.5rem + 0.875rem);
    opacity: 0.25;
}
.notification-info hr {
    border-bottom: 1px solid var(--notification-info-text);
    opacity: 1;
}
.notification-error hr {
    border-bottom: 1px solid var(--notification-error-text);
    opacity: 1;
}
</style>
