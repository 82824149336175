<template>
    <div :class="{ 'currency-container': isAmountField }">
        <div v-if="isAmountField" class="currency">{{ amountCurrency }}</div>
        <div class="currency-value">
            <div class="form-field relative">
                <Field :name="question.FieldName" type="hidden" v-model="model" :type="fieldType"></Field>
                <div :class="{ 'cleave-container': isAmountField }">
                    <cleave
                        :type="fieldType"
                        class="form-control"
                        :options="cleaveOptions"
                        :class="{ 'is-invalid': errors[question.FieldName] }"
                        v-model="model"
                        v-bind:placeholder="$t(question.TextKey)"
                        v-on:focus="this.showHint = true"
                        v-on:blur="this.showHint = false"
                        :raw="passRaw"></cleave>
                    <label class="small-label">{{ $t(question.TextKey) }}</label>
                    <label class="example" v-if="showHint">{{ $t(question.HintKey) }}</label>
                </div>
                <ErrorMessage v-if="!isAmountField" v-bind:name="question.FieldName" />
            </div>
        </div>
    </div>
    <ErrorMessage v-if="isAmountField" v-bind:name="question.FieldName" />
</template>

<script>
import cleave from "vue-cleave-component";

export default {
    name: "AppCleave",
    props: ["question", "errors"],
    components: {
        cleave,
    },
    beforeMount() {
        var displayType = this.question.DisplayType;
        if (displayType == "Amount") {
            var self = this;

            this.question.Answers[0].Value = this.question.Answers[0].Value;
            this.amountData = this.question.Answers[0].Value;

            this.cleaveOptions = {
                numeral: true,
                numeralThousandsGroupStyle: "thousand",
                numeralDecimalMark: ".",
                delimiter: " ",
                numeralPositiveOnly: true,
                numeralDecimalScale: 2,
                onValueChanged: (e) => {
                    self.question.Answers[0].Value = this.amountCurrency + " " + e.target.rawValue;
                },
            };

            this.fieldType = "tel";
        }
        if (displayType == "Date") {
            this.cleaveOptions = {
                date: true,
                delimiter: "/",
                datePattern: ["d", "m", "Y"],
            };
            this.passRaw = false;

            this.fieldType = "tel";
        }
        if (displayType == "Tin") {
            this.cleaveOptions = {
                delimiters: [".", ".", "-"],
                blocks: [3, 3, 3, 2],
                uppercase: true,
            };
        }
        if (displayType == "PassportNumber") {
            this.cleaveOptions = {
                blocks: [8],
            };
        }
        if (displayType == "Postcode") {
            this.cleaveOptions = {
                delimiters: ["-"],
                blocks: [5, 3],
                numericOnly: true,
            };
            this.passRaw = false;
        }
        if (displayType == "Cnpj") {
            this.cleaveOptions = {
                delimiters: [".", ".", "/", "-"],
                blocks: [2, 3, 3, 4, 2],
                numericOnly: true,
            };
            this.passRaw = false;
        }
    },
    mounted() {},
    data() {
        return {
            options: {},
            cleaveOptions: {},
            passRaw: true,
            amountData: "",
            fieldType: "text",
            showHint: false,
        };
    },
    computed: {
        showHint() {
            return this.question.Answers[0] && !this.question.Answers[0].Value.length && this.showHint && this.question.HintKey && this.question.DisplayType === "Date";
        },
        isAmountField() {
            return this.question.DisplayType == "Amount";
        },
        amountCurrency() {
            return this.question.CurrencySymbol;
        },
        model: {
            get() {
                return this.isAmountField ? this.amountData : this.question.Answers[0].Value;
            },
            set(newValue) {
                if (this.isAmountField) {
                    this.amountData = newValue;
                    this.question.Answers[0].Value = this.amountCurrency + " " + newValue;
                } else {
                    this.question.Answers[0].Value = newValue;
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.example {
    position: absolute;
    top: 1px;
    left: 1px;
    line-height: 1.2;
    padding: 1.5rem var(--form-control-padding) 0.625rem;
    font-size: var(--form-control-font-size);
    letter-spacing: -0.4px;
    color: var(--font-tertiary);
    pointer-events: none;
}

.rtl .example {
    left: auto;
    right: 0;
}

.currency {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: var(--form-control-bgr);
    color: var(--font-tertiary);
    border: 1px solid var(--border-main);
    border-right: none;
    border-top-left-radius: var(--border-radius-main);
    border-bottom-left-radius: var(--border-radius-main);
    font-size: var(--form-control-font-size);
    line-height: 1.2;
    padding: 12px var(--form-control-padding);
}
.rtl .currency {
    border-left: none;
    border-right: 1px solid var(--border-main);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--border-radius-main);
    border-bottom-right-radius: var(--border-radius-main);
}
.currency-value {
    flex-grow: 1;
    flex-shrink: 1;
}
.cleave-container input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.rtl .cleave-container input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: var(--border-radius-main);
    border-bottom-left-radius: var(--border-radius-main);
    direction: ltr;
    text-align: right;
}
.currency-container {
    display: flex;
    max-width: 100%;
    margin-bottom: 0.75rem;
}
.currency-container .form-field {
    width: 100%;
    margin-bottom: 0;
}
</style>
