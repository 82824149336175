<template>
    <MainLayout>
        <template #header>
            <Header :showProgressbar="false" :showArrows="false" :showCloseButton="true">
                <template #closeBtn>
                    <ExitPopup @on-exit-clicked="demoStore.exit"></ExitPopup>
                </template>
            </Header>
        </template>
        <div class="home-layout demo" :class="customClass">
            <div class="application flex-center">
                <!-- start loader -->
                <UILoader v-if="demoStore.isLoading" :delay="0"></UILoader>
                <!-- end loader -->
                <SomethingWentWrongView v-else-if="demoStore.submissionFailed"></SomethingWentWrongView>
                <div v-else class="flex-center">
                    <div
                        v-if="demoStore.step != null"
                        class="flex-center"
                        :class="{ loading: demoStore.isLoading }"
                    >
                        <div v-if="isDynamicComponent" class="max-100">
                            <component
                                v-bind:is="demoStore.step.DisplayType"
                                v-bind:nextButtonKey="demoStore.step.NextButtonKey"
                                @on-exit-clicked="demoStore.exit"
                            ></component>
                        </div>
                        <div v-else class="flex-center">
                            <div class="animation">
                                <div class="full-width">
                                    <Form
                                        @submit="demoStore.submit"
                                        :validation-schema="demoStore.validationSchema"
                                        v-slot="{ errors }"
                                        ref="form"
                                        autocomplete="off"
                                    >
                                        <div class="flex-switch">
                                            <div>
                                                <h1
                                                    v-if="demoStore.step.TitleKey"
                                                    v-bind:class="{
                                                        'has-subtitle': demoStore.step.SubtitleKey,
                                                    }"
                                                >{{ t(demoStore.step.TitleKey) }}</h1>

                                                <p
                                                    v-if="demoStore.step.SubtitleKey"
                                                    class="lead"
                                                    v-html="t(demoStore.step.SubtitleKey)"
                                                ></p>
                                            </div>
                                            <div class="max-100">
                                                <div class="form">
                                                    <Questions
                                                        :questions="demoStore.step.Questions"
                                                        :errors="errors"
                                                        :allowInlineElements="true"
                                                        :isDemoRegistration="true"
                                                    ></Questions>
                                                    <div
                                                        class="policy"
                                                        v-show="privacyPolicyUrl"
                                                        v-html="t('gdpr_pre_disclaimer', [privacyPolicyUrl])"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            id="submit_button"
                                            type="submit"
                                            v-if="demoStore.step.NextButtonKey != null && 
                                            demoStore.step.NextButtonKey != '' && 
                                            (applicationStore.customerStatus.length == 0 || 
                                            (applicationStore.customerStatus.UserStatus != '' && 
                                            applicationStore.customerStatus.UserStatus == 'NewUser'))"
                                            :disabled="uiStore.isSubmitButtonDisabled"
                                        >{{ t(demoStore.step.NextButtonKey) }}</button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script setup>
import Questions from "@/views/onlineapplication/questions/Questions.vue";
import UILoader from "@/components/ui/UILoader.vue";
import ExitPopup from "@/layout/ExitPopup.vue";
import Header from "@/layout/Header.vue";
import SomethingWentWrongView from "@/views/SomethingWentWrongView.vue";
import { Form } from "vee-validate";
import { useDemoStore } from "@/store/demo.js";
import { useLinksStore } from "@/store/links.js";
import { useCommonStore } from "@/store/common.js";
import { useUiStore } from "@/store/interface.js";
import { computed, onBeforeMount, ref } from "vue";
import MainLayout from "@/layout/MainLayout.vue";
import { CorporatePage } from "@/models/CorporatePage.js";
import i18n from "@/i18n.js";
import { useApplicationStore } from "@/store/onlineapplication.js";

const { t } = i18n.global;
const demoStore = useDemoStore();
const linksStore = useLinksStore();
const commonStore = useCommonStore();
const uiStore = useUiStore();
const privacyPolicyUrl = ref("");
const applicationStore = useApplicationStore();

onBeforeMount(async () => {
    await demoStore.initialize();
    privacyPolicyUrl.value = await linksStore.getLinkForBranch(CorporatePage.Privacy, commonStore.companyBranch);
});

const isDynamicComponent = computed(() => {
    return ["DemoRegistrationFinished"].includes(demoStore.step.DisplayType);
});

const customClass = computed(() => {
    switch (demoStore.step.DisplayType) {
        case "DemoRegistrationFinished":
            return "section-page";
        default:
            return "";
    }
});
</script>

<script>
import DemoRegistrationFinished from "@/views/demo/DemoRegistrationFinished.vue";
export default {
    components: {
        DemoRegistrationFinished,
    },
};
</script>

<style lang="scss" scoped>
.home-layout {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    flex-grow: 1;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.rtl ul {
    text-align: right;
}

li {
    display: flex;
    align-items: center;
    font-size: clamp(1.25rem, 3vmin, 1.5rem);
    margin-bottom: clamp(0.625rem, 3vmin, 1.875rem);
}

li span {
    line-height: 1.4;
}

.check {
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 2.5rem;
    vertical-align: middle;
    text-align: center;
}

.rtl .check {
    margin-left: 0.938rem;
    margin-right: 0;
}

li .check .fa-svg {
    font-size: 2.5rem;
    fill: var(--font-main);
}

.form {
    position: relative;
    max-width: 100%;
    margin: 0;
    text-align: left;
    width: 100%;
}

.rtl .form {
    text-align: right;
}

.form button[type="submit"] {
    width: 100%;
}

.start-new-oa {
    text-align: center;
    padding: 20px 0;
}

.disabled {
    background-color: #394965;
    color: #697b9a;
    pointer-events: none;
}

.demo .application,
.demo .flex-center,
.demo .max-100 {
    height: 100%;
}
</style>
