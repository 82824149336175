<template>
    <div class="form-field" v-if="question != null">
        <Field
            :name="question.FieldName"
            :type="fieldType"
            class="form-control"
            :class="{ 'is-invalid': errors[question.FieldName] }"
            v-model="question.Answers[0].Value"
            v-on:focus="onFocus = true"
            v-on:blur="onFocus = false"
            v-bind:placeholder="$t(passwordLabel)"></Field>

        <label class="small-label">{{ $t(passwordLabel) }}</label>
        <div class="dropdown" v-if="onFocus && !isCorrectPassword">
            <ul>
                <li v-bind:class="{ green: containSmallLetter }">
                    <span>{{ $t("at_least_one_latin_small_letter") }}</span>
                    <span class="tick"></span>
                </li>
                <li v-bind:class="{ green: containCapitalLetter }">
                    <span>{{ $t("at_least_one_latin_capital_letter") }}</span>
                    <span class="tick"></span>
                </li>
                <li v-bind:class="{ green: containDigit }">
                    <span>{{ $t("at_least_one_digit") }}</span>
                    <span class="tick"></span>
                </li>
                <li v-bind:class="{ green: atLeastSixChars }">
                    <span>{{ $t("at_least_eight_characters") }}</span>
                    <span class="tick"></span>
                </li>
                <li v-bind:class="{ green: atLeastOneSpecialChar }">
                    <span>{{ $t("at_least_one_special_character") }}</span>
                    <span class="tick"></span>
                </li>
            </ul>
        </div>

        <span v-if="showPassword" @click="displayPassword(false)" class="eye">
            <i>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="fa-svg fa-thin fa-eye">
                    <path
                        d="M106.9 384.5C62.9 342 32.6 291.4 17.3 256c15.2-35.4 45.5-86 89.6-128.5C153.5 82.7 214.2 48 288 48s134.5 34.7 181 79.5c44.1 42.5 74.4 93.1 89.6 128.5c-15.2 35.4-45.5 86-89.6 128.5C422.5 429.3 361.8 464 288 464s-134.5-34.7-181.1-79.5zM288 480c158.4 0 258-149.3 288-224C546 181.3 446.4 32 288 32S30 181.3 0 256c30 74.7 129.6 224 288 224zM192 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm208 0a112 112 0 1 0 -224 0 112 112 0 1 0 224 0z" />
                </svg>
            </i>
        </span>
        <span v-else @click="displayPassword(true)" class="eye">
            <i>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="fa-svg fa-thin fa-eye-slash">
                    <path
                        d="M13 1.7C9.5-1 4.5-.4 1.7 3S-.4 11.5 3 14.3l624 496c3.5 2.7 8.5 2.2 11.2-1.3s2.2-8.5-1.3-11.2L13 1.7zM605.5 268.3c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-47.8 0-90.1 12.9-126.3 32.5l13.7 10.9C240.3 58.7 277.9 48 320 48c75.6 0 136.6 34.4 181.7 76.3c44.9 41.7 75 91.7 89.1 125.6c1.6 3.9 1.6 8.4 0 12.3C581.7 284 566 312.4 544 341.1l12.6 9.9c23-29.9 39.4-59.7 49-82.7zM83.5 161c-23 29.9-39.4 59.7-49 82.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 90.1-12.9 126.3-32.5l-13.7-10.9C399.7 453.3 362.1 464 320 464c-75.6 0-136.6-34.4-181.7-76.3C93.4 346 63.3 296 49.2 262.2c-1.6-3.9-1.6-8.4 0-12.3C58.3 228 74 199.6 96 170.9L83.5 161zM320 368c7.8 0 15.4-.8 22.7-2.3l-17.5-13.8c-1.7 .1-3.5 .1-5.2 .1c-47.2 0-86.4-34-94.5-78.9L208 259.4C209.8 319.7 259.3 368 320 368zm0-224c-7.8 0-15.4 .8-22.7 2.3l17.5 13.8c1.7-.1 3.5-.1 5.2-.1c47.2 0 86.4 34 94.5 78.9L432 252.6C430.2 192.3 380.7 144 320 144z" />
                </svg>
            </i>
        </span>
    </div>
    <ErrorMessage v-bind:name="question.FieldName" />

    <UIPasswordReset v-show="showPasswordReset"></UIPasswordReset>
</template>

<script>
import eventBus from "@/eventBus";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";

export default {
    name: "UIPassword",
    props: ["question", "errors"],
    activated() {
        this.question.Answers[0].Value = "";
    },
    data() {
        return {
            showPassword: false,
            onFocus: false,
            defaultLabel: "",
            passwordLabel: "",
        };
    },
    mounted() {
        var self = this;
        self.passwordLabel = self.question.TextKey;
        self.defaultLabel = self.question.TextKey;

        eventBus.$off();
        eventBus.$on("ChangePasswordLabelEvent", (translationKey) => {
            self.passwordLabel = translationKey != null && translationKey != "default" ? translationKey : self.defaultLabel;
        });
    },
    methods: {
        displayPassword(result) {
            this.showPassword = result;
        },
    },
    computed: {
        ...mapStores(useApplicationStore),
        fieldType() {
            return this.showPassword ? "text" : "password";
        },
        passwordFilled() {
            return this.questionValue != null && this.questionValue.trim() != "";
        },
        containSmallLetter() {
            return this.passwordFilled && this.questionValue.match(/[a-z]+/) != null;
        },
        containCapitalLetter() {
            return this.passwordFilled && this.questionValue.match(/[A-Z]+/) != null;
        },
        containDigit() {
            return this.passwordFilled && this.questionValue.match(/[0-9]+/) != null;
        },
        atLeastSixChars() {
            return this.passwordFilled && this.questionValue != null && this.questionValue.length >= 8;
        },
        atLeastOneSpecialChar() {
            return this.passwordFilled && this.questionValue.match(/[^a-zA-Z0-9]+/) != null;
        },
        questionValue() {
            return this.question.Answers[0].Value;
        },
        isCorrectPassword() {
            return (
                this.questionValue != null &&
                this.passwordFilled &&
                this.containSmallLetter &&
                this.containCapitalLetter &&
                this.atLeastSixChars &&
                this.containDigit &&
                this.atLeastOneSpecialChar
            );
        },
        showPasswordReset() {
            return this.applicationStore.serverSideErrors?.Password?.length;
        },
    },
};
</script>

<style lang="scss" scoped>
.eye {
    position: absolute;
    top: 50%;
    right: var(--form-control-padding);
    transform: translateY(-50%);
    text-align: center;
    width: 2.25rem;
}
.rtl .eye {
    left: var(--form-control-padding);
    right: auto;
}

.eye .fa-svg {
    font-size: 1.625rem;
    fill: var(--font-main);
    cursor: pointer;
}

.dropdown {
    height: auto;
}

.dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 9px 0;
}

.dropdown li {
    padding: 0.313rem var(--form-control-padding);
    color: var(--font-main);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown li.green {
    color: var(--accent);
}

.dropdown li .tick {
    display: none;
    border-color: var(--accent);
    margin-left: 0.625rem;
    flex-shrink: 0;
    flex-grow: 0;
}
.rtl .dropdown li .tick {
    margin-right: 0.625rem;
    margin-left: 0;
}

.dropdown li.green .tick {
    display: inline-block;
}
</style>
