import http from "@/backend/vue-axios/axios";

class DemoService {
    start(lang, platform) {
        let endpoint = DemoService._getStartUrl(lang);
        if (!endpoint.includes("uiplatform")) {
            endpoint = `${endpoint}&uiplatform=${platform}`;
        }
        return http.get(endpoint);
    }

    submit(payload) {
        return http.post("/v1/demo/start", payload);
    }

    createOrUpdateActivTradesDemo(oaReference) {
        return http.post("/v1/demo/createorupdateatdemoinoa", { oaReference });
    }

    static _getStartUrl(lang) {
        let endpoint = "/v1/demo/start";
        if (!window.location.search) {
            return `${endpoint}?lang=${lang}`;
        }

        if (!window.location.search.includes("lang")) {
            return `${endpoint}${window.location.search}&lang=${lang}`;
        }

        return `${endpoint}${window.location.search}`;
    }
}

export default new DemoService();
