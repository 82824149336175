import http from "@/backend/vue-axios/axios";

class UploadFileService {
  uploadBase64(urlConstant, data) {
    try {
        return http.post(urlConstant, data);
      } catch (e) {
        console.log('FAILURE!!');
    }
  }
}

export default new UploadFileService();