import http from "@/backend/vue-axios/axios";
import endpoints from "@/common/endpoints";
import PostStepModel from "@/models/PostStepModel";

class OnlineApplicationService {
    start(lang) {
        const endpoint = OnlineApplicationService._getStartUrl(lang);
        return http.get(endpoint);
    }

    submit(oaStep) {
        var model = new PostStepModel(oaStep).createFromStep();
        return http.post(endpoints.SubmitStepUrl, model);
    }

    getNextStepBackend(oaStep) {
        var model = new PostStepModel(oaStep).createFromStep();
        return http.post(endpoints.GetNextStepUrl, model);
    }

    getPreviousStepBackend(step) {
        return http.post(endpoints.GetPreviousStepUrl, step.Id);
    }

    close() {
        return http.get(endpoints.CloseOA);
    }

    getStepById(stepId) {
        return http.get(endpoints.GetStepByIdUrl + "?id=" + stepId);
    }

    getCustomerStatus(email) {
        //Todo see why post is passed as url
        return http.post(endpoints.GetCustomerStatusUrl + "?email=" + email);
    }

    resetPassword(email) {
        return http.post("v1/Accounts/PasswordRecovery", { email: email });
    }

    skipStep(skippedStepId, skipToStepId) {
        const payload = {
            skippedStepId,
            skipToStepId,
        };
        return http.post(endpoints.SkipStepUrl, payload);
    }

    onfidoTest(data) {
        return http.post(endpoints.OnfidoTestUrl, data);
    }

    static _getStartUrl(lang) {
        let endpoint = endpoints.StartOAUrl;
        if (!window.location.search) {
            return `${endpoint}?lang=${lang}`;
        }

        if (!window.location.search.includes("lang")) {
            return `${endpoint}${window.location.search}&lang=${lang}`;
        }

        return `${endpoint}${window.location.search}`;
    }
}

export default new OnlineApplicationService();
