import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useUiStore } from "@/store/interface";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { useLanguagesStore } from "@/store/languages.js";
import { useCommonStore } from "@/store/common.js";
import { broadcastEvent } from "@/common/broadcaster.js";
import EnOrigin from "@/enums/EnOrigin";
import { EnEvent } from "@/enums/EnEvent";
import Validator from "@/common/validator";
import DemoService from "@/services/demo.service.js";
import AuthService from "@/services/auth.service.js";

export const useDemoStore = defineStore("demo", () => {
    const uiStore = useUiStore();
    const applicationStore = useApplicationStore();
    const languagesStore = useLanguagesStore();
    const commonStore = useCommonStore();
    const step = ref({});
    const isLoading = ref(false);
    const validationSchema = ref({});
    const oaReference = ref(null);
    const memberId = ref(null);
    const submissionFailed = ref(false);

    async function exit() {
        if (uiStore.origin === EnOrigin.Unspecified) {
            let returnUrl = import.meta.env.VITE_PA_URL;
            if (step.value.DisplayType === "DemoRegistrationFinished") {
                const loginKeyResponse = await AuthService.getPersonalAreaLoginLink(memberId.value);
                if (loginKeyResponse.status === 200) {
                    returnUrl = loginKeyResponse.data;
                }
            }

            window.location = returnUrl;
            return;
        }

        if (step.value.DisplayType === "DemoRegistrationFinished") {
            const event = {
                type: EnEvent.DemoUserRegistered,
                loginKey: await applicationStore.getLoginKey(memberId.value),
                memberId: memberId.value,
                oaReference: oaReference.value,
            };

            broadcastEvent(uiStore.origin, event);
            return;
        }

        broadcastEvent(uiStore.origin, { type: EnEvent.OaNotStarted });
    }

    async function initialize() {
        isLoading.value = true;
        const result = await DemoService.start(languagesStore.preferedLanguageShortName, commonStore.platform);
        step.value = result.data.CurrentStep;
        commonStore.companyBranch = result.data.CompanyBranch;
        validationSchema.value = Validator.CreateYupSchema(result.data.CurrentStep.Questions);

        isLoading.value = false;
    }

    async function submit() {
        try {
            isLoading.value = true;
            const isPlatformValid = commonStore.platform.toLowerCase() === "mt4" || commonStore.platform.toLowerCase() === "mt5" || commonStore.platform.toLowerCase() == "at";

            const platform = isPlatformValid ? commonStore.platform : "AT";
            const payload = {
                StepId: step.value.Id,
                Questions: step.value.Questions.map((x) => {
                    if (!isPlatformValid && x.UniqueKey == "Q_PreferredPlatform_Hidden") {
                        const answer = x?.Answers.find((y) => y.Value == "AT");
                        x.SelectedAnswers[0] = answer.Id;
                    }

                    if (x.Answers.length > 1) {
                        x.Answers = x.Answers.filter((y) => {
                            return x.SelectedAnswers.find((id) => id == y.Id);
                        });
                    }

                    return {
                        Id: x.Id,
                        Answers: x.Answers.map((a) => {
                            return { Id: a.Id, QuestionId: a.QuestionId, Value: a.Value, IsExternalAnswer: a.IsExternalAnswer };
                        }),
                        SelectedAnswers: x.SelectedAnswers,
                        IsVisible: x.IsVisible,
                        FieldType: x.FieldType,
                        DisplayType: x.DisplayType,
                    };
                }),
                CompanyBranch: commonStore.companyBranch,
                LanguageId: languagesStore.preferedLanguage.Id,
                Platform: platform,
            };

            const result = await DemoService.submit(payload);
            if (result.status > 201) {
                submissionFailed.value = true;
                isLoading.value = false;
                return;
            }

            step.value = result.data.CurrentStep;
            oaReference.value = result.data.OaReference;
            memberId.value = result.data.MemberId;

            isLoading.value = false;
        } catch (error) {
            submissionFailed.value = true;
            isLoading.value = false;
        }
    }
    return {
        initialize,
        exit,
        validationSchema,
        step,
        submit,
        isLoading,
        submissionFailed,
    };
});
