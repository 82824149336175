<template>
    <div class="form-field">
        <Field
            :name="question.FieldName"
            type="email"
            class="form-control"
            :class="{ 'is-invalid': errors[question.FieldName] }"
            v-model="question.Answers[0].Value"
            v-bind:placeholder="$t(question.TextKey)"
            @change="onChange"
            @blur="blur">
        </Field>

        <label class="small-label">{{ $t(question.TextKey) }}</label>
        <i v-if="emailFilled && !loading" @click="clear" class="clear">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fa-svg fa-thin fa-xmark-large">
                <path
                    d="M13.7 34.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L212.7 256 2.3 466.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0L224 267.3 434.3 477.7c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L235.3 256 445.7 45.7c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L224 244.7 13.7 34.3z" />
            </svg>
        </i>
        <i v-if="loading" class="clear">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-thin fa-loader fa-spin">
                <path
                    d="M264 8V96c0 4.4-3.6 8-8 8s-8-3.6-8-8V8c0-4.4 3.6-8 8-8s8 3.6 8 8zm0 408v88c0 4.4-3.6 8-8 8s-8-3.6-8-8V416c0-4.4 3.6-8 8-8s8 3.6 8 8zM-8 256c0-4.4 3.6-8 8-8H96c4.4 0 8 3.6 8 8s-3.6 8-8 8H0c-4.4 0-8-3.6-8-8zm424-8h88c4.4 0 8 3.6 8 8s-3.6 8-8 8H416c-4.4 0-8-3.6-8-8s3.6-8 8-8zM435.8 77.8c3.1 3.1 3.1 8.2 0 11.3l-62.2 62.2c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3l62.2-62.2c3.1-3.1 8.2-3.1 11.3 0zM147.3 377.6L85.1 439.8c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L136 366.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3zM68.2 72.2c3.1-3.1 8.2-3.1 11.3 0L147.3 140c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L68.2 83.5c-3.1-3.1-3.1-8.2 0-11.3zM373.6 366.3l62.2 62.2c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0l-62.2-62.2c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0z" />
            </svg>
        </i>
        <ErrorMessage v-bind:name="question.FieldName" />

        <UINewCustomerStatus
            :customerStatus="applicationStore.customerStatus"
            :isDemoRegistration="isDemoRegistration"
            @on-login-clicked="handleLoginClicked"></UINewCustomerStatus>
    </div>
</template>

<script>
import oaService from "@/services/onlineapplication.service";
import UINewCustomerStatus from "@/components/ui/email/UINewCustomerStatus.vue";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { useUiStore } from "@/store/interface.js";
import { mapStores } from "pinia";
import eventBus from "@/eventBus";

import { broadcastEvent } from "@/common/broadcaster.js";
import { EnEvent } from "@/enums/EnEvent";
import EnOrigin from "@/enums/EnOrigin";
import endpoints from "@/common/endpoints";

export default {
    name: "UIEmailInput",
    props: ["question", "errors", "isDemoRegistration"],
    components: {
        UINewCustomerStatus,
    },
    activated() {
        this.question.Answers[0].Value = "";
    },
    data: function () {
        return {
            onFocus: false,
            loading: false,
        };
    },
    mounted() {
        if (this.question != null && (this.question != this.question.Answers) != null && this.question.Answers[0].Value.trim() != "") {
            this.blur();
        }
    },
    methods: {
        clear() {
            this.question.Answers[0].Value = "";
        },
        onChange() {
            eventBus.$emit("email-changed");
        },
        blur() {
            var self = this;
            if (this.question.DisplayType == "UniqueEmail" && this.emailFilled) {
                self.loading = true;
                oaService.getCustomerStatus(this.question.Answers[0].Value).then((result) => {
                    self.applicationStore.changeCustomerStatus(result.data);
                    self.loading = false;
                });
            }
        },
        handleLoginClicked() {
            if (!this.uiStore.origin || this.uiStore.origin === EnOrigin.Unspecified) {
                window.location = endpoints.PersonalAreaHomePageUrl;
                return;
            }

            broadcastEvent(this.uiStore.origin, { type: EnEvent.Login });
        },
    },
    computed: {
        ...mapStores(useApplicationStore, useUiStore),
        emailFilled() {
            return !!this.question.Answers[0].Value;
        },
    },
};
</script>

<style lang="scss" scoped>
.loader {
    height: 1.85rem;
}
.loader img {
    height: 100%;
}

.clear {
    position: absolute;
    top: 0.625rem;
    right: var(--form-control-padding);
    cursor: pointer;
    color: var(--font-main);
    fill: var(--font-main);
    line-height: 1.875rem;
    padding: 0;
    width: 2.25rem;
    font-size: 1.625rem;
    text-align: center;
    font-weight: 300;
}
.rtl .clear {
    left: var(--form-control-padding);
    right: auto;
}

.fa-loader {
    font-size: 1.444rem;
}
</style>
