<template></template>
<script>
import { useLanguagesStore } from "@/store/languages.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import eventBus from "@/eventBus";

export default {
    name: "AppChatIntercom",
    props: {
        fullName: String,
        email: String,
    },
    computed: {
        ...mapStores(useLanguagesStore, useApplicationStore),
    },
    mounted() {
        let alignment = "right"; //this.languagesStore.preferedLanguageShortName === "ae" ? "left" : "right";
        window.intercomSettings = {
            api_base: import.meta.env.VITE_INTERCOM_BASE_URL,
            app_id: import.meta.env.VITE_INTERCOM_APP_ID,
            alignment: alignment,
            Branch: this.applicationStore ? this.applicationStore.getBranch() : "uk",
            ui_language: this.languagesStore ? this.languagesStore.preferedLanguageShortName : "en",
        };

        if (this.fullName) {
            window.intercomSettings.name = this.fullName;
        }

        if (this.email) {
            window.intercomSettings.email = this.email;
        }

        this.loadWidget();
        window.onclick_livesupport = function () {
            window.Intercom("showNewMessage");
        };

        eventBus.$on("change-language", (lang) => {
            if (window.Intercom.booted && window.intercomSettings) {
                const alignment = "right"; //lang.toLowerCase() === "ae" ? "left" : "right";
                if (window.intercomSettings.alignment !== alignment) {
                    window.intercomSettings.alignment = alignment;
                    window.Intercom("update");
                }
            }
        });
    },
    beforeUnmount() {
        if (window.Intercom) {
            window.Intercom("shutdown");
        }
    },
    methods: {
        loadWidget() {
            (function () {
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === "function") {
                    ic("reattach_activator");
                    ic("update", w.intercomSettings);
                } else {
                    var d = document;
                    var i = function () {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function (args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    var l = function () {
                        var s = d.createElement("script");
                        s.type = "text/javascript";
                        s.async = true;
                        s.src = "https://widget.intercom.io/widget/" + import.meta.env.VITE_INTERCOM_APP_ID;
                        var x = d.getElementsByTagName("script")[0];
                        x.parentNode.insertBefore(s, x);
                    };
                    if (document.readyState === "complete") {
                        l();
                    } else if (w.attachEvent) {
                        w.attachEvent("onload", l);
                    } else {
                        w.addEventListener("load", l, false);
                    }
                }
            })();
        },
    },
};
</script>

<style lang="scss">
.intercom-namespace .intercom-dfosxs {
    right: 12px;
    bottom: 35px;
}
.intercom-lightweight-app-launcher {
    bottom: 35px !important;
}
</style>
