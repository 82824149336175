<template>
    <div :dir="isMoneyDropdown ? 'ltr' : ''">
        <div class="form-field">
            <div class="relative-pos">
                <Field :name="fieldName" type="hidden" v-model="question.SelectedAnswers[0]"> </Field>
                <input
                    type="text"
                    class="form-control"
                    :class="{
                        'float-right': isMoneyDropdown && languagesStore.preferedLanguageShortName === 'ae',
                        'is-invalid': errors[fieldName],
                    }"
                    :placeholder="placeHolder"
                    @focus="defaultFocus"
                    @click="toggle"
                    @blur="blur"
                    :value="searchText"
                    @input="(e) => (searchText = e.target.value)"
                    ref="dropdownInput"
                    autocomplete="new-password"
                    :disabled="isDisabled()" />

                <label class="small-label">{{ $t(question.TextKey) }}</label>
                <span class="dropdown-arrow" @click="toggle">
                    <i v-if="!displayDropdown">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-thin fa-chevron-down">
                            <path
                                d="M250.3 397.7c3.1 3.1 8.2 3.1 11.3 0l216-216c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L256 380.7 45.7 170.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l216 216z" />
                        </svg>
                    </i>
                    <i v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-svg fa-thin fa-chevron-up">
                            <path
                                d="M250.3 114.3c3.1-3.1 8.2-3.1 11.3 0l216 216c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L256 131.3 45.7 341.7c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3l216-216z" />
                        </svg>
                    </i>
                </span>
            </div>
            <div v-if="displayDropdown" class="dropdown">
                <ul>
                    <li v-for="dropdown in filteredItems" v-bind:key="dropdown.Id">
                        <a href="javascript:void(0)" v-on:mousedown.prevent="select(dropdown)" v-bind:class="{ disabled: selectDisabled(dropdown.Id) }">
                            <span
                                v-text="
                                    dropdown.AdditionalData != null && dropdown.AdditionalData.TranslatedDropdown != null
                                        ? dropdown.AdditionalData.TranslatedDropdown
                                        : dropdown.TextKey != null
                                        ? $t(dropdown.TextKey)
                                        : ''
                                "></span>
                        </a>
                    </li>
                </ul>
            </div>
            <ErrorMessage v-bind:name="question.FieldName" />
        </div>
    </div>
</template>

<script>
import UIFlag from "@/components/ui/UIFlag.vue";
import eventBus from "@/eventBus";
import { useLanguagesStore } from "@/store/languages.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";

export default {
    name: "UiDropdown",
    props: ["question", "errors", "answerIndex", "multilineValidation", "disableOptions", "preSelectedItemId", "groupIndex"],
    emits: ["on-change-item", "change-continue-button-state"],
    components: {
        UIFlag,
    },
    data: function () {
        return {
            placeHolder: this.$t(this.question.TextKey),
            displayDropdown: false,
            currentAnswerIndex: 0,
            searchText: "",
            index: this.groupIndex,
            filteredAnswers: [],
        };
    },
    created() {
        if (this.question.SelectedAnswers[0] == 0) {
            this.question.SelectedAnswers[0] = null;

            if (this.disableOptions) {
                this.question.Answers = this.question.Answers.filter((answer) => !this.disableOptions.includes(answer.Id));
            }

            if (this.question.ActionType === "ShowBranchSpecificCurrencies") {
                let currency = this.question.Answers[0];
                var self = this;
                if (self.applicationStore.countryOfResidence && self.applicationStore.countryOfResidence.Currency) {
                    currency = this.question.Answers.find((x) => x.Value === self.applicationStore.countryOfResidence.Currency);
                }
                this.select(currency);
            }
        } else {
            const item = this.question.Answers.find((item) => item.Id == this.question.SelectedAnswers[0]);
            if (item) {
                this.select(item);
            }
        }

        this.handleCommuncationBetweenDropdowns();
    },
    beforeUnmount() {
        eventBus.$off(this.getCommunactionString());
    },
    beforeMount() {
        if (this.answerIndex != null) {
            this.currentAnswerIndex = this.answerIndex;
        }

        if (this.currentAnswerIndex && this.question.Answers[this.currentAnswerIndex] == null) {
            this.question.Answers.push({ Answer: "", Code: "" });
        }

        if (this.multilineValidation != null) {
            if (this.question.MultiLineValidation == null) {
                this.question.MultiLineValidation = [];
            }

            this.question.MultiLineValidation.push(this.fieldName);
        }
    },
    mounted() {
        var self = this;

        if (self.question.SelectedAnswers[0] != 0) {
            self.question.DefaultValue = self.question.SelectedAnswers[0];
        }

        this.selectDefaultItem();
    },
    computed: {
        ...mapStores(useLanguagesStore, useApplicationStore),
        currentSelectedItem() {
            if (this.question.SelectedAnswers[0] != null && this.question.SelectedAnswers[0] != 0) {
                var answer = this.question.Answers.filter((answer) => answer.Id == this.question.SelectedAnswers[0]);
                if (answer != null) {
                    return answer[0];
                }
            }

            return null;
        },

        currentSelectedItemName() {
            if (this.currentSelectedItem != null) {
                return this.currentSelectedItem.AdditionalData != null && this.currentSelectedItem.AdditionalData.TranslatedDropdown != null
                    ? this.currentSelectedItem.AdditionalData.TranslatedDropdown
                    : this.$t(this.currentSelectedItem.TextKey);
            }
            return null;
        },

        fieldName() {
            return this.multilineValidation != null ? this.question.FieldName + "_" + this.currentAnswerIndex : this.question.FieldName;
        },

        filteredItems() {
            var self = this;

            var filtered = [];
            if (self.filteredAnswers != null && self.filteredAnswers.length > 0) {
                var filtered = this.filterAnswers(self.filteredAnswers);
            } else {
                var filtered = this.filterAnswers(self.question.Answers);
            }

            return filtered;
        },
        isMoneyDropdown() {
            return this.question && (this.question.DropdownType === "YearlyIncomes" || this.question.DropdownType === "ValueOfSavings");
        },
    },
    methods: {
        selectDefaultItem() {
            var self = this;

            if (self.question.Answers.length == 1) {
                self.question.DefaultValue = self.question.Answers[0].Id;
            }

            if (self.question.DefaultValue != null && self.questionDefaultValue != 0) {
                const itemId = this.preSelectedItemId || self.question.DefaultValue;
                var item = self.question.Answers.find((item) => item.Id == itemId);
                if (item) {
                    self.select(item, true);
                }
            }
        },

        filterAnswers(answers) {
            var self = this;
            var filtered = [];
            if (answers.some((x) => x.AdditionalData != null)) {
                filtered = answers.filter((item) => item.AdditionalData.TranslatedDropdown.trim().toLowerCase().includes(self.searchText.toLowerCase()));
            } else {
                filtered = answers.filter((item) => this.$t(item.TextKey).trim().toLowerCase().includes(self.searchText.toLowerCase()));
            }

            return filtered;
        },

        defaultFocus() {
            this.placeHolder = "";
        },

        toggle() {
            if (this.displayDropdown) {
                this.$refs.dropdownInput.blur();
            } else {
                this.$refs.dropdownInput.focus();
                this.focus();
            }
        },

        focus() {
            this.searchText = "";

            this.question.PreviousItem = this.currentSelectedItem;

            this.placeHolder = this.currentSelectedItemName != null ? this.currentSelectedItemName : "";
            this.displayDropdown = true;
        },
        blur() {
            this.hideDropdown();
            if (this.searchText == null || this.searchText == "") {
                this.select(this.question.PreviousItem);
                return;
            }

            const typedItem = this.question.Answers.find((item) =>
                item.AdditionalData != null
                    ? item.AdditionalData.TranslatedDropdown.toLowerCase() == this.searchText.toLowerCase()
                    : this.$t(item.TextKey).toLowerCase() == this.searchText.toLowerCase()
            );

            if (typedItem) {
                this.select(typedItem);
                return;
            }

            this.select(this.question.PreviousItem);
            this.placeHolder = this.$t(this.question.TextKey);
        },
        select(item, skipPrefill) {
            if (item != null) {
                this.selectItem(item, skipPrefill);
            }
        },
        selectItem(item, skipPrefill) {
            this.searchText = item.AdditionalData != null ? item.AdditionalData.TranslatedDropdown : this.$t(item.TextKey);
            this.question.SelectedAnswers[0] = item.Id;
            item.IsExternalAnswer = true;

            this.hideDropdown();

            if (this.isBus()) {
                eventBus.$emit(this.getCommunactionString(), { item, skipPrefill });
            }

            if (this.index) {
                eventBus.$emit(`on-item-tim-change-${this.index}`, item);
            }
        },

        clear() {
            this.question.SelectedAnswers[0] = null;
            this.selectedValue = "";
            this.selected = "";
        },

        hideDropdown() {
            this.displayDropdown = false;
        },
        selectDisabled(itemId) {
            var answers = this.question.Answers.some((x) => x.Value == itemId) && this.question.Answers[this.currentAnswerIndex].Value != itemId;

            return answers;
        },
        isDisabled() {
            return this.question.DisplayType == "Readonly" ? "disabled" : false;
        },
        isBus() {
            return this.question.DisplayType.indexOf("Bus") > 0;
        },
        isReceiver() {
            return this.question.DisplayType.indexOf("Receiver") > 0;
        },

        handleCommuncationBetweenDropdowns() {
            var self = this;
            var dropdownType = this.getCommunactionString();

            if (self.isReceiver()) {
                self.filteredAnswers = [];

                eventBus.$on(this.getCommunactionString(), (data) => {
                    const { item, skipPrefill } = data;
                    switch (dropdownType) {
                        case "StatesCitiesDropdown":
                            self.busId = item.Id;
                            self.filteredAnswers = self.question.Answers.filter((x) => x.AdditionalData.StateId == self.busId);

                            if (skipPrefill || self.question.SelectedAnswers[0] != 0) {
                                break;
                            }

                            this.select(this.filteredAnswers[0]);
                            break;
                    }
                });
            } else {
                self.hidden = false;
            }
        },
        getCommunactionString() {
            return this.question.DisplayType.replace("Receiver", "").replace("Bus", "");
        },
    },
    watch: {
        searchText(newValue, oldValue) {
            if (newValue != null && oldValue != null && newValue.toLowerCase() !== oldValue.toLowerCase()) {
                this.question.PreviousItem = this.currentSelectedItem;

                this.placeHolder = this.currentSelectedItemName != null ? this.currentSelectedItemName : "";
            }
        },

        currentSelectedItemName(newValue, oldValue) {
            if (newValue?.toLowerCase() !== oldValue?.toLowerCase()) {
                this.hideDropdown();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.item .form-control {
    cursor: pointer;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.flag-container {
    width: 2.25rem;
    display: flex;
    justify-content: center;
}
.item-flag {
    position: absolute;
    top: 50%;
    left: var(--form-control-padding);
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    background: #f2f2f2;
    border: 1px solid var(--border-main);
    border-radius: 50%;
    overflow: hidden;
}
.rtl .item-flag {
    right: var(--form-control-padding);
    left: auto;
}
.item-flag > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item-flag.small {
    position: static;
    width: 1.5rem;
    height: 1.5rem;
    transform: initial;
}

.form-field.item .small-label,
.form-field.item input.has-flag {
    padding-left: 3.75rem;
}
.rtl .form-field.item .small-label,
.rtl .form-field.item input.has-flag {
    padding-right: 3.75rem;
    padding-left: 0;
}

.form-field.item .form-control:not(.is-invalid):focus + .small-label,
.form-field.item .form-control:not(.is-invalid):not(:placeholder-shown) + .small-label,
.form-field.item .form-control:-webkit-autofill:not(.is-invalid) + .small-label {
    padding-left: 3.75rem;
}

.rtl .form-field.item .form-control:not(.is-invalid):focus + .small-label,
.rtl .form-field.item .form-control:not(.is-invalid):not(:placeholder-shown) + .small-label,
.rtl .form-field.item .form-control:-webkit-autofill:not(.is-invalid) + .small-label {
    padding-right: 3.75rem;
    padding-left: 0;
}

input::-webkit-input-placeholder {
    color: transparent;
}

input:focus::-webkit-input-placeholder {
    color: #888;
}

.float-right {
    text-align: right;
}
</style>
