import yup from "./yup-pa";
import constants from "./constants";
import i18n from "@/i18n.js";

class Validator {
    constructor() {
        this.questions = [];
    }

    set Questions(questions) {
        this.questions = questions;
    }

    get GetSchema() {
        var result = this.CreateYupSchema(this.questions);
        return result;
    }

    get AddressSearchSchema() {
        const result = {};
        result[constants.SearchableAddressInputName] = yup.string().required(i18n.global.t("oa_search_function_error")).typeError(i18n.global.t("oa_search_function_error"));
        return yup.object().shape(result);
    }

    CreateYupSchema(questions) {
        var self = this;
        var result = {};

        if (questions == null || questions.length == 0) return yup.object().shape(result);

        questions.forEach((question) => {
            let multiLineValidation = question.MultiLineValidation;
            let serverSideValidations = question.ServerSideValidations;
            let validations = question.Validations;
            let validationType = self.GetValidationType(question.FieldType, question.DisplayType);
            let validator = yup[validationType]();
            let skipValidation = question.IsVisible != null && !question.IsVisible;

            if (skipValidation) {
                return;
                // result[question.FieldName] = yup.string();
            } else {
                if (validations.length > 0) {
                    validations.forEach((validation) => {
                        const mapValidationApi = self.MapValidationApi(validation.ValidationType, validationType);

                        if (validationType == "boolean") {
                            const expectedValue = true;
                            validator = validator[mapValidationApi]([expectedValue], validation.ErrorMessageKey)
                                .required(validations[0].ErrorMessageKey)
                                .typeError(validations[0].ErrorMessageKey);
                            return;
                        }

                        const params = [];
                        const validationValue =
                            validationType == "number" && !isNaN(validation.ValidationValue) ? parseInt(validation.ValidationValue) : validation.ValidationValue;

                        if (validationValue) {
                            params.push(validationValue, validation.ErrorMessageKey);
                        } else {
                            params.push(validation.ErrorMessageKey);
                        }

                        validator = validator[mapValidationApi](...params).typeError(validations[0].ErrorMessageKey);
                    });

                    if (multiLineValidation != null && multiLineValidation.length > 0) {
                        for (let index = 0; index < multiLineValidation.length; index++) {
                            const fieldName = multiLineValidation[index];
                            result[fieldName] = validator;
                        }
                    } else {
                        result[question.FieldName] = validator;
                    }
                }
            }
        });

        return yup.object().shape(result);
    }

    MapValidationApi(type, validationType) {
        switch (type) {
            case "Required":
                if (validationType == "boolean") return "oneOf";
                return "required";
            case "Regex":
                return "matches";
            case "MinLength":
                if (validationType == "number") return "minLength";
                return "min";
            case "MaxLength":
                if (validationType == "number") return "maxLength";
                return "max";
            case "GreaterThanEighteen":
                return "greaterThanEighteen";
            case "AfterDate":
                return "afterDate";
            case "MinValue":
                return "min";
            case "MaxValue":
                return "max";
            case "MaxInitialDeposit":
                return "max";
            case "IsValidCpfFormat":
                return "isValidCpfFormat";
            case "IsDateNotTooOld":
                return "isDateNotTooOld";
        }
    }

    GetValidationType(fieldType, displayType) {
        switch (fieldType) {
            case "Phone":
            case "Country":
                return "number";
            case "Cleave":
                if (displayType == "Amount") return "number";
                return "string";
            case "Date":
                return "date";
            case "Checkbox":
                return "boolean";
            case "MultipleCheckbox":
                return "boolean";
            default:
                return "string";
        }
    }
}

export default new Validator();
