export default {
    CountryOfResidenceDropdown: "BrazilCountryDropdown",
    RadioButtonLetters: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
    TerminateOaFieldName: "TerminateOA",

    OaReferenceQueryParam: "oaReference",
    KillCacheQueryParam: "killCache",
    CookiesOptionsQueryParam: "cookies_option",
    CookiesOptionsAll: "all",
    CookiesOptionsEsential: "essential",
    TaxResidencyField: "TaxResidencyCountryTins_",
    BrazilId: 35,
    BrazilianDateFormat: "DD/MM/YYYY",
    AllowedSizeInMegabytes: 5,
    MemberIdQueryParam: "memberId",
    UserIdCookieKey: "u",
    ThemeQueryParam: "theme",
    PlatformParam: "uiplatform",
    Branch: "branch",
    TestingGroup: "testing_group",

    BrazilianPrivacyAndTermsPageUrl: "https://activtrades.com.br/go/governanca/",

    SearchableAddressInputName: "searchableAddress",
};
