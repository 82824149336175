<template>
    <div class="upload-documents-container">
        <input type="file" id="file" ref="File" v-on:change="handleFileUpload()" v-on:click="cleanErrors()" accept="image/*,.pdf" style="display: none" />

        <div v-if="fileUploadError != null" class="notification notification-error">
            {{ fileUploadError }}
        </div>

        <div v-if="documentType == 'None' || imageUrl == null" class="flex-column-space-around">
            <div>
                <div id="drop" class="upload" @dragover="dragover" @dragleave="dragleave" @drop="drop($event)">
                    <label for="file" class="upload-btn">
                        <div class="upload-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" class="fa-svg fa-solid fa-file-arrow-up">
                                <path
                                    d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z" />
                            </svg>
                        </div>
                        <div class="upload-info">
                            <div class="upload-text">
                                <span>{{ $t("ls_drag_and_drop_to_upload") }}</span>
                            </div>
                            <small>{{ $t("treviso_accepted_file_multiple_formats") }}</small>
                        </div>
                    </label>
                    <div v-if="errors.length" class="errors">
                        <div class="error" v-for="error in errors" v-bind:key="error">
                            {{ $t(error.key, error.params) }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="notification notification-warning">
                <div class="notification-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.1em" viewBox="0 0 512 512" class="fa-svg fa-solid fa-triangle-exclamation">
                        <path
                            d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                    </svg>
                </div>
                <div v-html="$t('oa_it_country_note')"></div>
            </div>

            <div class="proof-address-documents">
                <div class="proof-address-heading">
                    <h3>{{ $t("below_docs_count_1") }}</h3>
                </div>
                <ul>
                    <li>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" class="fa-svg fa-light fa-check">
                                <path
                                    d="M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z" />
                            </svg> </span
                        >{{ $t("codice_fiscale_card") }}
                    </li>
                    <li>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" class="fa-svg fa-light fa-check">
                                <path
                                    d="M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z" />
                            </svg> </span
                        >{{ $t("tessera_sanitaria_card") }}
                    </li>
                </ul>
            </div>

            <div class="details-wrapper">
                <div class="details">
                    <div class="details-item">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="fa-svg fa-solid fa-camera">
                            <path
                                d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
                        </svg>
                        <div class="details-info">
                            <h3>{{ $t("ls_how_to_get_good_image") }}</h3>

                            <p>
                                {{ $t("ls_good_quality_img_process_app_faster") }}<br />
                                <a href="#" @click="showInstructions()">{{ $t("lbl_show_instructions") }}</a>
                            </p>
                        </div>
                    </div>
                    <!-- end .details-item -->

                    <a href="#" @click="showQrPopup()" class="qr" v-if="!uiStore.isMobile">
                        <div class="details-item">
                            <qrcode-vue :value="applicationStore.continueApplicationLink" :size="size" level="H" />
                            <!-- <img :src="continueApplication" alt="Scan code" /> -->
                            <div class="details-info">
                                <h3>{{ $t("lbl_use_smartphone") }}</h3>
                                <p>
                                    {{ $t("lbl_scan_qr_for_documents") }}<br />
                                    <a href="#">{{ $t("lbl_enlarge_qr_code") }}</a>
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div v-else class="upload-file-flex">
            <div
                id="drop"
                class="upload upload-no-margin"
                v-bind:class="{ selfie: question.DisplayType == 'Selfie' }"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop($event)">
                <label for="file">
                    <div v-if="fileUploadResult != null && !fileUploadResult" class="notification notification-error">
                        {{ fileUploadMessage }}
                    </div>

                    <a href="javascript:void(0)" @click="uploadFile" class="change-btn">
                        <div v-if="imageFormat == 'pdf'">
                            <div class="pdf-border">
                                <img src="@/assets/images/pdf-icon.svg" />
                                <div class="pdf-check"></div>
                            </div>
                        </div>
                        <div v-else>
                            <img v-bind:src="imageUrl" alt="" />
                        </div>
                        {{ $t("lbl_drag_and_drop_to_change") }}
                    </a>
                </label>
            </div>

            <div class="text-center-mobile">
                <button type="button" @click="uploadDocument" :disabled="submitButtonDisabled" :class="{ 'btn-disabled': submitButtonDisabled }" class="full-width">
                    {{ $t("btn_continue") }}
                </button>
            </div>
        </div>
    </div>

    <!-- popup for documents -->
    <div class="popup" v-if="instructionsVisible && question.DisplayType != 'Selfie'">
        <div class="container instructions-documents">
            <div class="popup-content">
                <div>
                    <h1>{{ $t("ls_how_to_get_good_image") }}</h1>
                    <div class="instructions-grid">
                        <ul class="tick-ul list-1">
                            <li>{{ $t("doc_req_not_expired") }}</li>
                            <li>{{ $t("doc_req_in_focus_glare") }}</li>
                            <li>{{ $t("doc_req_in_full_color") }}</li>
                            <li>{{ $t("doc_req_flat_angle") }}</li>
                        </ul>
                        <ul class="tick-ul list-2">
                            <li>{{ $t("good_lighting_no_flash") }}</li>
                            <li>{{ $t("doc_req_one_side_image") }}</li>
                            <li>{{ $t("doc_req_all_corners_visible") }}</li>
                            <li>{{ $t("doc_req_plain_background") }}</li>
                        </ul>
                        <div class="images">
                            <ul class="image-ul image-scan">
                                <li>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="fa-svg fa-solid fa-scanner-touchscreen scan">
                                            <path
                                                d="M152 0h16c13.3 0 24 10.7 24 24V64H128V24c0-13.3 10.7-24 24-24zM0 160c0-35.3 28.7-64 64-64H288c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V160zm64 16V432c0 8.8 7.2 16 16 16H272c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zM288 24c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V64H288V24zM240 0c8.8 0 16 7.2 16 16V64H224V16c0-8.8 7.2-16 16-16zM384 16c0-8.8 7.2-16 16-16s16 7.2 16 16V272c0 8.8-7.2 16-16 16s-16-7.2-16-16V16zM472 0h16c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24H472c-13.3 0-24-10.7-24-24V24c0-13.3 10.7-24 24-24z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div>{{ $t("lbl_scan") }}</div>
                                        <span class="recommended">{{ $t("min_scan_dpi") }}</span>
                                    </div>
                                </li>
                            </ul>
                            <ul class="image-ul image-photo">
                                <li>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="fa-svg fa-solid fa-image photo">
                                            <path
                                                d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div>{{ $t("photo") }}</div>
                                        <span class="recommended">{{ $t("min_megapixel_photo") }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn-blue mobile-btn full-width" @click="closeInstructions()">{{ $t("btn_ok") }}</button>
        </div>
    </div>
    <!-- end popup for documents -->

    <!-- popup for QR code -->
    <div class="popup qr-code" v-if="qrPopupVisible">
        <div class="container">
            <qrcode-vue :value="applicationStore.continueApplicationLink" :size="size" level="H" render-as="svg" />
            <button class="btn-blue mobile-btn full-width" @click="hideQrPopup()">{{ $t("btn_ok") }}</button>
        </div>
    </div>
    <!-- end popup for QR code -->
</template>

<script>
import QrcodeVue from "qrcode.vue";
import UploadFileService from "@/services/uploadfile.service";
import LogService from "@/services/log.service";
import endpoints from "@/common/endpoints";
import constants from "@/common/constants";
import eventBus from "@/eventBus";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import { useUiStore } from "@/store/interface.js";

export default {
    components: {
        // Cropper,
        QrcodeVue,
    },
    name: "AppFileUploadCodiceFiscale",
    props: ["question"],
    data() {
        return {
            documentType: "None",
            file: "",
            fileUploaded: false,
            instructionsVisible: false,
            qrPopupVisible: false,
            fileUploadResult: null,
            fileUploadMessage: null,
            errors: [],
            submitButtonDisabled: false,
            fileUploadError: null,
            size: 400,
        };
    },
    mounted() {
        var self = this;
        eventBus.$on("fileUpload", (imageBase64) => {
            self.uploadAndSubmit(imageBase64);
            eventBus.$off("fileUpload");
        });
    },
    methods: {
        showInstructions() {
            this.instructionsVisible = true;
        },
        closeInstructions() {
            this.instructionsVisible = false;
        },
        showQrPopup() {
            this.qrPopupVisible = true;
        },
        hideQrPopup() {
            this.qrPopupVisible = false;
        },
        cleanErrors() {
            // this.errors = [];
            this.fileUploadResult = null;
            this.fileUploadMessage = null;
        },
        uploadDocumentType(type) {
            this.documentType = type;
        },
        uploadFile() {
            this.$refs.File.click();
            this.cleanErrors();
        },
        handleFileUpload() {
            this.uploadDocumentType(this.question.DisplayType);

            if (this.$refs.File.files.length > 0) {
                this.file = this.$refs.File.files;
            }

            if (!this.file) return;

            const isValidSize = this.file[0]?.size <= constants.AllowedSizeInMegabytes * 1024 * 1024;
            if (!isValidSize) {
                this.addError({
                    key: "error_filesize_validation_mb",
                    params: [constants.AllowedSizeInMegabytes],
                });
                return;
            }

            const isValidFormat = this.file[0]?.type.includes("image/png") || this.file[0]?.type.includes("image/jpeg") || this.file[0]?.type.includes("pdf");
            if (!isValidFormat) {
                this.addError({
                    key: "error_filetypes_validation",
                    params: ["JPG, JPEG, PNG or PDF image"],
                });
                return;
            }

            this.applicationStore.uploadedDocuments.Document = {
                Type: this.DocumentType,
                File: this.File,
            };
            this.fileUploaded = true;
        },
        generateImage() {
            const { canvas } = this.$refs.cropper.getResult();
            return canvas.toDataURL();
        },

        dragover(event) {
            event.preventDefault();

            var innerEl = event.currentTarget.getElementsByTagName("label")[0];
            // Add some visual fluff to show the user can drop its files
            if (!innerEl.classList.contains("hover")) {
                innerEl.classList.add("hover");
            }
        },
        dragleave(event) {
            var innerEl = event.currentTarget.getElementsByTagName("label")[0];
            // Clean up
            innerEl.classList.remove("hover");
        },
        drop(event) {
            event.preventDefault();
            var innerEl = event.currentTarget.getElementsByTagName("label")[0];

            this.$refs.File.files = event.dataTransfer.files;
            this.handleFileUpload();

            // Clean up
            innerEl.classList.remove("hover");
        },
        uploadDocument() {
            var self = this;

            if (!this.submitButtonDisabled) {
                this.submitButtonDisabled = true;

                self.getBase64(self.file[0]).then((imageBase64) => {
                    self.uploadAndSubmit(imageBase64);
                });
            }
        },

        uploadAndSubmit(imageBase64) {
            var self = this;
            UploadFileService.uploadBase64(endpoints.UploadFile, {
                FileBase64: imageBase64,
                QuestionId: this.question.Id,
            })
                .then((data) => {
                    var result = data.data;
                    self.fileUploadResult = result.Success;
                    self.fileUploadMessage = result.ErrorMessage;

                    if (self.fileUploadResult) {
                        self.applicationStore.submit();
                    } else {
                        this.addError({ key: result.ErrorMessage, params: [] });
                    }
                    this.submitButtonDisabled = false;
                })
                .catch((data) => {
                    var result = data.data;
                    self.fileUploadResult = result.Success;
                    self.fileUploadMessage = result.ErrorMessage;
                    this.addError({ key: result.ErrorMessage, params: [] });
                    this.submitButtonDisabled = false;
                });
        },

        addError(error) {
            this.file = null;
            this.errors = [error];
        },

        getBase64(file) {
            var self = this;
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => {
                    // self.fileUploadError = "oa_file_upload_failed";
                    self.fileUploadError = self.$t("oa_file_upload_failed");

                    var errorMsg = error?.currentTarget?.error + file?.name;
                    var data = "UPLOAD DOCUMENT FAILED " + errorMsg;
                    LogService.log(endpoints.LogUrl, data);
                };
            });
        },
    },
    computed: {
        ...mapStores(useApplicationStore, useUiStore),
        imageUrl() {
            if (this.file == null || this.file[0] == null) {
                return null;
            }
            return URL.createObjectURL(this.file[0]);
        },
        imageFormat() {
            return this.file[0].type == "application/pdf" ? "pdf" : "image";
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_profiles.scss";
@import "src/assets/styles/_popup.scss";
@import "src/assets/styles/_file-upload.scss";

.proof-address-documents li {
    display: inline-flex;
    margin-right: 1.5rem;
}
.rtl .proof-address-documents li {
    margin-left: 1.5rem;
    margin-right: 0;
}

.fa-triangle-exclamation {
    fill: var(--warning);
}
</style>
