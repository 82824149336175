import http from "@/backend/vue-axios/axios";

class AuthService {
    getPersonalAreaLoginLink(memberId) {
        return http.post("/v1/authentication/pa", { memberId });
    }

    getLoginKey(memberId) {
        return http.post("v1/authentication/platform", { memberId });
    }
}

export default new AuthService();
