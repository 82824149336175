import CorporateLinksService from "@/services/links.service.js";
import { useLanguagesStore } from "@/store/languages.js";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useLinksStore = defineStore("links", () => {
    const languagesStore = useLanguagesStore();
    const applicationStore = useApplicationStore();
    const links = ref([]);

    function getLink(page) {
        if (links === null || links.value === null || links.value.length === 0) {
            return "#";
        }

        const branch = applicationStore.getBranch();
        const data = links.value.find((x) => x.Language === languagesStore.preferedLanguageShortName && x.Branch === branch);
        return data?.Links[page];
    }

    async function load() {
        const result = await CorporateLinksService.getAll();
        links.value = result.data;
    }

    async function getLinkForBranch(page, branch) {
        if (links === null || links.value === null || links.value.length === 0) {
            await load();
        }
        const data = links.value.find((x) => x.Language === languagesStore.preferedLanguageShortName && x.Branch === branch);
        return data?.Links[page];
    }

    return {
        getLink,
        load,
        getLinkForBranch,
    };
});
