import http from "@/backend/vue-axios/axios";
import endpoints from "../common/endpoints";

class CountriesService {
    getAll() {
        var data = http.get(`${endpoints.GetCountriesUrl}`);
        return data;
    }
}

export default new CountriesService();
