<template>
    <div class="nationality">
        <div v-for="(nationality, i) in nationalities" v-bind:key="nationality.Id" class="relative">
            <!-- <transition-group appear name="add"> -->
            <div>
                <AppCountryDropdown v-bind:question="nationality" v-bind:errors="errors" v-bind:answerIndex="i" v-bind:multilineValidation="true"></AppCountryDropdown>
                <a href="javascript:void(0)" @click="remove(i)" v-if="showRemoveButton(i)" class="remove-btn">&times;</a>
            </div>
            <!-- </transition-group> -->
        </div>

        <button type="button" @click="add()" class="add-btn" v-if="multipleChoice"><span class="add-icon">&plus;</span>{{ $t("lbl_another_nationality") }}</button>
    </div>
</template>

<script>
import { useApplicationStore } from "@/store/onlineapplication.js";
import { mapStores } from "pinia";
import AppCountryDropdown from "@/components/oa/AppCountryDropdown.vue";
import UIFlag from "@/components/ui/UIFlag.vue";

export default {
    name: "AppNationality",
    props: ["question", "errors"],
    components: {
        UIFlag,
        AppCountryDropdown,
    },
    data() {
        return {
            nationalities: [this.question],
            multipleChoice: false,
        };
    },
    mounted() {
        this.multipleChoice = this.question.DisplayType == "MultipleChoice";
    },
    computed: {
        ...mapStores(useApplicationStore),
    },
    methods: {
        add() {
            this.nationalities.push(this.nationalities[0]);
        },
        remove(i) {
            this.question.MultiLineValidation.splice(i, 1);
            this.question.Answers.splice(i, 1);
            this.nationalities.splice(i, 1);
            this.applicationStore.applyValidations();
        },
        showRemoveButton(i) {
            return i == this.nationalities.length - 1 && i != 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/_buttons.scss";
@import "src/assets/styles/_animations.scss";

.nationality {
    text-align: left;
}
.relative {
    position: relative;
}
</style>
