<template>
    <component v-bind:is="answer.DisplayTypeString"></component>
</template>

<script>
import BhBranchSelectPopover from "@/components/ui/popover/BhBranchSelectPopover.vue";
import BrBranchSelectPopover from "@/components/ui/popover/BrBranchSelectPopover.vue";
import UkBranchSelectPopover from "@/components/ui/popover/UkBranchSelectPopover.vue";

export default {
    name: "BranchSelectPopover",
    props: ["answer"],
    components: {
        BrBranchSelectPopover,
        BhBranchSelectPopover,
        UkBranchSelectPopover,
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
@import "/src/assets/styles/_variables.scss";

</style>
