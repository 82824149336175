<template>
    <div class="home-layout">
        <div class="form">
            <Questions
                v-bind:questions="questions"
                v-bind:errors="errors"
                :preSelectedCountryId="this.preSelectedCountryId"
                :allowInlineElements="true"
            ></Questions>
            <div
                class="policy"
                v-show="privacyPolicyUrl"
                v-html="$t('gdpr_pre_disclaimer', [privacyPolicyUrl])"
            ></div>
            <div
                v-if="isSubmitButtonsEnabled && this.applicationStore.customerStatus.UserStatus != 'ApplicationAlreadySubmitted'"
            >
                <button
                    type="submit"
                    :disabled="uiStore.isSubmitButtonDisabled"
                    v-if="
                        this.applicationStore.customerStatus.length == 0 ||
                        (this.applicationStore.customerStatus != null &&
                            this.applicationStore.customerStatus != '' &&
                            this.applicationStore.customerStatus.UserStatus != 'LiveUser')
                    "
                >{{ $t("btn_accent_and_continue") }}</button>
                <div
                    v-if="
                        this.applicationStore.customerStatus != null &&
                        this.applicationStore.customerStatus != '' &&
                        this.applicationStore.customerStatus.UserStatus == 'DemoUserWithOa'
                    "
                    class="start-new-oa"
                >
                    <a
                        href="javascript:void(0)"
                        @click="terminateOa"
                    >{{ $t("restart_new_oa_heading") }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Questions from "../Questions.vue";
import eventBus from "@/eventBus";

import { CorporatePage } from "@/models/CorporatePage.js";
import constants from "@/common/constants";
import { useUiStore } from "@/store/interface.js";
import { useLinksStore } from "@/store/links.js";
import { mapStores } from "pinia";
import { useApplicationStore } from "@/store/onlineapplication.js";

export default {
    name: "Start",
    props: ["questions", "errors"],
    components: {
        Questions,
    },
    data: function () {
        return {
            isSubmitButtonsEnabled: true,
            preSelectedCountryId: null,
        };
    },
    beforeMount() {
        eventBus.$on("change-continue-button-state", (btnState) => (this.isSubmitButtonsEnabled = btnState));
        this.preSelectedCountryId = this.$route.query.country;
    },
    methods: {
        terminateOa() {
            var terminateOaField = this.questions.filter((x) => x.FieldName == constants.TerminateOaFieldName);

            if (terminateOaField != null && terminateOaField.length > 0) {
                terminateOaField[0].Answers[0].Value = "true";
                eventBus.$emit("TerminateOa", true);
            }
        },
    },
    computed: {
        ...mapStores(useUiStore, useLinksStore, useApplicationStore),
        submitButtonDisabled() {
            var allFilled = this.questions.filter((x) => x.Value != null);

            if (allFilled.length < 3 || Object.keys(this.errors).length > 0) {
                return true;
            }

            return false;
        },
        privacyPolicyUrl() {
            return this.linksStore.getLink(CorporatePage.Privacy);
        },
    },
};
</script>

<style lang="scss" scoped>
.home-layout {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.rtl ul {
    text-align: right;
}

li {
    display: flex;
    align-items: center;
    font-size: clamp(1.25rem, 3vmin, 1.5rem);
    margin-bottom: clamp(0.625rem, 3vmin, 1.875rem);
}

li span {
    line-height: 1.4;
}

.check {
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 2.5rem;
    vertical-align: middle;
    text-align: center;
}

.rtl .check {
    margin-left: 0.938rem;
    margin-right: 0;
}

li .check .fa-svg {
    font-size: 2.5rem;
    fill: var(--font-main);
}

.form {
    position: relative;
    max-width: 100%;
    margin: 0;
    text-align: left;
    width: 100%;
}

.rtl .form {
    text-align: right;
}

.form button[type="submit"] {
    width: 100%;
}

.start-new-oa {
    text-align: center;
    padding: 20px 0;
}

.disabled {
    background-color: #394965;
    color: #697b9a;
    pointer-events: none;
}
</style>
