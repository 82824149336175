<template>
    <div v-if="displaySubtitle">
        <div v-if="isVimeo" v-html="step.SubtitleKey" class="video"></div>
        <p class="lead" v-else v-html="$t(step.SubtitleKey, applicationStore.translationsParams[step.SubtitleKey])"></p>
    </div>
    <div v-if="step.IsSkippable && uiStore.isSkipButtonEnabled && (step?.SkipButtonStyle === 'Top' || step?.SkipButtonStyle === 'TopAndBottom')">
        <a @click="applicationStore.skipStep" class="skip-link">
            {{ $t($t(step.SkipButtonKey, applicationStore.translationsParams[step.SkipButtonKey])) }}
        </a>
    </div>
</template>

<script>
import eventBus from "@/eventBus";
import { useApplicationStore } from "@/store/onlineapplication.js";
import { useUiStore } from "@/store/interface.js";

import { mapStores } from "pinia";

export default {
    name: "AppStepSubtitleVideo",
    props: ["step"],
    computed: {
        ...mapStores(useApplicationStore, useUiStore),
        displaySubtitle() {
            var subtitle = this.step.SubtitleKey;
            return subtitle != null && subtitle != "" && !this.step.Questions.find((x) => x.FieldName === "NoTinRequired");
        },

        isVimeo() {
            var subtitle = this.step.SubtitleKey;
            return subtitle != null && subtitle.includes("vimeo");
        },
    },
};
</script>
<style lang="scss" scoped>
.video {
    width: 525px;
    max-width: 100%;
    margin: 1rem auto;
    border-radius: var(--border-radius-btn);
    overflow: hidden;
}
.skip-link {
    display: block;
    margin-bottom: 1.625rem;
    margin-top: -1.188rem;
}
</style>
